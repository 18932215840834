import find from 'lodash/find';
import { useSelector } from 'react-redux';

const useCompareTriggerName = () => {
  const triggers = useSelector((state) => state.alarms.triggers);

  return (triggerName1, triggerName2) => {
    const trigger1 = find(triggers, { trigger_id: triggerName1 });
    const trigger2 = find(triggers, { trigger_id: triggerName2 });
    return trigger1?.trigger_id < trigger2?.trigger_id ? -1 : 1;
  };
};

export default useCompareTriggerName;
