import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { TextFieldListItem } from '../../List/TextFieldListItem';
import DefaultOrganizationSelect from './DefaultOrganizationSelect';

function Form(props) {
  const { mode, user, organizations, portfolios, handleChange } = props;
  const { item: appUser } = useSelector((state) => state.user);

  return (
    <List disablePadding>
      {mode === 'edit' && get(appUser, 'super_user', false) ? (
        <TextFieldListItem
          id='user_id'
          label='User ID'
          value={user.user_id}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
          }}
        />
      ) : null}

      {/* EMAIL */}
      <TextFieldListItem
        required
        id='email'
        label='Email'
        value={user.email}
        InputProps={{
          readOnly: mode === 'edit',
          disableUnderline: mode === 'edit',
        }}
        onChange={mode !== 'edit' ? handleChange : null}
      />

      {/* NAME */}
      <TextFieldListItem
        required
        label='Name'
        value={user.name}
        onChange={(e) =>
          handleChange({ target: { id: 'name', value: e.target.value } })
        }
      />

      {/* DEFAULT ORG */}
      <DefaultOrganizationSelect
        organizations={organizations}
        portfolios={portfolios}
        handleChange={handleChange}
        defaultOrganization={get(user, 'default_organization', '')}
      />

      {/* API KEY */}
      {mode === 'edit' && user?.api?.key ? (
        <TextFieldListItem
          id='api.key'
          label='API Key'
          value={get(user, 'api.key', '')}
          disabled
          InputProps={{
            readOnly: true,
            disableUnderline: true,
          }}
        />
      ) : null}

      {/* ACCOUNT STATUS */}
      {mode === 'edit' ? (
        <TextFieldListItem
          id='account_status'
          label='Account Status'
          value={user.account_status}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
          }}
        />
      ) : null}

      {/* SIGN IN COUNT */}
      {mode === 'edit' ? (
        <TextFieldListItem
          id='sign_in_count'
          label='Sign in Count'
          value={user.sign_in_count}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
          }}
        />
      ) : null}

      {/* SUPER USER */}
      <ListItem sx={{ pl: 0, pr: 0, pt: 1, pb: 1 }}>
        <FormControlLabel
          control={
            <Checkbox
              id='super_user'
              checked={user.super_user}
              onChange={handleChange}
            />
          }
          label='Super User'
        />
      </ListItem>
    </List>
  );
}

Form.propTypes = {
  user: PropTypes.object,
  portfolios: PropTypes.array,
  organizations: PropTypes.array,
  handleChange: PropTypes.func,
};

export default Form;
