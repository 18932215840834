import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import find from 'lodash/find';
import findKey from 'lodash/findKey';
import includes from 'lodash/includes';

import { setPage } from '../../../store/pages';
import useLoggers from '../../../store/hooks/useLoggers';
import useMeters from '../../../store/hooks/useMeters';
import useInverters from '../../../store/hooks/useInverters';
import useSensors from '../../../store/hooks/useSensors';
import { TABS } from '../../../constants/tabs';
import TabBar from '../../../components/tab/TabBar';
import ROUTES from '../../../constants/routes';
import Dashboard from './Dashboard';
import Analysis from './Analysis';
import Configuration from './Configuration';

export default function Logger() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const { id, tab } = useSelector((state) => state.pages.logger);
  useLoggers(id);
  useMeters(id);
  useInverters(id);
  useSensors(id);

  useEffect(() => {
    if (location.hash && !tab) {
      let _tab = find(TABS, (value) => value === location.hash);
      dispatch(setPage({ page: 'logger', tab: _tab, view: '' }));
    } else if (tab) {
      let _tabIndex = findKey(TABS, (_tab) => {
        return includes(_tab, tab);
      });

      if (_tabIndex) {
        setTabIndex(Number(_tabIndex));
      }
    } else {
      setTabIndex(0);
    }
  }, [dispatch, location.hash, tab]);

  useEffect(() => {
    const loggerId = localStorage.getItem('solar.logger');
    if (id) {
      localStorage.setItem('solar.logger', id);
    } else if (loggerId) {
      dispatch(setPage({ page: 'logger', id: loggerId }));
    }
  }, [dispatch, id]);

  const handleChangeTab = (event, value) => {
    dispatch(push(ROUTES.AUTH.LOGGER + TABS[value]));
    dispatch(setPage({ page: 'logger', tab: TABS[value], view: '' }));
  };

  return (
    <TabBar handleChangeTab={handleChangeTab} tabIndex={tabIndex}>
      <Dashboard />
      <Analysis />
      <Configuration />
    </TabBar>
  );
}
