import axios from '../xhr_libs/axios';
import { processResponse } from '.';

export const refreshLoggerAPI = async (orgId, loggerId) => {
  return processResponse(
    await axios.get(`/resource/refresh_logger/${orgId}/${loggerId}`)
  );
};

export const getLoggersAPI = async () => {
  return processResponse(await axios.get('/resource/loggers'));
};

export const postLoggerAPI = async (body) => {
  return processResponse(await axios.post(`/resource/loggers`, body));
};

export const putLoggerAPI = async (logger) => {
  return processResponse(
    await axios.put(`/resource/loggers/${logger.logger_id}`, logger)
  );
};

export const deleteLoggerAPI = async (loggerId) => {
  return processResponse(await axios.delete(`/resource/loggers/${loggerId}`));
};
