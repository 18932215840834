import React, { useEffect } from 'react';
import { useToggle, useMeasure } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import { useLocation } from 'react-router';
import get from 'lodash/get';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { common } from '@mui/material/colors';
import styled from '@mui/material/styles/styled';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import ROUTES from '../../../constants/routes';
import { navigate } from '../../../store/pages';
import { setTitleBarStyles } from '../../../store/appSettings';
import { logoutUser } from '../../../store/user';
import Drawer from './Drawer';
import Breadcrumbs from './Breadcrumbs';
import MainContent from './MainContent';
import Dialogs from './Dialogs';

const Root = styled('div')({
  display: 'flex',
  height: '100%',
  width: '100vw',
});

function Layout() {
  const location = useLocation();
  const dispatch = useDispatch();

  const [open, toggleOpen] = useToggle(true);
  const [appbarRef, appbarAttrs] = useMeasure();
  const [drawerRef, drawerAttrs] = useMeasure();
  const { item: user } = useSelector((state) => state.user);

  useEffect(() => {
    const { width: titleBarWidth, height: titleBarHeight } = appbarAttrs;
    dispatch(
      setTitleBarStyles({
        marginTop: titleBarHeight,
        width: titleBarWidth,
        marginLeft: open ? drawerAttrs.width : 0,
      })
    );
  }, [dispatch, appbarAttrs, open, drawerAttrs.width]);

  useEffect(() => {
    if (location.pathname === '/app') {
      dispatch(push(ROUTES.AUTH.PORTFOLIO));
    }
  }, [location.pathname, dispatch]);

  useEffect(() => {
    if (location.search.includes('?resource=')) {
      const resource_id = location.search.split('=')[1];

      if (resource_id) {
        const type = resource_id.split(':')[0];
        dispatch(
          navigate({
            page: type,
            id: resource_id,
          })
        );
      }
    }
  }, [location, dispatch]);

  return (
    <Root>
      <CssBaseline />
      <AppBar ref={appbarRef} position='fixed'>
        <Toolbar
          sx={{
            paddingLeft: '2rem !important',
            marginLeft: `${open ? drawerAttrs.width : 0}px`,
            transition: 'all 100ms ease-out',
          }}>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={toggleOpen}
            mr={2}>
            <FontAwesomeIcon
              icon={['fal', open ? 'arrow-to-left' : 'bars']}
              size='sm'
            />
          </IconButton>
          <Breadcrumbs />
          <Typography
            noWrap
            align='right'
            sx={{ flexGrow: 1, mr: 2 }}
            onClick={() => dispatch(navigate({ page: 'profile' }))}>
            {get(user, 'email', '')}
          </Typography>
          <IconButton
            aria-label='open popover'
            onClick={() => dispatch(logoutUser())}>
            <FontAwesomeIcon
              icon={['fal', 'sign-out']}
              color={common.white}
              size='sm'
            />
          </IconButton>
          <IconButton href='https://support.veregy.com/section/102-lightlevel'>
            <FontAwesomeIcon
              icon={['fal', 'question-circle']}
              color={common.white}
              size='sm'
            />
          </IconButton>
        </Toolbar>
      </AppBar>
      <nav
        style={{
          width: open ? drawerAttrs.width : 0,
          flexShrink: 0,
          transition: 'all 100ms ease-out',
        }}
        aria-label='drawer'>
        {open && (
          <Drawer open={open} toggleOpen={toggleOpen} drawerRef={drawerRef} />
        )}
      </nav>
      <MainContent appBarHeight={appbarAttrs.height} />
      <Dialogs />
    </Root>
  );
}

export default Layout;
