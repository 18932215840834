import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import filter from 'lodash/filter';
import has from 'lodash/has';
import toUpper from 'lodash/toUpper';

import Grid from '@mui/material/Grid';

import { setPage } from '../../../../store/pages';
import useSite from '../../../../store/hooks/useSite';
import useSiteDevices from '../../../../store/hooks/useSiteDevices';
import useVerifyOrgRole from '../../../../store/hooks/useVerifyOrgRole';
import CountGrid from '../../../../components/Grid/CountGrid';
import ResourceCount from '../../../../components/widgets/ResourceCount';
import CurrentGeneration from '../../../../components/widgets/CurrentGeneration';
import ProductionIndex from '../../../../components/widgets/ProductionIndex';
import LoggersGrid from './LoggersGrid';
import ViewStack from '../../../../components/ViewStack';
import AlarmsTable from '../../../../components/table/tables/AlarmsTable';

const VIEWS = {
  LOGGERS: {
    name: 'loggers',
    description: 'View a snapshot of recent data for each logger',
  },
  ALARMS: {
    name: 'alarms',
    description: "Table of alarms for this site's devices",
  },
};

function SiteDashboard() {
  const dispatch = useDispatch();
  const { id, view, expectationType } = useSelector(
    (state) => state.pages.site
  );
  const site = useSite(id);
  const { loggers, meters, inverters, sensors, alarms, loading } =
    useSiteDevices(id);

  const isOrgAdmin = useVerifyOrgRole(site?.org_id, 0);
  const [selectedView, setSelectedView] = useState(VIEWS.LOGGERS.name);

  useEffect(() => {
    if (has(VIEWS, toUpper(view))) {
      setSelectedView(view);
    } else if (view === '') {
      setSelectedView(VIEWS.LOGGERS.name);
    }
  }, [view]);

  const handleSetExpectationType = (type) => {
    switch (type) {
      case 'modeled':
      case 'corrected':
        dispatch(setPage({ page: 'site', expectationType: type }));
        break;

      default:
        dispatch(setPage({ page: 'site', expectationType: 'modeled' }));
        break;
    }
  };

  const renderView = () => {
    if (selectedView === VIEWS.LOGGERS.name) {
      return <LoggersGrid site={site} loading={loading} loggers={loggers} />;
    } else if (selectedView === VIEWS.ALARMS.name) {
      return <AlarmsTable resource={site} alarms={alarms} />;
    }
  };

  const activeAlarms = filter(alarms, { status: true });

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={2}
      padding={2}
      sx={{ pl: 4, pr: 3 }}>
      <Grid item xs={4}>
        <ResourceCount loading={loading}>
          <CountGrid number={meters.length} name='Meters' />
          <CountGrid number={inverters.length} name='Inverters' />
          <CountGrid number={sensors.length} name='Sensors' />
          <CountGrid number={activeAlarms.length} name='Alarms' />
        </ResourceCount>
      </Grid>
      <Grid item xs={4}>
        <CurrentGeneration
          loggers={loggers}
          meters={meters}
          inverters={inverters}
          loading={loading}
        />
      </Grid>
      <Grid item xs={4}>
        <ProductionIndex
          sites={[site]}
          meters={meters}
          inverters={inverters}
          sensors={sensors}
          loading={loading}
          expectationType={expectationType}
          setExpectationType={handleSetExpectationType}
          disabled={!isOrgAdmin}
        />
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: '8px !important' }}>
        <ViewStack
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          views={VIEWS}
        />
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: '8px !important' }}>
        {renderView()}
      </Grid>
    </Grid>
  );
}

export default SiteDashboard;
