import { useSelector } from 'react-redux';
import find from 'lodash/find';
import toLower from 'lodash/toLower';
import startCase from 'lodash/startCase';

const useTriggerNamePredicate = () => {
  const triggers = useSelector((state) => state.alarms.triggers);

  return (value, filter) => {
    const trigger = find(triggers, { trigger_id: value });
    return toLower(startCase(trigger?.trigger_id)).includes(
      toLower(filter.value)
    );
  };
};

export default useTriggerNamePredicate;
