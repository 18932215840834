import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import find from 'lodash/find';
import get from 'lodash/get';

import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { navigate } from '../../../store/pages';
import { TextFieldListItem } from '../../List/TextFieldListItem';
import AddressField from '../../AddressField';
import { renderLatLngValue } from '../../../helpers';

function Form(props) {
  const {
    mode,
    site,
    handleChange,
    handleAddressChange,
    handleAddressSelect,
    handleClose,
  } = props;

  const dispatch = useDispatch();
  const { item: user } = useSelector((state) => state.user);
  const organizations = useSelector((state) => state.organizations.data);
  const organization = find(organizations, { org_id: site.org_id });

  return (
    <List disablePadding>
      {mode === 'edit' && get(user, 'super_user', false) ? (
        <TextFieldListItem
          id='site_id'
          label='Site ID'
          value={site.site_id}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
          }}
        />
      ) : null}
      <ListItem sx={{ pl: 0, pr: 0, pt: 1, pb: 1 }}>
        <Link
          underline='hover'
          sx={(theme) => ({ color: theme.palette.veregy_colors.blue })}
          onClick={() => {
            dispatch(
              navigate({
                page: 'organization',
                id: organization.org_id,
              })
            );
            handleClose();
          }}>
          {get(organization, 'name')}
        </Link>
      </ListItem>
      <TextFieldListItem
        label='Name'
        value={site.name}
        onChange={(e) =>
          handleChange({ target: { id: 'name', value: e.target.value } })
        }
      />
      <ListItem sx={{ pl: 0, pr: 0, pt: 1, pb: 1 }}>
        <AddressField
          id='address'
          address={site.address}
          onAddressSelect={handleAddressSelect}
          handleAddressChange={handleAddressChange}
        />
      </ListItem>

      {mode === 'edit' ? (
        <>
          <TextFieldListItem
            label='Timezone'
            value={site.timezone.zone}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
          />
          <ListItem
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              pl: 1,
              pb: 0,
              pt: 0,
            }}>
            <TextField
              label='Timezone Abbreviation'
              value={site.timezone.name}
              variant='standard'
              sx={{ width: '45%' }}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
            />
            <TextField
              label='Timezone Offset'
              value={site.timezone.offset}
              variant='standard'
              sx={{ width: '45%' }}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
            />
          </ListItem>
        </>
      ) : null}

      <ListItem
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          pl: 0,
          pr: 0,
          pt: 1,
          pb: 1,
        }}>
        <TextField
          id='lat'
          label='Latitude'
          value={renderLatLngValue(site.location.lat)}
          variant='standard'
          sx={{ width: '45%' }}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
          }}
        />
        <TextField
          id='lng'
          label='Longitude'
          value={renderLatLngValue(site.location.lng)}
          variant='standard'
          sx={{ width: '45%' }}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
          }}
        />
      </ListItem>

      <List component='div' disablePadding>
        <TextFieldListItem
          id='installer'
          label='Installer'
          value={site.installer}
          onChange={handleChange}
        />
        <ListItem sx={{ pl: 0, pr: 0, pt: 1, pb: 1 }}>
          <DatePicker
            label='Install Date'
            value={dayjs(site.install_date)}
            onChange={(value) => {
              handleChange({
                target: {
                  id: 'install_date',
                  value,
                },
              });
            }}
            slotProps={{
              textField: {
                fullWidth: true,
                variant: 'standard',
                inputProps: {
                  readOnly: true,
                },
              },
            }}
          />
        </ListItem>
        <ListItem sx={{ pl: 0, pr: 0, pt: 1, pb: 1 }}>
          <DatePicker
            label='Startup Date'
            value={dayjs(site.startup_date)}
            onChange={(value) => {
              handleChange({
                target: {
                  id: 'startup_date',
                  value,
                },
              });
            }}
            slotProps={{
              textField: {
                fullWidth: true,
                variant: 'standard',
                inputProps: {
                  readOnly: true,
                },
              },
            }}
          />
        </ListItem>
      </List>
    </List>
  );
}

Form.propTypes = {
  mode: PropTypes.string,
  site: PropTypes.object,
  handleChange: PropTypes.func,
  handleAddressChange: PropTypes.func,
  handleAddressSelect: PropTypes.func,
  handleClose: PropTypes.func,
};

export default Form;
