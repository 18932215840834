import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';
import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import remove from 'lodash/remove';
import sortBy from 'lodash/sortBy';

import { buildAsyncReducers } from '../thunkTemplate';
import { licenses as initialState } from '../initialState';
import { getLicensesAPI, putLicenseAPI } from '../../api';

const getLicenses = createAsyncThunk(
  'licenses/getLicenses',
  async (_, { getState, requestId, dispatch }) => {
    try {
      const { currentRequestId, loading } = getState().licenses;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      let _licenses = await getLicensesAPI();
      _licenses = sortBy(_licenses, ['org_id', 'name']);
      return { data: _licenses };
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const putLicense = createAsyncThunk(
  'licenses/putLicense',
  async (license, { getState, requestId, dispatch }) => {
    try {
      const { currentRequestId, loading, data: licenses } = getState().licenses;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      const updatedLicense = await putLicenseAPI(license);

      let _licenses = cloneDeep(licenses);
      remove(_licenses, (license) => {
        return (
          license.org_id === updatedLicense.org_id &&
          license.name === updatedLicense.name
        );
      });

      _licenses = sortBy(_licenses, ['org_id', 'name']);
      toastr.success('License updated');
      return { data: concat(_licenses, updatedLicense) };
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { reducer } = createSlice({
  name: 'licenses',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [getLicenses, putLicense]);
  },
});

export { getLicenses, putLicense };
export default reducer;
