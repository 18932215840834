import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import startCase from 'lodash/startCase';

import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { closeDialog } from '../../store/dialogs';
import DialogTitleOptions from './DialogTitleOptions';

function InfoDialog() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { title, content } = useSelector((state) => state.dialogs.info);

  const handleClose = () => {
    dispatch(closeDialog('info'));
  };

  return (
    <Dialog open={title !== ''} onClose={handleClose} fullScreen={fullScreen}>
      <DialogTitle sx={{ p: 1, pl: 2, pb: 1 }}>
        {startCase(title)}
        <DialogTitleOptions handleClose={handleClose} />
      </DialogTitle>
      <DialogContent
        sx={{
          minWidth: theme.dialog.form.minWidth,
          p: 2,
          pt: 0,
          pb: 0,
        }}>
        {content}
      </DialogContent>
      <DialogActions sx={{ p: '4px' }}>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

export default InfoDialog;
