import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import get from 'lodash/get';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

function BaseTooltip({
  active,
  payload,
  label,
  getName,
  getValue,
  width = '18rem',
}) {
  if (active && payload && payload.length) {
    return (
      <Card raised sx={{ width }}>
        {label && (
          <CardHeader
            sx={{
              '&.MuiCardHeader-root': { padding: 0, pt: 1 },
            }}
            titleTypographyProps={{ variant: 'body1', align: 'center' }}
            title={label}
          />
        )}
        <CardContent sx={{ p: 1, pb: '0 !important' }}>
          <List disablePadding>
            {map(payload, (resource) => {
              return (
                <ListItem disableGutters disablePadding key={resource?.dataKey}>
                  <ListItemText
                    primaryTypographyProps={{ align: 'left' }}
                    sx={{
                      color: get(resource, 'color'),
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: '1',
                      WebkitBoxOrient: 'vertical',
                      m: 0,
                    }}>
                    {getName(resource)}
                  </ListItemText>
                  <ListItemText
                    primaryTypographyProps={{ align: 'right' }}
                    sx={{
                      color: get(resource, 'color'),
                      minWidth: '40%',
                      m: 0,
                    }}>
                    {getValue(resource)}
                  </ListItemText>
                </ListItem>
              );
            })}
          </List>
        </CardContent>
      </Card>
    );
  }

  return null;
}

BaseTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  label: PropTypes.string,
  getName: PropTypes.func,
  getValue: PropTypes.func,
};

export default BaseTooltip;
