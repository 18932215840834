import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import dayjs from 'dayjs';
import find from 'lodash/find';

import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { generateReportFileAPI } from '../../api';
import { closeDialog } from '../../store/dialogs';
import DialogTitleOptions from './DialogTitleOptions';

function GenerateReportDialog() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { reportId } = useSelector((state) => state.dialogs.generateReport);
  const reports = useSelector((state) => state.reports.data);

  const [report, setReport] = useState({});
  const [date, setDate] = useState(dayjs());

  useEffect(() => {
    if (reportId) {
      setReport(find(reports, { report_id: reportId }));
    }
  }, [reportId, reports]);

  const handleClose = () => {
    dispatch(closeDialog('generateReport'));
    setReport({});
    setDate(dayjs());
  };

  const handleConfirm = () => {
    dispatch(showLoading());
    generateReportFileAPI({
      org_id: report.org_id,
      report_id: report.report_id,
      period: report.period,
      year: date.year(),
      month: date.month() + 1,
    })
      .then(() => {
        toastr.success(
          'Report Generation In Progress',
          'You should receive an email shortly with the report attached'
        );
        handleClose();
      })
      .catch((err) => {
        toastr.error('Report Generation Failed', err);
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  };

  return (
    <Dialog
      open={reportId !== ''}
      onClose={handleClose}
      fullScreen={fullScreen}>
      <DialogTitle sx={{ p: 1, pl: 2, pb: 1 }}>
        Generate Report
        <DialogTitleOptions handleClose={handleClose} />
      </DialogTitle>
      <DialogContent
        sx={{
          width: theme.dialog.form.minWidth,
          p: 2,
          pt: 0,
          pb: 1,
        }}>
        {report?.period === 'month' && (
          <DatePicker
            disableFuture
            views={['year', 'month']}
            label='Select Month'
            value={date}
            onChange={(newValue) => {
              setDate(newValue);
            }}
            slotProps={{
              textField: {
                fullWidth: true,
                variant: 'standard',
                inputProps: {
                  readOnly: true,
                },
              },
            }}
          />
        )}
      </DialogContent>
      <DialogActions sx={{ p: '4px' }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant='contained' onClick={handleConfirm}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default GenerateReportDialog;
