import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import has from 'lodash/has';
import toUpper from 'lodash/toUpper';

import Grid from '@mui/material/Grid';

import useMemberships from '../../../../store/hooks/useMemberships';
import useOrganization from '../../../../store/hooks/useOrganization';
import useVerifyOrgRole from '../../../../store/hooks/useVerifyOrgRole';
import useOrganizationDevices from '../../../../store/hooks/useOrganizationDevices';
import ViewStack from '../../../../components/ViewStack';
import Kiosk from '../../../../components/Configuration/Kiosk';
import SitesTable from '../../../../components/table/tables/SitesTable';
import MembershipsTable from '../../../../components/table/tables/MembershipsTable';
import AlarmsConfigurationTable from '../../../../components/table/tables/AlarmsConfigurationTable';
import GeneratorsTable from '../../../../components/table/tables/GeneratorsTable';
import LicensesConfig from './LicensesConfig';

const VIEWS = {
  SITES: {
    name: 'sites',
    description: "Manage the organization's sites",
  },
  USERS: {
    name: 'users',
    description: "Manage the organization's user memberships",
  },
  ALARMS: {
    name: 'alarms',
    description: 'Configure alarm triggers for devices in this organization',
  },
  KIOSK: {
    name: 'kiosk',
    description: 'Configure kiosk settings for this organization',
  },
  LICENSES: {
    name: 'Licenses',
    description: 'View LightLevel feature Licenses',
  },
  GATS: {
    name: 'GATS',
    description: 'Table of GATS Generators',
  },
};

export default function Configuration() {
  const [views, setViews] = useState(VIEWS);
  const [selectedView, setSelectedView] = useState(views.SITES.name);
  const { id, view } = useSelector((state) => state.pages.organization);
  const organization = useOrganization(id);
  const memberships = useMemberships(id);
  const { sites } = useOrganizationDevices(id);
  const isOrgAdmin = useVerifyOrgRole(id, 0);

  useEffect(() => {
    let _views = { ...VIEWS };

    if (!isOrgAdmin) {
      delete _views.USERS;
      delete _views.LICENSES;
    }

    setViews(_views);
  }, [isOrgAdmin]);

  useEffect(() => {
    if (has(views, toUpper(view))) {
      setSelectedView(view);
    } else if (view === '') {
      setSelectedView(views?.SITES?.name);
    }
  }, [views, view]);

  const renderView = () => {
    switch (selectedView) {
      case views?.USERS?.name:
        return (
          <MembershipsTable
            relations={memberships}
            orgId={organization?.org_id}
          />
        );

      case views.SITES.name:
        return <SitesTable sites={sites} orgId={organization?.org_id} />;

      case views.ALARMS.name:
        return (
          <AlarmsConfigurationTable resource={organization} typeSelect={true} />
        );

      case views.KIOSK.name:
        return (
          <Kiosk
            resourceId={organization?.org_id}
            orgId={organization?.org_id}
          />
        );

      case views?.GATS?.name:
        return <GeneratorsTable orgId={organization?.org_id} />;

      case views?.LICENSES?.name:
        return <LicensesConfig orgId={organization?.org_id} />;

      default:
        return null;
    }
  };

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      spacing={2}
      padding={2}
      sx={{ pl: 4, pr: 3 }}>
      <Grid item xs={12} sx={{ paddingTop: '8px !important' }}>
        <ViewStack
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          views={views}
        />
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: '8px !important' }}>
        {renderView()}
      </Grid>
    </Grid>
  );
}
