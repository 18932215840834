import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';

import { TextFieldListItem } from '../../List/TextFieldListItem';
import { IntegerFieldListItem } from '../../List/IntegerFieldListItem';
import { dateStringToHumanReadable } from '../../../helpers/dates';

function Form(props) {
  const { mode, generatorMeter } = props;
  const user = useSelector((state) => state.user.item);

  return (
    <List disablePadding>
      {mode === 'view' && get(user, 'super_user', false) ? (
        <TextFieldListItem
          id='generator_meter_id'
          label='Generator Meter ID'
          value={generatorMeter.meter_id}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
          }}
        />
      ) : null}
      <TextFieldListItem
        label='Name'
        value={generatorMeter.name}
        InputProps={{
          readOnly: true,
          disableUnderline: true,
        }}
      />
      <IntegerFieldListItem
        label='Last Meter Reading'
        value={generatorMeter.last_meter_reading}
        InputProps={{
          readOnly: true,
          disableUnderline: true,
          endAdornment: <InputAdornment position='end'>kW</InputAdornment>,
        }}
      />
      <IntegerFieldListItem
        label='Last Net Generation'
        value={generatorMeter.last_net_generation}
        InputProps={{
          readOnly: true,
          disableUnderline: true,
          endAdornment: <InputAdornment position='end'>kW</InputAdornment>,
        }}
      />
      <TextFieldListItem
        label='Unprocessed Generation'
        value={generatorMeter.unprocessed_generation}
        InputProps={{
          readOnly: true,
          disableUnderline: true,
        }}
      />
      <TextFieldListItem
        label='Last Update'
        value={
          generatorMeter.last_update
            ? dateStringToHumanReadable(generatorMeter.last_update)
            : ''
        }
        InputProps={{
          readOnly: true,
          disableUnderline: true,
        }}
      />
    </List>
  );
}

Form.propTypes = {
  generatorMeter: PropTypes.object,
  setGeneratorMeter: PropTypes.func,
  handleClose: PropTypes.func,
};

export default Form;
