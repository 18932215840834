import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import startCase from 'lodash/startCase';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';

import { closeDialog } from '../../../store/dialogs';
import useVerifyOrgRole from '../../../store/hooks/useVerifyOrgRole';
import DialogTitleOptions from '../DialogTitleOptions';
import { navigate } from '../../../store/pages';
import {
  postOrganization,
  putOrganization,
} from '../../../store/organizations/_organizations';
import Form from './Form';
import useOrganization, {
  defaultState,
} from '../../../store/hooks/useOrganization';

function OrganizationDialog() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [organization, setOrganization] = useState(defaultState);

  const { item: user } = useSelector((state) => state.user);
  const { id, mode } = useSelector((state) => state.dialogs.organization);
  const organizationItem = useOrganization(id);
  const isOrgAdmin = useVerifyOrgRole(id, 0);

  useEffect(() => {
    if (organizationItem.org_id) {
      setOrganization({ ...organizationItem });
    } else {
      setOrganization(defaultState);
    }
  }, [organizationItem]);

  const handleClose = () => {
    dispatch(closeDialog('organization'));
    setOrganization(defaultState);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    switch (mode) {
      case 'create':
        dispatch(postOrganization(organization));
        break;

      case 'edit':
        dispatch(putOrganization(organization));
        break;

      default:
        break;
    }
    handleClose();
  };

  const handleChange = (e) => {
    let value = e.target.value;
    if (e.target.id === 'is_portfolio') {
      value = !organization.is_portfolio;
    }
    setOrganization({ ...organization, [e.target.id]: value });
  };

  const handleAddressSelect = (address) => {
    setOrganization((org) => ({
      ...org,
      address: address.formatted_address,
      location: {
        lat: address.geometry.location.lat(),
        lng: address.geometry.location.lng(),
      },
    }));
  };

  const handleAddressChange = (e) => {
    setOrganization({ ...organization, address: e.target.value });
  };

  const handleOpenConfigView = (view) => {
    dispatch(
      navigate({
        page: !organization.is_portfolio ? 'organization' : 'portfolio',
        id: organization.org_id,
        tab: 'config',
        view,
      })
    );
    handleClose();
  };

  return (
    <Dialog open={id !== ''} onClose={handleClose} fullScreen={fullScreen}>
      <form onSubmit={handleSubmit} style={theme.dialog.form}>
        <DialogTitle sx={{ p: 1, pl: 2, pb: 1 }}>
          {`${startCase(mode)} ${
            organization.is_portfolio ? 'Portfolio' : 'Organization'
          }`}
          <DialogTitleOptions mode={mode} handleClose={handleClose}>
            {organization.is_portfolio ? (
              <MenuItem onClick={() => handleOpenConfigView('organizations')}>
                Organizations
              </MenuItem>
            ) : (
              [
                <MenuItem
                  key='kiosk-url'
                  onClick={() => {
                    navigator.clipboard.writeText(
                      process.env.REACT_APP_WEB_URL +
                        'kiosk?org=' +
                        organization.kiosk_code
                    );
                  }}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={['fal', 'copy']} size='lg' />
                  </ListItemIcon>
                  <ListItemText>Kiosk URL</ListItemText>
                </MenuItem>,
                <MenuItem
                  key={'sites'}
                  onClick={() => handleOpenConfigView('sites')}>
                  Sites
                </MenuItem>,
              ]
            )}
            <MenuItem onClick={() => handleOpenConfigView('users')}>
              Users
            </MenuItem>
            <MenuItem onClick={() => handleOpenConfigView('alarms')}>
              Alarms
            </MenuItem>
          </DialogTitleOptions>
        </DialogTitle>
        <DialogContent
          sx={{
            overflow: 'inherit',
            p: 2,
            pt: 0,
            pb: 0,
          }}>
          <Form
            mode={mode}
            organization={organization}
            handleChange={handleChange}
            handleAddressChange={handleAddressChange}
            handleAddressSelect={handleAddressSelect}
          />
        </DialogContent>
        <DialogActions sx={{ p: '4px' }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type='submit'
            onClick={handleSubmit}
            color='primary'
            variant='contained'
            disabled={
              (mode === 'edit' && !isOrgAdmin) ||
              (mode === 'create' && !user.super_user)
            }>
            {(() => {
              switch (mode) {
                case 'create':
                  return 'Create';

                case 'edit':
                  return 'Update';

                default:
                  break;
              }
            })()}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default OrganizationDialog;
