import axios from '../xhr_libs/axios';
import { processResponse } from '.';

export const refreshSensorAPI = async (orgId, sensorId) => {
  return processResponse(
    await axios.get(`/resource/refresh_sensor/${orgId}/${sensorId}`)
  );
};

export const getSensorsAPI = async () => {
  return processResponse(await axios.get('/resource/sensors'));
};

export const postSensorAPI = async (body) => {
  return processResponse(await axios.post(`/resource/sensors`, body));
};

export const putSensorAPI = async (body) => {
  return processResponse(
    await axios.put(`/resource/sensors/${body.sensor_id}`, body)
  );
};

export const deleteSensorAPI = async (sensorId) => {
  return processResponse(await axios.delete(`/resource/sensors/${sensorId}`));
};
