import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import find from 'lodash/find';
import startCase from 'lodash/startCase';

import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';

import { closeDialog } from '../../../store/dialogs';
import Form from './Form';
import DialogTitleOptions from '../DialogTitleOptions';

const defaultState = {
  org_id: '',
  generator_id: '',
  account_id: '',
  account_name: '',
  name: '',
  capacity: 0,
  last_month_of_generation: '',
  last_update: '',
};

function GeneratorDialog() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { id, mode } = useSelector((state) => state.dialogs.generator);
  const generators = useSelector((state) => state.generators.data);

  const [generator, setGenerator] = useState(defaultState);

  useEffect(() => {
    const _generator = find(generators, { generator_id: id });
    if (_generator) {
      setGenerator(_generator);
    } else setGenerator(defaultState);
  }, [id, generators]);

  const handleClose = () => {
    dispatch(closeDialog('generator'));
    setGenerator(defaultState);
  };

  return (
    <Dialog open={id !== ''} onClose={handleClose} fullScreen={fullScreen}>
      <form style={theme.dialog.form}>
        <DialogTitle sx={{ p: 1, pl: 2, pb: 1 }}>
          {startCase(mode) + ' Generator'}

          <DialogTitleOptions mode={'edit'} handleClose={handleClose}>
            <MenuItem onClick={() => console.log('refresh')}>Refresh</MenuItem>
            <MenuItem
              onClick={() => console.log('delete unprocessed generation')}>
              Delete Unprocessed
            </MenuItem>
          </DialogTitleOptions>
        </DialogTitle>
        <DialogContent
          sx={{
            overflow: 'inherit',
            p: 2,
            pt: 0,
            pb: 0,
          }}>
          <Form
            mode={mode}
            generator={generator}
            setGenerator={setGenerator}
            handleClose={handleClose}
          />
        </DialogContent>
        <DialogActions sx={{ p: '4px' }}>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default GeneratorDialog;
