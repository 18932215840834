import React, { useEffect } from 'react';
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';

import { init } from '@sentry/react';
import ClipboardJS from 'clipboard';
import loadable from '@loadable/component';

import _package from '../../package.json';
import icons from '../assets/icons';
import ROUTES from '../constants/routes';
import { handleAuthCode } from '../helpers/auth';
import { getTokens, setTokens } from '../helpers/auth/tokens';
import { authenticateUser } from '../store/user';
import { setLoader } from '../store/pages';

import { Privacy, Terms } from './App/TermsAndPrivacy';
import Authentication from './Authentication';
import Layout from './App/Layout';
import Portfolio from './App/Portfolio';
import Organization from './App/Organization';
import Site from './App/Site';
import Logger from './App/Logger';
import Meter from './App/Meter';
import Inverter from './App/Inverter';
import Sensor from './App/Sensor';
import Profile from './App/Profile';

const Admin = loadable(() => import('./App/Admin'));
const KeyPerformanceIndicators = loadable(() =>
  import('./App/KeyPerformanceIndicators')
);
const Kiosk = loadable(() => import('./Kiosk'));

let didInit = false;
const APP_STR = ROUTES.AUTH.INDEX + '/';

export const clipboard = new ClipboardJS('.copy-btn');
icons();

if (['staging', 'prod', 'production'].includes(process.env.REACT_APP_ENV)) {
  init({
    dsn: 'https://82ff36eac25d4a30bd5b642223268817@o240785.ingest.sentry.io/6760676',
    environment: process.env.REACT_APP_ENV,
    release: _package.version,
  });
}

function AppRoutes() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const handleAuthCodeGrant = async (code) => {
      try {
        await handleAuthCode(code);
        setSearchParams({});
        dispatch(authenticateUser());
      } catch (err) {
        console.error(err.message);
        dispatch(push(ROUTES.UNAUTH.LOGIN));
        dispatch(setLoader(false));
      }
    };

    const handleRefreshToken = () => {
      try {
        const _refreshToken = searchParams.get('refreshToken');
        if (_refreshToken) {
          setTokens({ RefreshToken: _refreshToken });
        }

        const { refreshToken } = getTokens();
        if (refreshToken) {
          dispatch(authenticateUser());
        } else {
          dispatch(push(ROUTES.UNAUTH.LOGIN));
          dispatch(setLoader(false));
        }
      } catch (err) {
        console.error(err);
        dispatch(push(ROUTES.UNAUTH.LOGIN));
        dispatch(setLoader(false));
      }
    };

    // React.Strict renders twice in dev
    // didInit avoids trying to authenticate twice
    if (!didInit) {
      didInit = true;
      if (!location.pathname.startsWith(ROUTES.UNAUTH.KIOSK)) {
        const code = searchParams.get('code');
        dispatch(setLoader(true));
        if (code) {
          handleAuthCodeGrant(code);
        } else {
          handleRefreshToken();
        }
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className='App'>
      <Routes>
        <Route
          path={ROUTES.UNAUTH.LANDING}
          element={<Navigate to={ROUTES.UNAUTH.LOGIN} />}
        />
        <Route path={ROUTES.UNAUTH.LOGIN} element={<Authentication />} />
        <Route path={ROUTES.UNAUTH.KIOSK} element={<Kiosk />} />

        <Route path={ROUTES.AUTH.INDEX} element={<Layout />}>
          <Route
            path={ROUTES.AUTH.PORTFOLIO.replace(APP_STR, '')}
            element={<Portfolio />}
          />
          <Route
            path={ROUTES.AUTH.ORGANIZATION.replace(APP_STR, '')}
            element={<Organization />}
          />
          <Route
            path={ROUTES.AUTH.SITE.replace(APP_STR, '')}
            element={<Site />}
          />
          <Route
            path={ROUTES.AUTH.LOGGER.replace(APP_STR, '')}
            element={<Logger />}
          />
          <Route
            path={ROUTES.AUTH.METER.replace(APP_STR, '')}
            element={<Meter />}
          />
          <Route
            path={ROUTES.AUTH.INVERTER.replace(APP_STR, '')}
            element={<Inverter />}
          />
          <Route
            path={ROUTES.AUTH.SENSOR.replace(APP_STR, '')}
            element={<Sensor />}
          />

          <Route
            path={ROUTES.AUTH.ADMIN.replace(APP_STR, '')}
            element={<Admin />}
          />
          <Route
            path={ROUTES.AUTH.KPIS.replace(APP_STR, '')}
            element={<KeyPerformanceIndicators />}
          />
          <Route
            path={ROUTES.AUTH.PROFILE.replace(APP_STR, '')}
            element={<Profile />}
          />
          <Route
            path={ROUTES.AUTH.TERMS.replace(APP_STR, '')}
            element={<Terms />}
          />
          <Route
            path={ROUTES.AUTH.PRIVACY.replace(APP_STR, '')}
            element={<Privacy />}
          />
        </Route>
        <Route path={'*'} element={<Navigate to={ROUTES.UNAUTH.LOGIN} />} />
      </Routes>
    </div>
  );
}

export default AppRoutes;
