import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import startCase from 'lodash/startCase';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import MuiGrid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';

import {
  SortingState,
  IntegratedSorting,
  IntegratedFiltering,
  IntegratedPaging,
  EditingState,
  FilteringState,
  PagingState,
} from '@devexpress/dx-react-grid';
import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';

import useDeviceNamePredicate from '../../../store/hooks/useDeviceNamePredicate';
import useTriggerNamePredicate from '../../../store/hooks/useTriggerNamePredicate';
import useCompareDeviceName from '../../../store/hooks/useCompareDeviceName';
import useCompareTriggerName from '../../../store/hooks/useCompareTriggerName';
import { openDialog } from '../../../store/dialogs';
import BaseTable from './BaseTable';
import {
  AlarmStatusTypeProvider,
  AlarmLevelTypeProvider,
  TriggerTypeProvider,
  DeviceTypeProvider,
  DateTimeTypeProvider,
  OrgIdNameLinkTypeProvider,
} from '../providers';
import { EditCell } from '../cells';
import { getAllAlarms } from '../../../store/alarms/_alarms';

const defaultColumns = [
  { title: 'Active', name: 'status', width: 0.075 },
  { title: 'Level', name: 'level', width: 0.075 },
  { title: 'Trigger', name: 'trigger_id', width: 0.2 },
  { title: 'Device', name: 'device_id', width: 0.2 },
  { title: 'Message', name: 'message', width: 0.25 },
  { title: 'Date Triggered', name: 'created_on', width: 0.2 },
];

function AlarmsTable({ title = false, resource, alarms }) {
  const dispatch = useDispatch();

  const deviceNamePredicate = useDeviceNamePredicate();
  const triggerNamePredicate = useTriggerNamePredicate();
  const compareDeviceName = useCompareDeviceName();
  const compareTriggerName = useCompareTriggerName();

  const { data: allAlarms } = useSelector((state) => state.alarms);
  const [tableData, setTableData] = useState([]);
  const [integratedFilteringColumnExtensions] = useState([
    { columnName: 'device_id', predicate: deviceNamePredicate },
    { columnName: 'trigger_id', predicate: triggerNamePredicate },
  ]);
  const [integratedSortingColumnExtensions] = useState([
    { columnName: 'device_id', compare: compareDeviceName },
    { columnName: 'trigger_id', compare: compareTriggerName },
  ]);
  const [columns, setColumns] = useState(defaultColumns);

  useEffect(() => {
    if (resource?.type_ === 'organization' && resource?.is_portfolio === true) {
      setColumns([
        { title: 'Active', name: 'status', width: 0.075 },
        { title: 'Level', name: 'level', width: 0.075 },
        { title: 'Trigger', name: 'trigger_id', width: 0.2 },
        { title: 'Organization', name: 'org_id', width: 0.2 },
        { title: 'Device', name: 'device_id', width: 0.2 },
        { title: 'Date Triggered', name: 'created_on', width: 0.25 },
      ]);
    } else {
      setColumns(defaultColumns);
    }
  }, [resource]);

  useEffect(() => {
    if (alarms) {
      setTableData(alarms);
    } else {
      setTableData(allAlarms);
    }
  }, [alarms, allAlarms]);

  const handleClick = (alarm) => {
    dispatch(
      openDialog({
        mode: 'view',
        type: 'alarm',
        id: alarm.alarm_id,
      })
    );
  };

  const fetchAllAlarms = () => {
    dispatch(getAllAlarms());
  };

  return (
    <>
      <MuiGrid item xs={12} sx={{ height: 0, pt: '0 !important' }}>
        <Box
          sx={{
            position: 'relative',
            top: title ? 62 : 7,
            left: 7,
            zIndex: 1,
          }}>
          <Tooltip title='Fetch all Alarms' placement='top'>
            <IconButton onClick={fetchAllAlarms}>
              <FontAwesomeIcon icon={['fal', 'bell-plus']} size='sm' />
            </IconButton>
          </Tooltip>
        </Box>
      </MuiGrid>
      <MuiGrid item xs={12}>
        <Card raised>
          {title && (
            <CardHeader
              titleTypographyProps={{ align: 'left' }}
              title={`${startCase(resource?.type_)} Alarms`}
              sx={{ pb: 0, pt: 1 }}
            />
          )}
          <CardContent sx={{ p: 1, pt: 0, pb: '4px !important' }}>
            <BaseTable
              rows={tableData}
              columns={columns}
              editColumn={
                <TableEditColumn
                  width={42}
                  showEditCommand
                  cellComponent={(props) => (
                    <EditCell
                      disabled={false}
                      tooltipText='View Alarm'
                      icon={['fal', 'eye']}
                      handleClick={() => handleClick(props.row)}
                      {...props}
                    />
                  )}
                />
              }>
              <AlarmLevelTypeProvider for={['level']} />
              <AlarmStatusTypeProvider for={['status']} />
              <DeviceTypeProvider for={['device_id']} />
              <OrgIdNameLinkTypeProvider for={['org_id']} />
              <TriggerTypeProvider for={['trigger_id']} />
              <DateTimeTypeProvider for={['created_on']} />

              <EditingState />
              <FilteringState />
              <SortingState
                defaultSorting={[
                  { columnName: 'level', direction: 'desc' },
                  { columnName: 'created_on', direction: 'asc' },
                ]}
              />
              <PagingState defaultCurrentPage={0} pageSize={10} />

              <IntegratedFiltering
                columnExtensions={integratedFilteringColumnExtensions}
              />
              <IntegratedSorting
                columnExtensions={integratedSortingColumnExtensions}
              />
              <IntegratedPaging />
            </BaseTable>
          </CardContent>
        </Card>
      </MuiGrid>
    </>
  );
}

AlarmsTable.propTypes = {
  title: PropTypes.bool,
  resource: PropTypes.object,
  alarms: PropTypes.array,
};

export default AlarmsTable;
