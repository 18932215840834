import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';
import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import each from 'lodash/each';
import get from 'lodash/get';
import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';
import remove from 'lodash/remove';
import toArray from 'lodash/toArray';
import isNull from 'lodash/isNull';

import {
  postLoggerAPI,
  getLoggersAPI,
  putLoggerAPI,
  deleteLoggerAPI,
  refreshLoggerAPI,
} from '../../api';
import { updateTriggerDevices } from '../alarms';
import { dateStringToDayJS, getLatestInterval } from '../../helpers/dates';
import { updateNodes } from '../nodes';

const getLoggers = createAsyncThunk(
  'loggers/getLoggers',
  async (_, { getState, requestId }) => {
    const { currentRequestId, loading } = getState().loggers;
    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    return { data: await getLoggersAPI() };
  }
);

const refreshLoggers = createAsyncThunk(
  'loggers/refreshLoggers',
  async (loggerIds, { getState, dispatch, requestId }) => {
    const {
      data: loggers,
      loaded,
      loading,
      currentRequestId,
    } = getState().loggers;
    let allLoggers = cloneDeep(loggers);

    if (!loading || requestId !== currentRequestId) {
      return;
    }

    try {
      let latestInterval = getLatestInterval();
      let _loggers = filter(
        map(toArray(loggerIds), (loggerId) => {
          return find(loggers, { logger_id: loggerId });
        }),
        (logger) => {
          if (isNull(logger) || !logger.active) return false;

          let lastOk = dateStringToDayJS(get(logger, 'stats.last_ok_time'));
          let lastFail = dateStringToDayJS(get(logger, 'stats.last_fail_time'));

          return (
            !loaded ||
            lastOk.isBefore(latestInterval) ||
            (lastFail.isAfter(lastOk) && lastFail.isBefore(latestInterval))
          );
        }
      );

      // refresh loggers
      if (_loggers.length > 0) {
        dispatch(showLoading());
        let resolvedLoggers = await Promise.all(
          map(
            _loggers,
            async (logger) =>
              await refreshLoggerAPI(logger.org_id, logger.logger_id)
          )
        ).then((updatedLoggers) => {
          each(updatedLoggers, (updatedLogger) => {
            remove(allLoggers, {
              logger_id: get(updatedLogger, 'logger_id'),
            });
          });
          return concat(allLoggers, updatedLoggers);
        });
        return { data: resolvedLoggers, loaded: true };
      }
    } catch (err) {
      console.error('Logger refresh failed');
    } finally {
      dispatch(hideLoading());
    }
  }
);

const postLogger = createAsyncThunk(
  'loggers/postLogger',
  async (data, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        data: allLoggers,
      } = getState().loggers;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      let { logger, trigger_devices: triggerDevices } = await postLoggerAPI(
        data
      );

      dispatch(updateTriggerDevices(triggerDevices));
      toastr.success('Logger created');
      const loggers = concat(allLoggers, logger);

      dispatch(updateNodes({ loggers }));

      return { data: loggers };
    } catch (err) {
      console.log('err: ', err);
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

const putLogger = createAsyncThunk(
  'loggers/putLogger',
  async (logger, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading, data: loggers } = getState().loggers;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      let updatedLogger = await putLoggerAPI(logger);

      let _loggers = cloneDeep(loggers);
      remove(_loggers, { logger_id: get(updatedLogger, 'logger_id') });

      toastr.success('Logger updated');
      _loggers = concat(_loggers, updatedLogger);
      dispatch(updateNodes({ loggers: _loggers }));
      return { data: _loggers };
    } catch (err) {
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deleteLogger = createAsyncThunk(
  'loggers/deleteLogger',
  async (data, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        data: allLoggers,
      } = getState().loggers;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      let deletedLoggerId = await deleteLoggerAPI(data.logger_id);
      let loggers = cloneDeep(allLoggers);
      remove(loggers, { logger_id: deletedLoggerId });
      toastr.success('Logger deleted');

      dispatch(updateNodes({ loggers }));

      return { data: loggers };
    } catch (err) {
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { postLogger, getLoggers, putLogger, deleteLogger, refreshLoggers };
