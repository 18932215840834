import React, { useEffect, useState } from 'react';
import { useStateValidator } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import find from 'lodash/find';
import findKey from 'lodash/findKey';
import startCase from 'lodash/startCase';
import map from 'lodash/map';
import isEmail from 'validator/es/lib/isEmail';

import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { ORG_USER_ROLES, ORG_USER_ROLES as ROLES } from '../../../constants';
import { closeDialog, openConfirmDialog } from '../../../store/dialogs';
import useVerifyOrgRole from '../../../store/hooks/useVerifyOrgRole';
import {
  deleteMembership,
  postMembership,
  putMembership,
} from '../../../store/memberships/_memberships';
import DialogTitleOptions from '../DialogTitleOptions';
import { TextFieldListItem } from '../../List/TextFieldListItem';
import OrganizationSelect from './OrganizationSelect';
import UserSelect from './UserSelect';

const defaultState = {
  name: '',
  email: '',
  org_id: '',
  role: 2,
};
function MembershipDialog() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const organizations = useSelector((state) => state.organizations.data);
  const { id, mode, orgId } = useSelector((state) => state.dialogs.membership);
  const isOrgAdmin = useVerifyOrgRole(orgId, 0);
  const [membership, setMembership] = useState(defaultState);
  const [organization, setOrganization] = useState({});
  const [emailValid] = useStateValidator(membership.email, (email) =>
    isEmail(email)
  );

  const { data: memberships } = useSelector((state) => state.memberships);

  useEffect(() => {
    setOrganization(find(organizations, { org_id: orgId }));
  }, [orgId, organizations]);

  useEffect(() => {
    if (id && orgId && memberships) {
      const _membership = find(memberships, { org_id: orgId, user_id: id });
      if (_membership) setMembership(_membership);
    } else {
      setMembership((_membership) => ({
        ..._membership,
        org_id: id || '',
      }));
    }
  }, [id, memberships, orgId]);

  const handleClose = () => {
    dispatch(closeDialog('membership'));
    setMembership(defaultState);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    switch (mode) {
      case 'create':
        if (!membership.email) {
          toastr.error('Email is required');
          return;
        }

        if (!emailValid) {
          toastr.error('Invalid email address');
          return;
        }

        const newMembership = {
          ...membership,
          email: membership.email.toLowerCase(),
          org_id: orgId || membership.org_id,
          role: Number(membership.role),
        };

        dispatch(postMembership(newMembership));
        break;

      case 'edit':
        dispatch(putMembership(membership));
        break;

      default:
        break;
    }
    handleClose();
  };

  const handleDelete = () => {
    handleClose();
    dispatch(
      openConfirmDialog({
        title: 'Remove User From Organization',
        message:
          'Are you sure you want to remove this user from the organization?',
        onConfirm: () => dispatch(deleteMembership(membership)),
      })
    );
  };

  const handleChange = (e) => {
    let value = e.target.value;
    let id = e.target.id;

    if (id === 'role') {
      value = findKey(ORG_USER_ROLES, (role) => role === value);
    }

    setMembership((_membership) => ({ ..._membership, [id]: value }));
  };

  const handleChangeLicense = (e, license) => {
    setMembership((_membership) => ({
      ..._membership,
      licenses: { ..._membership.licenses, [license]: e.target.checked },
    }));
  };

  return (
    <Dialog open={id !== ''} onClose={handleClose} fullScreen={fullScreen}>
      <form onSubmit={handleSubmit} style={theme.dialog.form}>
        <DialogTitle sx={{ p: 1, pl: 2, pb: 0 }}>
          {startCase(mode)} Organization Membership
          <DialogTitleOptions mode={mode} handleClose={handleClose}>
            {isOrgAdmin && mode === 'edit' ? (
              <MenuItem onClick={handleDelete}>Delete</MenuItem>
            ) : null}
          </DialogTitleOptions>
        </DialogTitle>
        <DialogContent sx={{ p: 2, pt: 0, pb: 0 }}>
          <List disablePadding>
            {!orgId && mode === 'create' && (
              <OrganizationSelect
                relation={membership}
                setRelation={setMembership}
              />
            )}
            {orgId && mode === 'edit' && (
              <TextFieldListItem
                label='Organization'
                value={organization?.name || ''}
                InputProps={{ readOnly: true, disableUnderline: true }}
              />
            )}
            {!orgId ? (
              <UserSelect relation={membership} setRelation={setMembership} />
            ) : (
              <TextFieldListItem
                required
                id='user-email'
                label='Email'
                value={membership.email}
                onChange={(e) =>
                  handleChange({
                    target: { id: 'email', value: e.target.value },
                  })
                }
                InputProps={{
                  readOnly: mode === 'edit',
                  disableUnderline: mode === 'edit',
                }}
              />
            )}

            <TextFieldListItem
              label='Name'
              value={membership.name}
              onChange={(e) =>
                handleChange({
                  target: { id: 'name', value: e.target.value },
                })
              }
              InputProps={{
                readOnly: mode === 'edit',
                disableUnderline: mode === 'edit',
              }}
            />

            <ListItem sx={{ pl: 0, pr: 0, pt: 1, pb: 1 }}>
              <FormControl variant='standard' size='small' fullWidth>
                <InputLabel id='role-select-label'>Role</InputLabel>
                <Select
                  fullWidth
                  labelId='role-select-label'
                  id='role'
                  value={ROLES[membership.role] || ''}
                  onChange={(e) =>
                    handleChange({
                      target: { id: 'role', value: e.target.value },
                    })
                  }
                  MenuProps={{ MenuListProps: { disablePadding: true } }}>
                  {map(ROLES, (value, key) => (
                    <MenuItem key={key} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </ListItem>
            {organization?.licenses?.reporting && (
              <ListItem sx={{ pl: 0, pr: 0, pb: 0 }}>
                <ListItemText>Feature Access</ListItemText>
              </ListItem>
            )}
            {organization?.licenses?.reporting && (
              <ListItem sx={{ p: 0 }}>
                <FormControlLabel
                  label='Reporting'
                  control={
                    <Checkbox
                      disabled={!isOrgAdmin}
                      checked={membership?.licenses?.reporting || false}
                      onChange={(e) => handleChangeLicense(e, 'reporting')}
                    />
                  }
                />
              </ListItem>
            )}
          </List>
        </DialogContent>
        <DialogActions sx={{ p: '4px' }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type='submit'
            onClick={handleSubmit}
            color='primary'
            variant='contained'>
            {mode === 'create' ? 'Create' : 'Update'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default MembershipDialog;
