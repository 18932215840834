import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import filter from 'lodash/filter';
import first from 'lodash/first';

import useTheme from '@mui/material/styles/useTheme';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';

import ChangePasswordForm from './ChangePasswordForm';
import UserMembershipsTable from './UserMembershipsTable';
import APIInfo from './APIInfo';
import ViewStack from '../../../components/ViewStack';
import AlarmsConfigurationTable from '../../../components/table/tables/AlarmsConfigurationTable';
import ReportSubscriptionsTable from './ReportSubscriptionsTable';
import { toggleThemeMode } from '../../../store/user';

const VIEWS = {
  CHANGE_PASSWORD: {
    name: 'change password',
    description: 'Change your password',
  },
  MEMBERSHIPS: {
    name: 'memberships',
    description: 'View your organization memberships',
  },
  ALARM_SUBSCRIPTIONS: {
    name: 'alarm subscriptions',
    description: 'Subscribe and unsubscribe to all device alarms',
  },
};

export default function Profile() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { item: user } = useSelector((state) => state.user);
  const { data: memberships } = useSelector((state) => state.memberships);
  const [selectedView, setSelectedView] = useState(VIEWS.CHANGE_PASSWORD.name);
  const [views, setViews] = useState(VIEWS);

  useEffect(() => {
    let _views = VIEWS;
    if (user?.api) {
      _views = {
        ..._views,
        API: {
          name: 'api',
          description: 'view api key and usage',
        },
      };
    }
    let _reportingMemberships = filter(
      memberships,
      (membership) =>
        membership?.user_id === user?.user_id && membership?.licenses?.reporting
    );
    if (user?.super_user || _reportingMemberships.length) {
      _views = {
        ..._views,
        REPORTS: {
          name: 'reports',
          description: 'manage report subscriptions',
        },
      };
    }
    setViews(_views);
  }, [user, memberships]);

  const handleAvatarClick = () => {
    dispatch(toggleThemeMode());
  };

  const renderView = () => {
    if (selectedView === views.CHANGE_PASSWORD.name) {
      return <ChangePasswordForm />;
    } else if (selectedView === views.MEMBERSHIPS.name) {
      return <UserMembershipsTable />;
    } else if (selectedView === views.ALARM_SUBSCRIPTIONS.name) {
      return (
        <AlarmsConfigurationTable
          resource={user}
          orgSelect={true}
          typeSelect={true}
        />
      );
    } else if (selectedView === views?.API?.name) {
      return <APIInfo />;
    } else if (selectedView === views?.REPORTS?.name) {
      return <ReportSubscriptionsTable />;
    }
    return null;
  };

  return (
    <Grid
      container
      spacing={2}
      direction='row'
      justifyContent='center'
      sx={{ mt: 1 }}>
      <Grid item xs={10}>
        <Card raised>
          <CardHeader
            sx={{ p: 1 }}
            avatar={
              <Avatar
                sx={{ bgcolor: theme.palette.veregy_colors.grey }}
                onClick={handleAvatarClick}>
                {first(get(user, 'name'))}
              </Avatar>
            }
            title={user?.email}
            subheader={user?.name}
            titleTypographyProps={{
              align: 'left',
            }}
            subheaderTypographyProps={{
              align: 'left',
            }}
          />
        </Card>
      </Grid>
      <Grid item xs={10} sx={{ paddingTop: '8px !important' }}>
        <ViewStack
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          views={views}
        />
      </Grid>
      <Grid item xs={10} sx={{ paddingTop: '8px !important' }}>
        {renderView()}
      </Grid>
    </Grid>
  );
}
