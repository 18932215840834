import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import {
  FilteringState,
  IntegratedPaging,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { closeDialog } from '../../store/dialogs';
import DialogTitleOptions from './DialogTitleOptions';
import BaseTable from '../table/tables/BaseTable';

function EmailedUsersDialog() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { alarm } = useSelector((state) => state.dialogs.emailedUsers);
  const allUsers = useSelector((state) => state.admin.users);
  const [users, setUsers] = useState([]);
  const [columns] = useState([
    { title: 'Name', name: 'name', width: 0.45 },
    { title: 'Email', name: 'email', width: 0.45 },
  ]);

  useEffect(() => {
    if (!isEmpty(alarm)) {
      const _users = map(alarm?.emailed_users, (user_id) => {
        return find(allUsers, { user_id: user_id });
      });
      setUsers(_users);
    }
  }, [alarm, allUsers]);

  const handleClose = () => {
    dispatch(closeDialog('emailedUsers'));
  };

  return (
    <Dialog
      open={!isEmpty(alarm)}
      onClose={handleClose}
      fullScreen={fullScreen}>
      <DialogTitle sx={{ p: 1, pl: 2, pb: 0 }}>
        Emailed Users
        <DialogTitleOptions handleClose={handleClose} />
      </DialogTitle>
      <DialogContent
        sx={{
          minHeight: 50,
          minWidth: theme.dialog.form.minWidth,
          p: 2,
          pt: 0,
          pb: 0,
        }}>
        <BaseTable rows={users} columns={columns}>
          <FilteringState />
          <SortingState
            defaultSorting={[{ columnName: 'name', direction: 'asc' }]}
          />
          <PagingState defaultCurrentPage={0} pageSize={10} />
          <IntegratedPaging />
        </BaseTable>
      </DialogContent>
      <DialogActions sx={{ p: '4px' }}>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

export default EmailedUsersDialog;
