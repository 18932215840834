import axios from '../xhr_libs/axios';
import { processResponse } from '.';

export const getLicensesAPI = async () => {
  return processResponse(await axios.get('/resource/licenses'));
};

export const putLicenseAPI = async (license) => {
  return processResponse(
    await axios.put(
      `/resource/licenses/${license.org_id}/${license.name}`,
      license
    )
  );
};
