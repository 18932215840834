import axios from '../xhr_libs/axios';
import { processResponse } from '.';

export const getUserReportsAPI = async () => {
  return processResponse(await axios.get('/resource/user_reports'));
};

export const postUserReportAPI = async (body) => {
  return processResponse(await axios.post(`/resource/user_reports`, body));
};

export const deleteUserReportAPI = async (userReportId) => {
  return processResponse(
    await axios.delete(`/resource/user_reports/${userReportId}`)
  );
};
