import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import numeral from 'numeral';
import get from 'lodash/get';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import reduce from 'lodash/reduce';

import useTheme from '@mui/material/styles/useTheme';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const sumValues = (type, payload) => {
  return reduce(
    filter(payload, (datapoint) => includes(datapoint.dataKey, type)),
    (acc, datapoint) => {
      return acc + get(datapoint, 'value', 0);
    },
    0
  );
};

function Tooltip({ active, payload, label, unit }) {
  const theme = useTheme();
  if (active && payload && payload.length) {
    let actual = sumValues('actual', payload);
    let expected = sumValues('expected', payload);

    return (
      <Card raised sx={{ width: '20rem' }}>
        <CardHeader
          sx={{
            '&.MuiCardHeader-root': { padding: 0, pt: 1 },
          }}
          titleTypographyProps={{ variant: 'body1', align: 'center' }}
          title={dayjs(Number(label)).format('YYYY MMMM')}
        />
        <CardContent sx={{ p: 1, pb: '0px !important' }}>
          <List disablePadding>
            <ListItem disablePadding disableGutters>
              <ListItemText
                primaryTypographyProps={{
                  align: 'left',
                  color: theme.palette.veregy_colors.blue,
                }}>
                Actual
              </ListItemText>
              <ListItemText
                primaryTypographyProps={{
                  align: 'right',
                  color: theme.palette.veregy_colors.blue,
                }}>
                {numeral(actual).format('0,0.0') + ' ' + unit}
              </ListItemText>
            </ListItem>
            <ListItem disablePadding disableGutters>
              <ListItemText
                primaryTypographyProps={{
                  align: 'left',
                  color: theme.palette.veregy_colors.orange,
                }}>
                Expected
              </ListItemText>
              <ListItemText
                primaryTypographyProps={{
                  align: 'right',
                  color: theme.palette.veregy_colors.orange,
                }}>
                {numeral(expected).format('0,0.0') + ' ' + unit}
              </ListItemText>
            </ListItem>
            <ListItem disablePadding disableGutters>
              <ListItemText primaryTypographyProps={{ align: 'left' }}>
                Production Index
              </ListItemText>
              <ListItemText primaryTypographyProps={{ align: 'right' }}>
                {numeral(expected ? actual / expected : 0).format('0,0.00')}
              </ListItemText>
            </ListItem>
            <Divider />
            <ListItem disablePadding disableGutters>
              <ListItemText primaryTypographyProps={{ align: 'center' }}>
                Click to see all sites
              </ListItemText>
            </ListItem>
          </List>
        </CardContent>
      </Card>
    );
  }

  return null;
}

Tooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  label: PropTypes.number,
  unit: PropTypes.string,
};

export default Tooltip;
