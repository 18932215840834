import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import find from 'lodash/find';

const defaultState = {
  active: false,
  force_active: false,
  name: null,
  org_id: null,
  valid: null,
  effectiveStatus: false,
};

const useLicense = (orgId, licenseType) => {
  const { data: licenses } = useSelector((state) => state.licenses);
  const [license, setLicense] = useState(defaultState);

  useEffect(() => {
    let license = find(licenses, { org_id: orgId, name: licenseType });

    if (license) {
      let effectiveStatus = false;
      if (license?.force_active === false) effectiveStatus = false;
      else if (license?.force_active === true) effectiveStatus = true;
      else effectiveStatus = license?.active;
      setLicense({ ...license, effectiveStatus });
    }
  }, [licenses, orgId, licenseType]);

  return license;
};

export default useLicense;
