import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';

import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { closeDialog } from '../../../store/dialogs';
import DialogTitleOptions from '../DialogTitleOptions';
import CreateTable from './CreateTable';
import DeleteForm from './DeleteForm';
import {
  deletePortfolioMembership,
  postPortfolioMemberships,
} from '../../../store/admin';
import usePortfolioMembers from '../../../store/hooks/usePortfolioMembers';

function OrgPortfolioRelationDialog() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { id, mode } = useSelector(
    (state) => state.dialogs.portfolio_membership
  );
  const { id: portfolioId } = useSelector((state) => state.pages.portfolio);
  const { data: organizations } = useSelector((state) => state.organizations);
  const { portfolioMembers } = usePortfolioMembers(portfolioId);

  const [childOrgs, setChildOrgs] = useState([]);
  const [selection, setSelection] = useState([]);
  const [deleteOrg, setDeleteOrg] = useState({});

  useEffect(() => {
    if (mode === 'create') {
      setChildOrgs(
        filter(organizations, (organization) => {
          let child = find(portfolioMembers, { org_id: organization.org_id });
          return !child && !organization.is_portfolio;
        })
      );
    }
  }, [portfolioMembers, organizations, mode, portfolioId]);

  const handleClose = () => {
    setSelection([]);
    dispatch(closeDialog('portfolio_membership'));
  };
  const handleSubmit = () => {
    const childOrgIds = map(selection, (idx) => childOrgs[idx].org_id);
    dispatch(
      postPortfolioMemberships({
        portfolioId,
        childOrgIds,
      })
    );
    handleClose();
  };

  const handleDelete = () => {
    dispatch(
      deletePortfolioMembership({
        portfolioId,
        childOrgId: deleteOrg.org_id,
      })
    );
    handleClose();
  };

  return (
    <Dialog open={id !== ''} onClose={handleClose} fullScreen={fullScreen}>
      <form onSubmit={handleSubmit} style={theme.dialog.form}>
        <DialogTitle sx={{ p: 1, pl: 2, pb: 0 }}>
          {mode === 'edit' ? 'Delete' : 'Create'} Portfolio Membership
          <DialogTitleOptions handleClose={handleClose} />
        </DialogTitle>
        <DialogContent sx={{ p: 2, pt: 0, pb: 0 }}>
          {(() => {
            switch (mode) {
              case 'create':
                return (
                  <CreateTable
                    childOrgs={childOrgs}
                    selection={selection}
                    setSelection={setSelection}
                  />
                );
              case 'edit':
                return (
                  <DeleteForm
                    selectedOrg={deleteOrg}
                    setSelectedOrg={setDeleteOrg}
                  />
                );
              default:
                return null;
            }
          })()}
        </DialogContent>
        <DialogActions sx={{ p: '4px' }}>
          <Button onClick={handleClose}>Cancel</Button>
          {mode === 'edit' ? (
            <Button onClick={handleDelete} color='error' variant='contained'>
              Delete
            </Button>
          ) : null}
          {mode === 'create' ? (
            <Button
              type='submit'
              onClick={handleSubmit}
              color='primary'
              variant='contained'>
              Create
            </Button>
          ) : null}
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default OrgPortfolioRelationDialog;
