import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ActionButton(props) {
  const {
    disabled,
    onClick,
    tooltipTitle,
    placement = 'right',
    icon = 'cog',
  } = props;

  const iconButton = (
    <IconButton
      sx={{
        display: 'flex',
      }}
      disabled={disabled}
      onClick={onClick}>
      <FontAwesomeIcon icon={['fal', icon]} size='sm' />
    </IconButton>
  );

  if (disabled) {
    return iconButton;
  } else {
    return (
      <Tooltip title={tooltipTitle} placement={placement}>
        {iconButton}
      </Tooltip>
    );
  }
}

ActionButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  tooltipTitle: PropTypes.string.isRequired,
  placement: PropTypes.string,
  size: PropTypes.string,
  icon: PropTypes.string,
};
export default ActionButton;
