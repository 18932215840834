import React, { useEffect, useState } from 'react';
import { useMeasure } from 'react-use';
import PropTypes from 'prop-types';

import map from 'lodash/map';
import get from 'lodash/get';

import {
  Grid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableEditRow,
  TableFilterRow,
  TableInlineCellEditing,
  TableHeaderRow,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui';

import Header from '../Header';
import { TableCell, HeaderCell } from '../cells';
// import { Pager } from './Pager';

function BaseTable(props) {
  const {
    rows,
    columns,
    tableColumnExtensions = [],
    children,
    editColumn,
    editRow,
    selection,
    detail = null,
    filtering = true,
    headerRow = true,
    inlineEditing,
  } = props;
  const [tableRef, { width }] = useMeasure();

  const [columnWidths, setColumnWidths] = useState([]);

  useEffect(() => {
    const availableWidith = width - get(editColumn, 'props.width', 0);
    if (width) {
      setColumnWidths(
        map(columns, (column) => {
          return {
            columnName: column.name,
            width: availableWidith * column.width,
          };
        })
      );
    }
  }, [width, columns, editColumn]);

  return (
    <div ref={tableRef}>
      <Grid rows={rows} columns={columns}>
        {children}
        <Table
          columnExtensions={tableColumnExtensions}
          cellComponent={TableCell}
        />
        {selection && <TableSelection showSelectAll />}
        <TableColumnResizing
          columnWidths={columnWidths}
          onColumnWidthsChange={setColumnWidths}
          resizingMode='nextColumn'
        />

        {headerRow && (
          <TableHeaderRow
            showSortingControls={filtering}
            contentComponent={Header}
            cellComponent={HeaderCell}
          />
        )}
        {inlineEditing && <TableInlineCellEditing selectTextOnEditStart />}
        {detail}
        {editColumn && editColumn}
        {editRow && <TableEditRow />}

        {filtering && rows.length > 10 && <TableFilterRow />}
        {rows.length > 10 && (
          <PagingPanel
          // containerComponent={Pager}
          />
        )}
      </Grid>
    </div>
  );
}

BaseTable.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  tableColumnExtensions: PropTypes.array,
  editColumn: PropTypes.object,
  editRow: PropTypes.bool,
  selection: PropTypes.bool,
  filtering: PropTypes.bool,
  inlineEditing: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default BaseTable;
