import { createSlice } from '@reduxjs/toolkit';
import { appSettings as initialState } from '../initialState';

const { reducer, actions } = createSlice({
  name: 'appSettings',
  initialState,
  reducers: {
    setTitleBarStyles(state, action) {
      state.titleBarStyles = action.payload;
    },
  },
});

const { setTitleBarStyles } = actions;
export { setTitleBarStyles };
export default reducer;
