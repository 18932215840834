import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MuiGrid from '@mui/material/Grid';

import {
  SortingState,
  IntegratedSorting,
  IntegratedFiltering,
  IntegratedPaging,
  EditingState,
  FilteringState,
  PagingState,
} from '@devexpress/dx-react-grid';
import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';

import useVerifyOrgRole from '../../../store/hooks/useVerifyOrgRole';
import { openDialog } from '../../../store/dialogs';
import BaseTable from './BaseTable';
import { MembershipRoleTypeProvider } from '../providers';
import { EditCell, HeaderEditCell } from '../cells';

function MembershipsTable(props) {
  const { relations, orgId } = props;
  const dispatch = useDispatch();
  const isOrgAdmin = useVerifyOrgRole(orgId, 0);
  const columns = [
    { title: 'Role', name: 'role', width: 0.15 },
    { title: 'Name', name: 'name', width: 0.3 },
    { title: 'Email', name: 'email', width: 0.4 },
  ];

  const handleEditClick = (membership) => {
    dispatch(
      openDialog({
        type: membership.type_,
        mode: 'edit',
        id: membership.user_id,
        orgId: membership.org_id,
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      openDialog({
        orgId: orgId,
        type: 'membership',
        mode: 'create',
      })
    );
  };

  return (
    <MuiGrid container alignItems='center' direction='column'>
      <MuiGrid item xs={12}>
        <Card raised>
          <CardContent sx={{ p: 1, pt: 0, pb: '4px !important' }}>
            <BaseTable
              rows={relations}
              columns={columns}
              editColumn={
                <TableEditColumn
                  width={42}
                  showEditCommand
                  cellComponent={(props) => (
                    <EditCell
                      disabled={!isOrgAdmin}
                      tooltipText='Edit Membership'
                      // eslint-disable-next-line
                      handleClick={() => handleEditClick(props.row)}
                      {...props}
                    />
                  )}
                  headerCellComponent={(props) => {
                    return (
                      <HeaderEditCell
                        disabled={!isOrgAdmin}
                        tooltipText='Create Membership'
                        handleClick={handleCreateClick}
                        {...props}
                      />
                    );
                  }}
                />
              }>
              <MembershipRoleTypeProvider for={['role']} />

              <EditingState />
              <FilteringState />
              <SortingState
                defaultSorting={[
                  { columnName: 'role', direction: 'desc' },
                  { columnName: 'name', direction: 'asc' },
                ]}
              />
              <PagingState defaultCurrentPage={0} pageSize={10} />

              <IntegratedFiltering />
              <IntegratedSorting />
              <IntegratedPaging />
            </BaseTable>
          </CardContent>
        </Card>
      </MuiGrid>
    </MuiGrid>
  );
}

MembershipsTable.propTypes = {
  relations: PropTypes.array.isRequired,
  orgId: PropTypes.string,
};

export default MembershipsTable;
