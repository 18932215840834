import each from 'lodash/each';
import get from 'lodash/get';
import find from 'lodash/find';
import filter from 'lodash/filter';
import map from 'lodash/map';
import reduce from 'lodash/reduce';

const WEB_URL = process.env.REACT_APP_WEB_URL;

function generateKioskURL(kioskCode, siteId) {
  return WEB_URL + `kiosk/${kioskCode}` + (siteId ? `/${siteId}` : '');
}

const getSiteInvertersAndSensors = (
  site,
  allMeters,
  allInverters,
  allSensors
) => {
  let sensorIds = new Set([]);
  let siteMeters = filter(allMeters, { site_id: get(site, 'site_id') });
  let siteMeterIds = map(siteMeters, (meter) => meter.meter_id);
  let siteInverters = reduce(
    siteMeters,
    (_inverters, meter) => {
      let meterInverters = filter(allInverters, {
        meter_id: meter.meter_id,
      });
      return _inverters.concat(meterInverters);
    },
    []
  );

  let weatherStationId = get(site, 'weather_station_id');
  if (weatherStationId) sensorIds.add(weatherStationId);

  each(siteInverters, (inverter) => {
    let inverterSensorId = get(inverter, 'sensor_id');
    if (inverterSensorId) sensorIds.add(inverterSensorId);
  });

  each(allSensors, (sensor) => {
    if (siteMeterIds.indexOf(sensor.meter_id) !== -1) {
      sensorIds.add(sensor.sensor_id);
    }
  });

  let siteSensors = map([...sensorIds], (sensorId) => {
    return find(allSensors, (sensor) => sensor.sensor_id === sensorId);
  });

  return [siteMeters, siteInverters, siteSensors];
};

const numberToCurrency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

const renderLatLngValue = (value, defaultValue = '') => {
  if (!value) return defaultValue;
  return value.toFixed(2) + '°';
};

export {
  generateKioskURL,
  getSiteInvertersAndSensors,
  numberToCurrency,
  renderLatLngValue,
};
