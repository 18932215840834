import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import map from 'lodash/map';
import slice from 'lodash/slice';

import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import {
  SortingState,
  IntegratedSorting,
  IntegratedFiltering,
  FilteringState,
  PagingState,
  SelectionState,
  IntegratedSelection,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';

import {
  MeterIdNameLinkTypeProvider,
  SiteIdNameLinkTypeProvider,
} from '../../table/providers';

import ROUTES from '../../../constants/routes';
import Tooltip from './Tooltip';
import DialogTitleOptions from '../../Dialogs/DialogTitleOptions';
import BaseTable from '../../table/tables/BaseTable';

const COLUMNS = [
  { title: 'Sensor', name: 'name' },
  { title: 'Meter', name: 'meter_id' },
  { title: 'Site', name: 'site_id' },
];

const getColumns = (pathname) => {
  switch (pathname) {
    case ROUTES.AUTH.SITE:
      return slice(COLUMNS, 0, 1);

    case ROUTES.AUTH.METER:
    case ROUTES.AUTH.SENSOR:
      return [COLUMNS[0]];

    case ROUTES.AUTH.ORGANIZATION:
    default:
      return COLUMNS;
  }
};

function SelectSensors(props) {
  const { allSensors, selectedSensors, setSelectedSensors } = props;
  const theme = useTheme();
  const location = useLocation();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [columns] = useState(getColumns(location.pathname));

  const { data: meters } = useSelector((state) => state.meters);

  const [open, setOpen] = useState(false);
  const [sensors, setSensors] = useState([]);
  const [selection, setSelection] = useState([]);
  const [sorting, setSorting] = useState([{ columnName: 'name' }]);

  useEffect(() => {
    setSensors(
      map(allSensors, (sensor) => {
        const meter = find(meters, { meter_id: sensor.meter_id });
        return { ...sensor, site_id: meter.site_id };
      })
    );
    /* eslint-disable-next-line */
  }, [allSensors]);

  useEffect(() => {
    setSelection(
      map(selectedSensors, (sensor) => {
        return findIndex(allSensors, { sensor_id: sensor.sensor_id });
      })
    );
    /* eslint-disable-next-line */
  }, [open]);

  const handleSelect = () => {
    setSelectedSensors(
      map(selection, (idx) => {
        return allSensors[idx];
      })
    );
    setSelection([]);
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip
        title={
          <List disablePadding>
            {map(selectedSensors, (sensor) => (
              <ListItem sx={{ p: 1, pt: 0, pb: 0 }} key={sensor?.sensor_id}>
                <ListItemText
                  primaryTypographyProps={{
                    color: theme.palette.primary.main,
                  }}>
                  {sensor?.name}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        }>
        <Button
          color='primary'
          onClick={() => setOpen(true)}
          sx={{ width: '4rem' }}>
          Sensors
        </Button>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} fullScreen={fullScreen}>
        <DialogTitle sx={{ p: 1, pl: 2, pb: 0 }}>
          Select Sensors
          <DialogTitleOptions handleClose={handleClose} />
        </DialogTitle>
        <DialogContent sx={{ p: 0, pl: 1, pr: 1 }}>
          <BaseTable rows={sensors} columns={columns} selection>
            <SiteIdNameLinkTypeProvider for={['site_id']} />
            <MeterIdNameLinkTypeProvider for={['meter_id']} />

            <SelectionState
              selection={selection}
              onSelectionChange={setSelection}
            />
            <FilteringState />
            <SortingState sorting={sorting} onSortingChange={setSorting} />
            <PagingState defaultCurrentPage={0} pageSize={10} />

            <IntegratedFiltering />
            <IntegratedSorting />
            <IntegratedSelection />
            <IntegratedPaging />
          </BaseTable>
        </DialogContent>
        <DialogActions sx={{ p: '4px' }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant='contained' onClick={handleSelect}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

SelectSensors.propTypes = {
  allSensors: PropTypes.array,
  selectedSensors: PropTypes.array,
  setSelectedSensors: PropTypes.func,
};

export default SelectSensors;
