import axios from '../xhr_libs/axios';
import { processResponse } from '.';

export const getKPITimeseriesAPI = async (metrics, start, end) => {
  return processResponse(
    await axios.get(`/resource/timestream/kpis/${start}/${end}`, {
      params: {
        metrics: JSON.stringify(metrics),
      },
    })
  );
};

export const getDeviceTimeseriesAPI = async (
  resourceId,
  start,
  end,
  attributes
) => {
  return processResponse(
    await axios.get(
      `/resource/timestream-device/${resourceId}/${start}/${end}`,
      {
        params: {
          attributes: JSON.stringify(attributes),
        },
      }
    )
  );
};

export const getProductionTimeseriesAPI = async (resourceId, start, end) => {
  return processResponse(
    await axios.get(`/resource/timestream/${resourceId}/${start}/${end}`)
  );
};

export const postBackfillRequestAPI = async (meterId, start, end) => {
  return processResponse(
    await axios.post(`/resource/timestream/${meterId}`, {
      start,
      end,
    })
  );
};
