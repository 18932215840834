import React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';

function NoOrgMessage() {
  return (
    <Grid container direction='row' justifyContent='center' p={3}>
      <Grid item xs={12}>
        <Card raised>
          <CardContent>
            <List disablePadding>
              <ListItem disableGutters disablePadding>
                <ListItemText
                  primary='This account does not have any organization memberships'
                  primaryTypographyProps={{ align: 'center' }}
                />
              </ListItem>
              <ListItem disableGutters disablePadding>
                <ListItemText
                  primary='Contact an administrator for further assistance'
                  primaryTypographyProps={{ align: 'center' }}
                />
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default NoOrgMessage;
