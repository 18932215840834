import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import {
  SortingState,
  IntegratedSorting,
  IntegratedFiltering,
  EditingState,
  FilteringState,
  PagingState,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';

import useVerifyOrgRole from '../../../store/hooks/useVerifyOrgRole';
import { openDialog } from '../../../store/dialogs';
import BaseTable from './BaseTable';
import { EditCell, HeaderEditCell } from '../cells';
import usePortfolioMembers from '../../../store/hooks/usePortfolioMembers';

function OrgPortfolioRelationsTable() {
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.pages.portfolio);
  const { portfolioMembers } = usePortfolioMembers(id);
  const isOrgAdmin = useVerifyOrgRole(id, 0);

  const columns = [{ title: 'Name', name: 'name' }];

  const handleEditClick = (organization) => {
    dispatch(
      openDialog({
        mode: 'edit',
        type: 'portfolio_membership',
        id: organization.org_id,
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      openDialog({
        type: 'portfolio_membership',
        mode: 'create',
      })
    );
  };

  return (
    <Card raised>
      <CardContent sx={{ p: 1, pt: 0, pb: '4px !important' }}>
        <BaseTable
          rows={portfolioMembers}
          columns={columns}
          editColumn={
            <TableEditColumn
              width={42}
              showEditCommand
              cellComponent={(props) => (
                <EditCell
                  disabled={!isOrgAdmin}
                  tooltipText='Delete Relation'
                  // eslint-disable-next-line
                  handleClick={() => handleEditClick(props.row)}
                  icon={['fal', 'trash']}
                  {...props}
                />
              )}
              headerCellComponent={(props) => {
                return (
                  <HeaderEditCell
                    disabled={!isOrgAdmin}
                    tooltipText='Create Portfolio Membership'
                    handleClick={handleCreateClick}
                    {...props}
                  />
                );
              }}
            />
          }>
          <EditingState />
          <FilteringState />
          <SortingState
            defaultSorting={[{ columnName: 'name', direction: 'asc' }]}
          />
          <PagingState defaultCurrentPage={0} pageSize={10} />

          <IntegratedFiltering />
          <IntegratedSorting />
          <IntegratedPaging />
        </BaseTable>
      </CardContent>
    </Card>
  );
}

export default OrgPortfolioRelationsTable;
