import React from 'react';

import useTheme from '@mui/material/styles/useTheme';
import Chip from '@mui/material/Chip';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

const STATUSES = {
  Cancelled: 'grey',
  InProgress: 'blue',
  Stopped: 'orange',
  Stopping: 'orange',
  Succeeded: 'green',
  Superseded: 'grey',
  Failed: 'red',
};

export const PipelineStatusTypeProvider = (props) => {
  const theme = useTheme();
  return (
    <DataTypeProvider
      formatterComponent={({ value }) => {
        return (
          <Chip
            color='success'
            label={value}
            size='small'
            sx={{
              backgroundColor: theme.palette.veregy_colors[STATUSES[value]],
              color: 'white',
            }}
          />
        );
      }}
      {...props}
    />
  );
};
