import React, { useEffect, useMemo, useState } from 'react';
import { useMeasure } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import map from 'lodash/map';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MuiGrid from '@mui/material/Grid';

import {
  SortingState,
  IntegratedSorting,
  IntegratedFiltering,
  IntegratedPaging,
  EditingState,
  FilteringState,
  PagingState,
  RowDetailState,
} from '@devexpress/dx-react-grid';

import {
  Grid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableEditColumn,
  TableFilterRow,
  TableHeaderRow,
  TableRowDetail,
} from '@devexpress/dx-react-grid-material-ui';

import useVerifyOrgRole from '../../../store/hooks/useVerifyOrgRole';
import { openDialog } from '../../../store/dialogs';
import { getGenerators } from '../../../store/generators';
import { DateTimeTypeProvider } from '../providers';
import { EditCell, HeaderCell, TableCell } from '../cells';
import Header from '../Header';
import GeneratorMetersTable from './GeneratorMetersTable';

const RowDetail = ({ row }) => {
  return <GeneratorMetersTable generator={row} />;
};

function GeneratorsTable({ orgId }) {
  const dispatch = useDispatch();
  const [tableRef, { width }] = useMeasure();
  const isOrgAdmin = useVerifyOrgRole(orgId, 0);

  const {
    data: allGenerators,
    generatorMeters,
    orgId: storeOrgId,
  } = useSelector((state) => state.generators);

  const [tableData, setTableData] = useState([]);
  const [columnWidths, setColumnWidths] = useState([]);
  const columns = useMemo(
    () => [
      { title: 'Generator', name: 'name', width: 0.3 },
      {
        title: 'Last Month of Generation',
        name: 'last_month_of_generation',
        width: 0.3,
      },
      { title: 'Last Update', name: 'last_update', width: 0.3 },
    ],
    []
  );

  useEffect(() => {
    const availableWidith = width;
    if (width) {
      setColumnWidths(
        map(columns, (column) => {
          return {
            columnName: column.name,
            width: availableWidith * column.width,
          };
        })
      );
    }
  }, [width, columns]);

  useEffect(() => {
    if (storeOrgId !== orgId || storeOrgId === '') {
      dispatch(getGenerators(orgId));
    }
  }, [dispatch, orgId, storeOrgId]);

  useEffect(() => {
    if (allGenerators) {
      setTableData(allGenerators);
    }
  }, [allGenerators, generatorMeters]);

  const handleViewClick = (generator) => {
    dispatch(
      openDialog({
        mode: 'view',
        type: 'generator',
        id: generator.generator_id,
      })
    );
  };

  return (
    <MuiGrid item xs={12}>
      <Card raised>
        <CardContent sx={{ p: 1, pt: 0, pb: '4px !important' }}>
          <div ref={tableRef}>
            <Grid rows={tableData} columns={columns}>
              <DateTimeTypeProvider for={['last_update']} />
              <EditingState />
              <FilteringState />
              <SortingState
                defaultSorting={[{ columnName: 'name', direction: 'asc' }]}
              />
              <PagingState defaultCurrentPage={0} pageSize={10} />
              <RowDetailState />

              <IntegratedFiltering />
              <IntegratedSorting />
              <IntegratedPaging />
              <Table cellComponent={TableCell} />
              <TableColumnResizing
                columnWidths={columnWidths}
                onColumnWidthsChange={setColumnWidths}
                resizingMode='nextColumn'
              />

              <TableHeaderRow
                showSortingControls
                contentComponent={Header}
                cellComponent={HeaderCell}
              />
              <TableRowDetail contentComponent={RowDetail} />
              <TableEditColumn
                width={42}
                showEditCommand
                cellComponent={(props) => (
                  <EditCell
                    disabled={!isOrgAdmin}
                    tooltipText='View Generator'
                    // eslint-disable-next-line
                    handleClick={() => handleViewClick(props.row)}
                    icon={['fal', 'eye']}
                    {...props}
                  />
                )}
              />

              {tableData.length > 10 && <TableFilterRow />}
              {tableData.length > 10 && <PagingPanel />}
            </Grid>
          </div>
        </CardContent>
      </Card>
    </MuiGrid>
  );
}

GeneratorsTable.propTypes = {
  sites: PropTypes.array,
  orgId: PropTypes.string,
};

export default GeneratorsTable;
