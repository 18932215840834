import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import map from 'lodash/map';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import useSite from '../../../../../store/hooks/useSite';
import useSiteDevices from '../../../../../store/hooks/useSiteDevices';
import useVerifyOrgRole from '../../../../../store/hooks/useVerifyOrgRole';
import { openDialog } from '../../../../../store/dialogs';
import LoggerCard from './LoggerCard';
import ActionButton from './ActionButton';

function LoggersGrid() {
  const dispatch = useDispatch();

  const { id } = useSelector((state) => state.pages.site);
  const site = useSite(id);
  const { loggers, meters, inverters, sensors, loading } = useSiteDevices(id);

  const isOrgAdmin = useVerifyOrgRole(site?.org_id, 0);

  const handleCreate = () => {
    dispatch(
      openDialog({
        type: 'logger',
        mode: 'create',
        siteId: id,
      })
    );
  };

  return (
    <>
      {loggers.length ? (
        map(loggers, (logger) => {
          return (
            <LoggerCard
              key={logger.logger_id}
              site={site}
              logger={logger}
              meters={meters}
              inverters={inverters}
              sensors={sensors}
            />
          );
        })
      ) : loading ? null : (
        <Button onClick={handleCreate}>Create Data Logger</Button>
      )}
      {loggers.length ? (
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            ml: 1,
            mt: 1,
          }}>
          <ActionButton
            disabled={!isOrgAdmin}
            onClick={handleCreate}
            tooltipTitle='Create Data Logger'
            icon='plus-circle'
          />
        </Grid>
      ) : null}
    </>
  );
}

export default LoggersGrid;
