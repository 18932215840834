import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import concat from 'lodash/concat';
import find from 'lodash/find';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';

import useTheme from '@mui/material/styles/useTheme';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export const TIMEZONES = [
  { name: 'UTC', zone: 'UTC', offset: 0 },
  { name: 'MST', offset: -7, zone: 'America/Phoenix' },
];

function SelectTimezone(props) {
  const { selectedTimezone, setSelectedTimezone } = props;
  const theme = useTheme();

  const [timezone, setTimezone] = useState({ name: '' });
  const [timezones, setTimezones] = useState(TIMEZONES);

  useEffect(() => {
    setTimezone(selectedTimezone);
    if (!find(timezones, { name: selectedTimezone.name })) {
      setTimezones(
        reverse(sortBy(concat(timezones, selectedTimezone), 'offset'))
      );
    }

    /* eslint-disable-next-line */
  }, [selectedTimezone]);

  const handleSelect = (e) => {
    e.preventDefault();
    let _timezone = find(timezones, { name: e.target.value });
    setSelectedTimezone(_timezone);
  };

  return (
    <Select
      disableUnderline
      id='timezone-select'
      value={timezone.name}
      variant='standard'
      onChange={handleSelect}
      sx={{ width: '3rem', textAlign: 'center' }}
      IconComponent={() => null}
      inputProps={{
        sx: {
          padding: '0 !important',
          color: theme.palette.primary.main,
          ...theme.typography.body2,
        },
      }}
      MenuProps={{ MenuListProps: { disablePadding: true } }}>
      {timezones.map((timezone) => {
        return (
          <MenuItem
            key={timezone.zone}
            value={timezone.name}
            sx={{
              color: theme.palette.primary.main,
              ...theme.typography.body2,
            }}>
            {timezone.name}
          </MenuItem>
        );
      })}
    </Select>
  );
}

SelectTimezone.propTypes = {
  selectedTimezone: PropTypes.object,
  setSelectedTimezone: PropTypes.func,
};

export default SelectTimezone;
