import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import each from 'lodash/each';
import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { default as _range } from 'lodash/range';
import reduce from 'lodash/reduce';
import remove from 'lodash/remove';
import sortBy from 'lodash/sortBy';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { yesterday } from '../../../../helpers/dates';
import { adjustTimeseriesUnits } from '../../../../helpers/chart-data';
import { getProductionTimeseriesAPI } from '../../../../api';

import ProductionProfileChart from '../../../../components/charts/analysis/ProductionProfileChart';
import SelectMeters from '../../../../components/charts/selectors/SelectMeters';
import SelectInverters from '../../../../components/charts/selectors/SelectInverters';
import SelectRange from '../../../../components/charts/selectors/SelectRange';
import SelectTimezone, {
  TIMEZONES,
} from '../../../../components/charts/selectors/SelectTimezone';
import SelectInterval, {
  INTERVALS,
} from '../../../../components/charts/selectors/SelectInterval';
import SelectUnit, {
  UNITS,
} from '../../../../components/charts/selectors/SelectUnits';
import GenerateCSVButton from '../../../../components/charts/buttons/GenerateCSVButton';
import { generateCsvString } from '../../../../helpers/csv-data';
import { displaykW } from '../../../../helpers/display-energy';

const sumByType = (chartData, type, unit) =>
  reduce(
    chartData,
    (acc, point) =>
      acc +
      reduce(
        point,
        (acc, val, key) => {
          if (key.startsWith(type)) {
            return acc + val * (unit.endsWith('h') ? 1 : 0.25);
          }
          return acc;
        },
        0
      ),
    0
  );

const calculateTotals = (chartData, unit) => {
  return {
    meter: sumByType(chartData, 'meter', unit),
    inverter: sumByType(chartData, 'inverter', unit),
  };
};

const prepareCsvData = (data, meters, inverters, unit) => {
  let headers = ['timestamp'];
  let chartData = cloneDeep(data);

  let timeseries = map(chartData, (dataPoint) => {
    let values = reduce(
      Object.entries(dataPoint),
      (acc, entry) => {
        const deviceId = entry[0];
        const value = entry[1];
        if (deviceId !== 'timestamp') {
          let column;
          if (deviceId.startsWith('meter:')) {
            let meter = find(meters, { meter_id: deviceId });
            column = `${get(meter, 'name')}-${unit}-production`;
          } else if (deviceId.startsWith('inverter:')) {
            let inverter = find(inverters, { inverter_id: deviceId });
            column = `inverter-${get(
              inverter,
              'parent_index'
            )}-active_power_${unit}`;
          }
          if (!headers.includes(column)) {
            headers.push(column);
          }
          return { ...acc, [column]: value };
        }
        return acc;
      },
      {}
    );

    return {
      ...values,
      timestamp: dayjs(dataPoint.timestamp * 1000).format(),
    };
  });

  remove(timeseries, (datapoint) => {
    return Object.keys(datapoint).length <= 1;
  });

  return generateCsvString(headers, timeseries);
};

const compileChartData = (raw, range, unit, interval) => {
  let intervalSec = interval.minutes * 60;
  let rawData = cloneDeep(raw);
  let timeseries = _range(
    range.start / 1000,
    range.end / 1000 + 1,
    intervalSec
  );

  timeseries = map(timeseries, (timestamp) => {
    let pointData = { timestamp };
    each(rawData, (deviceData) => {
      let deviceDataPoints = remove(
        get(deviceData, 'records', []),
        (record) => {
          if (
            timestamp <= record.timestamp &&
            record.timestamp < timestamp + intervalSec
          ) {
            return record;
          }
        }
      );

      if (!isEmpty(deviceDataPoints)) {
        pointData[get(deviceData, 'device_id')] = reduce(
          deviceDataPoints,
          (acc, dataPoint) => {
            return acc + get(dataPoint, 'value', 0);
          },
          0
        );
      }
    });
    return pointData;
  });
  return adjustTimeseriesUnits(timeseries, unit);
};

function ProductionProfileChartContainer(props) {
  const { defaultTimezone, meters, inverters } = props;

  const [loading, setLoading] = useState(false);
  const [rawData, setRawData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [totals, setTotals] = useState({ meter: 0, inverter: 0 });
  const [selectedMeters, setSelectedMeters] = useState([]);
  const [selectedInverters, setSelectedInverters] = useState([]);
  const [range, setRange] = useState({ key: '', name: '' });
  const [timezone, setTimezone] = useState(TIMEZONES[0]);
  const [unit, setUnit] = useState(UNITS[1]);
  const [interval, setInterval] = useState(INTERVALS[0]);

  useEffect(() => {
    if (meters.length <= 5) {
      setSelectedMeters(meters);
    } else {
      const _meters = sortBy(meters, 'name');
      setSelectedMeters(_meters.slice(0, 5));
    }
  }, [meters]);

  // set timezone
  useEffect(() => {
    if (!isEmpty(defaultTimezone)) {
      setTimezone(defaultTimezone);
    }
  }, [defaultTimezone]);

  // set range
  useEffect(() => {
    if (!isEmpty(defaultTimezone)) {
      setRange(yesterday(defaultTimezone.offset));
    }
  }, [defaultTimezone]);

  useEffect(() => {
    if (!loading && !isEmpty(rawData)) {
      setChartData(compileChartData(rawData, range, unit, interval));
    }
  }, [rawData, range, unit, interval, loading]);

  // set default chart data
  useEffect(() => {
    if (isEmpty(chartData)) {
      const start = range.start / 1000;
      const end = range.end / 1000;

      let _data = map(selectedMeters, (meter) => {
        return {
          device_id: meter.meter_id,
          records: filter(meter?.timeseries, (record) => {
            return record.timestamp >= start && record.timestamp <= end;
          }),
        };
      });
      if (!isEmpty(_data)) {
        setRawData(_data);
      }
    }

    /* eslint-disable-next-line */
  }, [selectedMeters]);

  useEffect(() => {
    if (!isEmpty(chartData)) {
      setTotals(calculateTotals(chartData, unit));
    }
  }, [chartData, unit]);

  const handleIntervalChange = (value) => {
    if (value.minutes === 15) {
      setUnit(UNITS[1]);
    } else {
      setUnit(UNITS[4]);
    }
    setInterval(value);
  };

  const fetchChartData = (range) => {
    if (selectedMeters.length === 0) {
      toastr.warning('Select at least one meter');
      setChartData([]);
      return;
    }

    setLoading(true);
    Promise.all(
      map(concat(selectedMeters, selectedInverters), async (device) => {
        const deviceId = get(device, `${device.type_}_id`);
        return {
          device_id: deviceId,
          records: await getProductionTimeseriesAPI(
            deviceId,
            range.start,
            range.end
          ),
        };
      })
    )
      .then((payloads) => {
        setRawData(payloads);
      })
      .catch((err) => {
        console.log('err: ', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRangeSelect = (newRange) => {
    setRange(newRange);
    fetchChartData(newRange);
  };

  return (
    <>
      <Grid item xs={12}>
        <Card raised>
          <CardHeader
            sx={{
              '&.MuiCardHeader-root': { padding: 0 },
            }}
            title={
              <Stack
                direction='row'
                justifyContent='space-evenly'
                alignItems='center'
                divider={
                  <Divider
                    orientation='vertical'
                    flexItem
                    sx={{ mt: 1, mb: 1 }}
                  />
                }>
                <SelectMeters
                  allMeters={meters}
                  selectedMeters={selectedMeters}
                  setSelectedMeters={setSelectedMeters}
                />
                <SelectInverters
                  allInverters={inverters}
                  selectedInverters={selectedInverters}
                  setSelectedInverters={setSelectedInverters}
                />

                <SelectRange
                  range={range}
                  handleRangeSelect={handleRangeSelect}
                  timezone={timezone}
                />

                <SelectTimezone
                  selectedTimezone={timezone}
                  setSelectedTimezone={setTimezone}
                />

                <SelectInterval
                  selectedInterval={interval}
                  setSelectedInterval={handleIntervalChange}
                />

                <SelectUnit
                  selectedUnit={unit}
                  setSelectedUnit={setUnit}
                  units={UNITS}
                />

                <Button
                  color='primary'
                  variant='contained'
                  size='small'
                  onClick={() => fetchChartData(range)}>
                  Generate
                </Button>

                <GenerateCSVButton
                  generateCsvString={() =>
                    prepareCsvData(
                      chartData,
                      selectedMeters,
                      selectedInverters,
                      unit
                    )
                  }
                  filename={`Production Profile (${dayjs().format(
                    'ddd MMM DD YYYY'
                  )})`}
                />
              </Stack>
            }
          />
        </Card>
      </Grid>

      {isEmpty(selectedMeters) && isEmpty(selectedInverters) ? (
        <Grid item xs={12}>
          <Card raised>
            <CardContent sx={{ height: 450 }}>
              <Typography>Select meters and/or inverters</Typography>
            </CardContent>
          </Card>
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <Card raised>
              <CardContent sx={{ p: 1, pb: '8px !important' }}>
                <ProductionProfileChart
                  chartData={chartData}
                  meters={selectedMeters}
                  inverters={selectedInverters}
                  range={range}
                  timezone={timezone}
                  unit={unit}
                  loading={loading}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card raised>
              <CardContent sx={{ p: '12px', pb: '12px !important' }}>
                <Stack direction='row'>
                  {selectedMeters.length > 0 && (
                    <Typography sx={{ mr: 2 }} color='text.secondary'>
                      Meter Production:
                    </Typography>
                  )}
                  {selectedMeters.length > 0 && (
                    <Typography sx={{ mr: 15 }}>
                      {displaykW(totals.meter, true)}
                    </Typography>
                  )}
                  {selectedInverters.length > 0 && (
                    <Typography sx={{ mr: 2 }} color='text.secondary'>
                      Inverter Production:
                    </Typography>
                  )}
                  {selectedInverters.length > 0 && (
                    <Typography>{displaykW(totals.inverter, true)}</Typography>
                  )}
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </>
      )}
    </>
  );
}

ProductionProfileChartContainer.propTypes = {
  defaultTimezone: PropTypes.object,
  meters: PropTypes.array,
  inverters: PropTypes.array,
};

export default ProductionProfileChartContainer;
