import axios from '../xhr_libs/axios';
import { processResponse } from '.';

export const getSitesAPI = async () => {
  return processResponse(await axios.get('/resource/sites'));
};

export const postSiteAPI = async (body) => {
  return processResponse(await axios.post(`/resource/sites`, body));
};

export const putSiteAPI = async (site) => {
  return processResponse(
    await axios.put(`/resource/sites/${site.site_id}`, site)
  );
};

export const deleteSiteAPI = async (site_id) => {
  return processResponse(await axios.delete(`/resource/sites/${site_id}`));
};
