import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import MenuItem from '@mui/material/MenuItem';

import { TextFieldListItem } from '../../List/TextFieldListItem';

function SelectSensor(props) {
  const { sensorId, sensors, handleSelect } = props;
  const meters = useSelector((state) => state.meters.data);

  return (
    <TextFieldListItem
      label='Sensor'
      id='sensor_id'
      select
      value={sensors.length ? sensorId : ''}
      fullWidth
      onChange={handleSelect}>
      <MenuItem key={'select-option-none'} value={''}>
        None
      </MenuItem>
      {map(sortBy(sensors, 'name'), (sensor) => {
        let meter = find(meters, { meter_id: sensor.meter_id });
        return (
          <MenuItem key={sensor.sensor_id} value={sensor.sensor_id}>
            {meter.name} - {sensor.name}
          </MenuItem>
        );
      })}
    </TextFieldListItem>
  );
}

SelectSensor.propTypes = {
  sensorId: PropTypes.string,
  sensors: PropTypes.array,
  handleSelect: PropTypes.func,
};

export default SelectSensor;
