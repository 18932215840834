import axios from '../xhr_libs/axios';
import { processResponse } from '.';

export const refreshInverterAPI = async (orgId, inverterId) => {
  return processResponse(
    await axios.get(`/resource/refresh_inverter/${orgId}/${inverterId}`)
  );
};

export const getInvertersAPI = async () => {
  return processResponse(await axios.get('/resource/inverters'));
};

export const postInverterAPI = async (body) => {
  return processResponse(await axios.post(`/resource/inverters`, body));
};

export const putInverterAPI = async (body) => {
  return processResponse(
    await axios.put(`/resource/inverters/${body.inverter_id}`, body)
  );
};

export const deleteInverterAPI = async (inverterId) => {
  return processResponse(
    await axios.delete(`/resource/inverters/${inverterId}`)
  );
};
