import axios from '../xhr_libs/axios';
import { processResponse } from '.';

export const getTriggerDevicesAPI = async (id) => {
  return processResponse(await axios.get(`/resource/trigger_devices/${id}`));
};

export const postTriggerDeviceAPI = async ({ orgId, deviceId, triggerId }) => {
  return processResponse(
    await axios.post(`/resource/trigger_devices/${orgId}`, {
      triggerId,
      deviceId,
    })
  );
};

export const deleteTriggerDeviceAPI = async (triggerDeviceId) => {
  return processResponse(
    await axios.delete(`/resource/trigger_devices/${triggerDeviceId}`)
  );
};
