import React from 'react';
import TextField from '@mui/material/TextField';

export default function DisplayTextField(props) {
  return (
    <TextField
      variant='standard'
      InputProps={{ readOnly: true, disableUnderline: true }}
      {...props}
    />
  );
}
