import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import DialogTitleOptions from '../../Dialogs/DialogTitleOptions';
import Tooltip from '@mui/material/Tooltip';

function SelectNumber(props) {
  const { value, setValue, label } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);

  const [tempValue, setTempValue] = useState('');

  useEffect(() => {
    setTempValue(value);
  }, [value]);

  const handleChange = (e) => {
    e.preventDefault();
    setTempValue(e.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setValue(Number(tempValue));
    handleClose();
  };

  return (
    <>
      <Tooltip title={label}>
        <Button
          color='primary'
          onClick={() => setOpen(true)}
          sx={{ width: '4rem' }}>
          {value}
        </Button>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} fullScreen={fullScreen}>
        <form onSubmit={handleSubmit}>
          <DialogTitle sx={{ p: 1, pl: 2, pb: 0 }}>
            {label}
            <DialogTitleOptions handleClose={handleClose} />
          </DialogTitle>
          <DialogContent sx={{ width: '18rem', pb: 0 }}>
            <TextField
              fullWidth
              type='text'
              variant='standard'
              inputProps={{
                inputMode: 'numeric',
                // pattern: '/^-?d+(?:.d+)?$/g',
              }}
              value={tempValue}
              onChange={handleChange}
            />
          </DialogContent>
          <DialogActions sx={{ p: '4px' }}>
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant='contained' onClick={handleSubmit}>
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

SelectNumber.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setValue: PropTypes.func,
};

export default SelectNumber;
