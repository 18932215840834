import axios from '../xhr_libs/axios';
import { processResponse } from '.';

export const getReportFilesAPI = async (resourceId, period) => {
  return processResponse(
    await axios.get(`/resource/report_files/${resourceId}`, {
      params: {
        period,
      },
    })
  );
};

export const getReportFileAPI = async (resourceId, period, filename) => {
  return processResponse(
    await axios.get(`/resource/report_files/${resourceId}`, {
      params: {
        period,
        filename,
      },
    })
  );
};

export const generateReportFileAPI = async (body) => {
  return processResponse(await axios.post(`/resource/report_files`, body));
};

export const deleteReportFileAPI = async (resourceId, period, filename) => {
  return processResponse(
    await axios.delete(`/resource/report_files/${resourceId}`, {
      params: {
        period,
        filename,
      },
    })
  );
};
