import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router';
import PropTypes from 'prop-types';
import styled from '@mui/material/styles/styled';

import useAlarms from '../../../store/hooks/useAlarms';
import NoOrgMessage from './NoOrgsMessage';

const Main = styled('main')(() => ({
  flexGrow: 1,
  overflow: 'auto',
}));

function MainContent({ appBarHeight }) {
  useAlarms();

  const user = useSelector((state) => state.user.item);
  const loaderOpen = useSelector((state) => state.pages.loaderOpen);
  const { loading: orgLoading, data: organizations } = useSelector(
    (state) => state.organizations
  );
  const { loading: siteLoading } = useSelector((state) => state.sites);
  const { loading: meterLoading } = useSelector((state) => state.meters);

  const content = () => {
    if (
      user?.user_id &&
      !loaderOpen &&
      !siteLoading &&
      !meterLoading &&
      !orgLoading &&
      organizations.length === 0
    ) {
      return <NoOrgMessage />;
    } else {
      return <Outlet />;
    }
  };

  return <Main style={{ marginTop: appBarHeight }}>{content()}</Main>;
}

MainContent.propTypes = {
  appBarHeight: PropTypes.number.isRequired,
};

export default MainContent;
