import axios from '../xhr_libs/axios';
import { processResponse } from '.';

export const getAlarmTriggersAPI = async () => {
  return processResponse(await axios.get(`/resource/alarm_triggers`));
};

export const getAlarmsAPI = async (status) => {
  return processResponse(
    await axios.get(`/resource/alarms`, {
      params: { status },
    })
  );
};

export const acknowledgeAlarmAPI = async (alarmId) => {
  return processResponse(
    await axios.put(`/resource/alarms/${alarmId}`, {
      action: 'ack',
    })
  );
};

export const closeAlarmAPI = async (alarmId) => {
  return processResponse(
    await axios.put(`/resource/alarms/${alarmId}`, {
      action: 'close',
    })
  );
};

export const deleteAlarmAPI = async (alarmId) => {
  return processResponse(await axios.delete(`/resource/alarms/${alarmId}`));
};
