import axios from '../xhr_libs/axios';
import { processResponse } from '.';

export const getOrganizationsAPI = async () => {
  return processResponse(await axios.get(`/resource/organizations`));
};

export const postOrganizationAPI = async (body) => {
  return processResponse(await axios.post(`/resource/organizations`, body));
};

export const putOrganizationAPI = async (id, body) => {
  return processResponse(
    await axios.put(`/resource/organizations/${id}`, body)
  );
};

export const deleteOrganizationAPI = async (id) => {
  return processResponse(await axios.delete(`/resource/organizations/${id}`));
};
