import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import DisplayTextField from './DisplayTextField';

function DisplayKioskCode(props) {
  const { type, kioskCode = '' } = props;

  return (
    <DisplayTextField
      id='kiosk-url'
      label='Kiosk URL'
      sx={{ width: '100%' }}
      value={process.env.REACT_APP_WEB_URL + 'kiosk?' + type + '=' + kioskCode}
      InputProps={{
        readOnly: true,
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position='start'>
            <IconButton
              className='copy-btn'
              onClick={() => {}}
              data-clipboard-target={`#kiosk-url`}>
              <FontAwesomeIcon icon={['fal', 'copy']} size='sm' />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

DisplayKioskCode.propTypes = {
  type: PropTypes.string.isRequired,
  kioskCode: PropTypes.string,
};

export default DisplayKioskCode;
