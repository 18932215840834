import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTheme from '@mui/material/styles/useTheme';
import Badge from '@mui/material/Badge';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { navigate } from '../../../../store/pages';
import { openDialog } from '../../../../store/dialogs';
import useSite from '../../../../store/hooks/useSite';
import useSiteDevices from '../../../../store/hooks/useSiteDevices';
import SiteProfileChart from '../../../../components/charts/preview/SiteProfileChart';
import SitePerformance from './SitePerformance';

import CustomLink from '../../../../components/CustomLink';
import { MaxWidthTooltip } from '../../../../components/MaxWidthTooltip';

function SiteCard(props) {
  const { siteId, expectationType } = props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const site = useSite(siteId);
  const { alarms, loggers } = useSiteDevices(siteId);

  const active = (() => {
    let active = true;
    loggers.forEach((logger) => {
      if (!logger.active) active = false;
    });
    return active;
  })();

  const handleSiteClick = () => {
    dispatch(
      navigate({
        page: 'site',
        id: site.site_id,
      })
    );
  };

  const handleIconClick = () => {
    dispatch(
      openDialog({
        type: 'site',
        mode: 'edit',
        id: site.site_id,
      })
    );
  };

  const handleClick = (view) => {
    dispatch(
      navigate({
        page: 'site',
        id: siteId,
        tab: 'analysis',
        view: view,
      })
    );
  };

  const renderSiteLink = () => {
    const activeAlarms = filter(alarms, { status: true });
    const renderLink = () => {
      return (
        <Badge
          badgeContent={alarms.length ? alarms.length : null}
          color='error'
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
          <Link
            underline='hover'
            variant='h6'
            mb={1}
            ml={2}
            align='center'
            onClick={handleSiteClick}>
            {site.name}
          </Link>
        </Badge>
      );
    };

    if (activeAlarms.length > 0 && active) {
      return (
        <MaxWidthTooltip
          placement='right'
          title={
            <List sx={{ p: 0 }}>
              {activeAlarms.map((alarm) => (
                <ListItem key={alarm.alarm_id} disablePadding disableGutters>
                  <ListItemText
                    primaryTypographyProps={{ variant: 'caption' }}
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: '1',
                      WebkitBoxOrient: 'vertical',
                      maxWidth: 250,
                    }}>
                    {alarm.message}
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          }>
          {renderLink()}
        </MaxWidthTooltip>
      );
    }
    return renderLink();
  };

  return (
    <Card raised>
      <CardHeader
        action={
          <IconButton onClick={() => handleIconClick(site)}>
            <FontAwesomeIcon icon={['fal', 'cog']} size='sm' />
          </IconButton>
        }
        disableTypography
        title={
          <Stack
            sx={{ width: '100%' }}
            spacing={2}
            direction='row'
            justifyContent='flex-start'
            alignItems='center'>
            {renderSiteLink()}
          </Stack>
        }
        sx={{ textAlign: 'left', pl: 0, pt: 1, pb: 0 }}
      />
      {active ? (
        <CardContent sx={{ p: 0, pt: 0, pb: '4px !important' }}>
          <Grid container padding={0}>
            <Grid item xs={8}>
              <SiteProfileChart siteId={site.site_id} />
            </Grid>
            <Grid item xs={4}>
              <CustomLink handleClick={() => handleClick('performance')}>
                {(expectationType === 'modeled'
                  ? 'Modeled'
                  : 'Weather Corrected') + ' Performance'}
              </CustomLink>
              <SitePerformance
                siteId={site.site_id}
                expectationType={expectationType}
              />
            </Grid>
          </Grid>
        </CardContent>
      ) : (
        <CardContent
          sx={{
            p: 1,
            pb: '4px !important',
            minHeight: 240,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Typography color={theme.palette.veregy_colors.grey}>
            {site.name}'s meter is inactive
          </Typography>
        </CardContent>
      )}
    </Card>
  );
}

SiteCard.propTypes = {
  siteId: PropTypes.string.isRequired,
  expectationType: PropTypes.string.isRequired,
};

export default SiteCard;
