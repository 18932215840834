import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import each from 'lodash/each';
import find from 'lodash/find';
import get from 'lodash/get';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { default as _range } from 'lodash/range';
import remove from 'lodash/remove';
import set from 'lodash/set';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { yesterday } from '../../../../helpers/dates';
import { getProductionTimeseriesAPI } from '../../../../api';

import { generateCsvString } from '../../../../helpers/csv-data';
import SelectMeters from '../../selectors/SelectMeters';
import SelectSensors from '../../selectors/SelectSensors';
import SelectRange from '../../selectors/SelectRange';
import SelectTimezone, { TIMEZONES } from '../../selectors/SelectTimezone';
import GenerateCSVButton from '../../buttons/GenerateCSVButton';
import WeatherStationChart from '.';
import WeatherStationChartTotals from './Totals';

const prepareCsvData = (data, range, meters, sensors) => {
  let headers = ['timestamp'];
  let rawData = cloneDeep(data);
  let start = range.start / 1000;
  let end = range.end / 1000;

  let timeseries = map(_range(start, end, 900), (timestamp) => {
    let values = {};
    each(rawData, (resource) => {
      let deviceId = get(resource, 'device_id');
      let records = get(resource, 'records');

      if (deviceId?.startsWith('meter:')) {
        let meter = find(meters, { meter_id: deviceId });
        let column = `${get(meter, 'name')}-kw-production`;
        let deviceDataPoints = remove(records, (record) => {
          if (record.timestamp < timestamp + 900) {
            return record;
          }
        });
        if (!isEmpty(deviceDataPoints)) {
          set(values, column, get(deviceDataPoints, '0.value', 0));
          if (!headers.includes(column)) {
            headers.push(column);
          }
        }
      } else if (deviceId?.startsWith('sensor:')) {
        let sensor = find(sensors, { sensor_id: deviceId });
        let meter = find(meters, { meter_id: get(sensor, 'meter_id') });

        let radColumn = `${get(meter, 'name')}-sensor-${get(
          sensor,
          'parent_index'
        )}-radiation`;
        let tempColumn = `${get(meter, 'name')}-sensor-${get(
          sensor,
          'parent_index'
        )}-temp`;

        let deviceDataPoints = remove(records, (record) => {
          if (record.timestamp < timestamp + 900) {
            return record;
          }
        });
        if (!isEmpty(deviceDataPoints)) {
          let panelTemp = get(
            find(deviceDataPoints, (point) =>
              point.measure_name.startsWith('PanelTemp')
            ),
            'value',
            null
          );
          let radiation = get(
            find(deviceDataPoints, (point) =>
              point.measure_name.startsWith('Radiation')
            ),
            'value',
            null
          );
          set(values, radColumn, radiation);
          set(values, tempColumn, panelTemp);
          if (!headers.includes(radColumn)) {
            headers.push(radColumn);
          }
          if (!headers.includes(tempColumn)) {
            headers.push(tempColumn);
          }
        }
      }
    });
    return {
      timestamp: dayjs(timestamp * 1000).format(),
      ...values,
    };
  });
  remove(timeseries, (datapoint) => {
    return Object.keys(datapoint).length <= 1;
  });
  return generateCsvString(headers, timeseries);
};

function WeatherStationChartContainer(props) {
  const { defaultTimezone, meters, sensors } = props;

  const [loading, setLoading] = useState(false);
  const [rawData, setRawData] = useState([]);
  const [selectedMeters, setSelectedMeters] = useState([]);
  const [selectedSensors, setSelectedSensors] = useState([]);
  const [range, setRange] = useState({ key: '', name: '' });
  const [timezone, setTimezone] = useState(TIMEZONES[0]);

  // set default selected sensors
  useEffect(() => {
    const defaultSensor = head(sensors);
    setSelectedSensors([defaultSensor]);
  }, [sensors]);

  // set timezone
  useEffect(() => {
    if (!isEmpty(defaultTimezone)) {
      setTimezone(defaultTimezone);
    }
  }, [defaultTimezone]);

  // set range
  useEffect(() => {
    if (!isEmpty(defaultTimezone)) {
      setRange(yesterday(defaultTimezone.offset));
    }
  }, [defaultTimezone]);

  // set default chart data
  useEffect(() => {
    if (!isEmpty(selectedSensors) && isEmpty(rawData)) {
      fetchRawData(range);
    }
    /* eslint-disable-next-line */
  }, [selectedMeters, selectedSensors]);

  const fetchRawData = (range) => {
    setLoading(true);
    Promise.all(
      map(concat(selectedMeters, selectedSensors), async (device) => {
        const deviceId = get(device, `${device.type_}_id`);
        return {
          device_id: deviceId,
          records: await getProductionTimeseriesAPI(
            deviceId,
            range.start,
            range.end
          ),
        };
      })
    )
      .then((payloads) => {
        setRawData(payloads);
      })
      .catch((err) => {
        console.log('err: ', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRangeSelect = (newRange) => {
    setRange(newRange);
    fetchRawData(newRange);
  };

  return (
    <>
      <Grid item xs={12}>
        <Card raised>
          <CardHeader
            sx={{ '&.MuiCardHeader-root': { padding: 0 } }}
            title={
              <Stack
                direction='row'
                justifyContent='space-evenly'
                alignItems='center'
                divider={
                  <Divider
                    orientation='vertical'
                    flexItem
                    sx={{ mt: 1, mb: 1 }}
                  />
                }>
                <SelectMeters
                  allMeters={meters}
                  selectedMeters={selectedMeters}
                  setSelectedMeters={setSelectedMeters}
                />

                <SelectSensors
                  allSensors={sensors}
                  selectedSensors={selectedSensors}
                  setSelectedSensors={setSelectedSensors}
                />

                <SelectRange
                  range={range}
                  handleRangeSelect={handleRangeSelect}
                  timezone={timezone}
                />

                <SelectTimezone
                  selectedTimezone={timezone}
                  setSelectedTimezone={setTimezone}
                />

                <Button
                  color='primary'
                  variant='contained'
                  size='small'
                  onClick={() => fetchRawData(range)}>
                  Generate
                </Button>

                <GenerateCSVButton
                  generateCsvString={() =>
                    prepareCsvData(
                      rawData,
                      range,
                      selectedMeters,
                      selectedSensors
                    )
                  }
                  filename={`Weather Station (${dayjs().format(
                    'ddd MMM DD YYYY'
                  )})`}
                />
              </Stack>
            }
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card raised>
          <CardContent sx={{ p: 1, pb: '8px !important' }}>
            <WeatherStationChart
              sensors={selectedSensors}
              meters={selectedMeters}
              rawData={rawData}
              range={range}
              timezone={timezone}
              loading={loading}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <WeatherStationChartTotals
          selectedMeters={selectedMeters}
          selectedSensors={selectedSensors}
          rawData={rawData}
        />
      </Grid>
    </>
  );
}

WeatherStationChartContainer.propTypes = {
  defaultTimezone: PropTypes.object,
  meters: PropTypes.array,
  sensors: PropTypes.array,
};

export default WeatherStationChartContainer;
