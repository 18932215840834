import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useTheme from '@mui/material/styles/useTheme';
import Badge from '@mui/material/Badge';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { navigate } from '../../../../store/pages';
import { openDialog } from '../../../../store/dialogs';
import useLoggerDevices from '../../../../store/hooks/useLoggerDevices';
import { MaxWidthTooltip } from '../../../../components/MaxWidthTooltip';
import LoggerProfileChart from '../../../../components/charts/preview/LoggerProfileChart';

function LoggerCard({ logger }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { alarms } = useLoggerDevices(logger.logger_id);

  const handleLoggerClick = () => {
    dispatch(
      navigate({
        page: 'logger',
        id: logger.logger_id,
      })
    );
  };

  const handleIconClick = () => {
    dispatch(
      openDialog({
        type: 'logger',
        mode: 'edit',
        id: logger.logger_id,
      })
    );
  };

  const renderLoggerLink = () => {
    const activeAlarms = filter(alarms, { status: true });
    const renderLink = () => {
      return (
        <Badge
          badgeContent={alarms.length ? alarms.length : null}
          color='error'
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
          <Link
            underline='hover'
            variant='h6'
            mb={1}
            ml={2}
            align='center'
            onClick={handleLoggerClick}>
            {logger.name}
          </Link>
        </Badge>
      );
    };

    if (activeAlarms.length > 0 && logger.active) {
      return (
        <MaxWidthTooltip
          placement='right'
          title={
            <List disablePadding>
              {activeAlarms.map((alarm) => (
                <ListItem key={alarm.alarm_id} disablePadding disableGutters>
                  <ListItemText
                    primaryTypographyProps={{ variant: 'caption' }}
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: '1',
                      WebkitBoxOrient: 'vertical',
                      maxWidth: 250,
                    }}>
                    {alarm.message}
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          }>
          {renderLink()}
        </MaxWidthTooltip>
      );
    }
    return renderLink();
  };

  return (
    <Card raised>
      <CardHeader
        action={
          <IconButton onClick={() => handleIconClick(logger)}>
            <FontAwesomeIcon icon={['fal', 'broadcast-tower']} size='sm' />
          </IconButton>
        }
        disableTypography
        title={renderLoggerLink()}
        sx={{ textAlign: 'left', pl: 0, pt: 1, pb: 0 }}
      />
      {logger.active ? (
        <CardContent sx={{ pl: 1, pr: 1, pt: 0, pb: '4px !important' }}>
          <LoggerProfileChart logger={logger} />
        </CardContent>
      ) : (
        <CardContent
          sx={{
            minHeight: 250,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Typography color={theme.palette.veregy_colors.grey}>
            {logger.name ? `${logger.name} is inactive` : ''}
          </Typography>
        </CardContent>
      )}
    </Card>
  );
}

LoggerCard.propTypes = {
  logger: PropTypes.object.isRequired,
};

export default LoggerCard;
