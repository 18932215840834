import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import concat from 'lodash/concat';
import get from 'lodash/get';
import filter from 'lodash/filter';
import find from 'lodash/find';
import includes from 'lodash/includes';
import map from 'lodash/map';

const useTriggerDevices = (resource) => {
  const { triggers, triggerDevices } = useSelector((state) => state.alarms);
  const subscriptions = useSelector((state) => state.user.subscriptions);
  const sites = useSelector((state) => state.sites.data);
  const loggers = useSelector((state) => state.loggers.data);
  const meters = useSelector((state) => state.meters.data);
  const inverters = useSelector((state) => state.inverters.data);
  const sensors = useSelector((state) => state.sensors.data);

  const [devices, setDevices] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (resource.type_ === 'user') {
      setDevices(concat(sites, loggers, meters, inverters, sensors));
    } else if (resource.type_ === 'organization') {
      const orgSites = filter(sites, { org_id: resource?.org_id });
      const orgLoggers = filter(loggers, { org_id: resource?.org_id });
      const orgMeters = filter(meters, { org_id: resource?.org_id });
      const orgInverters = filter(inverters, { org_id: resource?.org_id });
      const orgSensors = filter(sensors, { org_id: resource?.org_id });
      setDevices(
        concat(orgSites, orgLoggers, orgMeters, orgInverters, orgSensors)
      );
    } else if (resource.type_ === 'site') {
      const siteLoggers = filter(loggers, {
        site_id: resource?.site_id,
      });
      const siteMeters = filter(meters, { site_id: resource?.site_id });
      const siteMeterIds = map(siteMeters, 'meter_id');
      const siteInverters = filter(inverters, (inverter) =>
        siteMeterIds.includes(inverter.meter_id)
      );
      const siteSensors = filter(sensors, (sensor) =>
        siteMeterIds.includes(sensor.meter_id)
      );
      setDevices(
        concat(resource, siteLoggers, siteMeters, siteInverters, siteSensors)
      );
    } else if (resource.type_ === 'logger') {
      const loggerMeters = filter(meters, {
        logger_id: resource?.logger_id,
      });
      const loggerMeterIds = map(loggerMeters, 'meter_id');
      const loggerInverters = filter(inverters, (inverter) =>
        loggerMeterIds.includes(inverter.meter_id)
      );
      const loggerSensors = filter(sensors, (sensor) =>
        loggerMeterIds.includes(sensor.meter_id)
      );
      setDevices(
        concat(resource, loggerMeters, loggerInverters, loggerSensors)
      );
    } else if (resource.type_ === 'meter') {
      const meterInverters = filter(inverters, {
        meter_id: resource?.meter_id,
      });
      const meterSensors = filter(sensors, {
        meter_id: resource?.meter_id,
      });
      setDevices(concat(resource, meterInverters, meterSensors));
    } else if (includes(['inverter', 'sensor'], resource.type_)) {
      setDevices([resource]);
    }
  }, [resource, sites, loggers, meters, inverters, sensors]);

  useEffect(() => {
    const _data = [];

    devices.forEach((device) => {
      const deviceId = get(device, `${device.type_}_id`);
      const deviceTriggers = filter(triggers, { device_type: device.type_ });
      deviceTriggers.forEach((trigger) => {
        const triggerDevice = find(triggerDevices, {
          device_id: deviceId,
          trigger_id: trigger.trigger_id,
        });
        const subscription = find(subscriptions, {
          device_id: deviceId,
          trigger_id: trigger.trigger_id,
        });
        _data.push({
          trigger_id: trigger.trigger_id,
          org_id: device.org_id,
          device_id: deviceId,
          subscription_id: subscription?.subscription_id,
          trigger_device_id: triggerDevice?.trigger_device_id,
          device_type: device.type_,
          name: device?.name,
          level: trigger.level,
        });
      });
    });
    setData(_data);
  }, [devices, triggers, triggerDevices, subscriptions]);

  return data;
};

export default useTriggerDevices;
