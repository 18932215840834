import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import has from 'lodash/has';
import toUpper from 'lodash/toUpper';

import Grid from '@mui/material/Grid';

import useLogger from '../../../../store/hooks/useLogger';
import useSite from '../../../../store/hooks/useSite';
import useLoggerDevices from '../../../../store/hooks/useLoggerDevices';
import ViewStack from '../../../../components/ViewStack';
import LoggerCard from '../../Site/Configuration/LoggersGrid/LoggerCard';
import AlarmsConfigurationTable from '../../../../components/table/tables/AlarmsConfigurationTable';

const VIEWS = {
  DEVICES: {
    name: 'devices',
    description: "Manage the data logger's meters, inverters and sensors",
  },
  ALARMS: {
    name: 'alarms',
    description: 'Manage alarm triggers for the data logger and its devices',
  },
};

export default function Configuration() {
  const [selectedView, setSelectedView] = useState(VIEWS.DEVICES.name);
  const { id, view } = useSelector((state) => state.pages.logger);
  const logger = useLogger(id);
  const site = useSite(logger.site_id);
  const { meters, inverters, sensors } = useLoggerDevices(id);

  useEffect(() => {
    if (has(VIEWS, toUpper(view))) {
      setSelectedView(view);
    } else if (view === '') {
      setSelectedView(VIEWS.DEVICES.name);
    }
  }, [view]);

  const renderView = () => {
    switch (selectedView) {
      case VIEWS.DEVICES.name:
        return (
          <LoggerCard
            site={site}
            logger={logger}
            meters={meters}
            inverters={inverters}
            sensors={sensors}
          />
        );

      case VIEWS.ALARMS.name:
        return <AlarmsConfigurationTable resource={logger} typeSelect={true} />;

      default:
        return null;
    }
  };

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={2}
      padding={2}
      sx={{ pl: 4, pr: 3 }}>
      <Grid item xs={12} sx={{ paddingTop: '8px !important' }}>
        <ViewStack
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          views={VIEWS}
        />
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: '8px !important' }}>
        {renderView()}
      </Grid>
    </Grid>
  );
}
