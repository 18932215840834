import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

function SelectOrganization(props) {
  const {
    selectedOrganization,
    setSelectedOrganization,
    showPortfolios = true,
  } = props;

  const allOrganizations = useSelector((state) => state.organizations.data);

  const handleChangeOrganization = (e) => {
    if (e.target.value === '') return setSelectedOrganization({ org_id: '' });
    setSelectedOrganization(find(allOrganizations, { org_id: e.target.value }));
  };

  const organizations = sortBy(
    filter(allOrganizations, { is_portfolio: false }),
    'name'
  );
  const portfolios = sortBy(
    filter(allOrganizations, { is_portfolio: true }),
    'name'
  );

  return (
    <Card sx={{ width: '100%', minWidth: 250 }}>
      <CardContent sx={{ p: 0, pb: '0 !important' }}>
        <FormControl variant='filled' size='small' sx={{ width: '100%' }}>
          <InputLabel id='organization-label'>Organization</InputLabel>
          <Select
            label='Organization'
            labelId='organization-label'
            disableUnderline
            id='org_id'
            value={
              selectedOrganization.org_id ? selectedOrganization.org_id : ''
            }
            onChange={handleChangeOrganization}
            sx={{
              backgroundColor: 'unset',
              '& .MuiSelect-select': {
                display: 'flex',
                alignItems: 'center',
              },
            }}
            MenuProps={{
              MenuListProps: { disablePadding: true },
            }}>
            <MenuItem id={'empty-menu-item'} value=''>
              None
            </MenuItem>
            {portfolios.length > 0 && showPortfolios && (
              <ListSubheader>Portfolios</ListSubheader>
            )}
            {showPortfolios &&
              portfolios.map((portfolio) => (
                <MenuItem
                  key={portfolio.org_id}
                  id={`${portfolio.org_id}-menu-item`}
                  value={portfolio.org_id}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={['fal', 'folder']} />
                  </ListItemIcon>
                  {portfolio.name}
                </MenuItem>
              ))}
            {organizations.length > 0 && showPortfolios && (
              <ListSubheader>Organizations</ListSubheader>
            )}
            {organizations.map((organization) => (
              <MenuItem
                key={organization.org_id}
                id={`${organization.org_id}-menu-item`}
                value={organization.org_id}>
                <ListItemIcon>
                  <FontAwesomeIcon icon={['fal', 'buildings']} />
                </ListItemIcon>
                {organization.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </CardContent>
    </Card>
  );
}

SelectOrganization.propTypes = {
  selectedOrganization: PropTypes.object.isRequired,
  setSelectedOrganization: PropTypes.func.isRequired,
  showPortfolios: PropTypes.bool,
};

export default SelectOrganization;
