import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import find from 'lodash/find';

const useVerifyOrgRole = (orgId, role) => {
  const { item: user, memberships } = useSelector((state) => state.user);
  const [verifyOrgRole, setVerifyOrgRole] = useState(false);

  useEffect(() => {
    if (user?.super_user) {
      setVerifyOrgRole(true);
    } else {
      const membership = find(memberships, {
        org_id: orgId,
        user_id: user?.user_id,
      });
      setVerifyOrgRole(membership?.role <= role);
    }
  }, [memberships, orgId, user, role]);

  return verifyOrgRole;
};

export default useVerifyOrgRole;
