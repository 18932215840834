import React from 'react';
import { useSelector } from 'react-redux';
import { useMeasure } from 'react-use';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import startCase from 'lodash/startCase';

import useTheme from '@mui/material/styles/useTheme';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import TabPanel from './TabPanel';

const DEFAULT_TAB_NAMES = ['dashboard', 'analysis', 'configuration'];

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const defaultStyles = (tabHeight) => ({
  marginTop: tabHeight,
  height: `calc(100% - ${tabHeight})`,
  transition: 'all 150ms ease-out',
});

function TabBar(props) {
  const {
    tabNames,
    handleChangeTab,
    tabIndex,
    children,
    compact = false,
  } = props;
  const theme = useTheme();
  const [tabsRef, { height: tabsHeight }] = useMeasure();
  const { titleBarStyles } = useSelector((state) => state.appSettings);

  let containerStyle = compact
    ? {
        ...defaultStyles(tabsHeight),
        maxWidth: 1000,
        marginLeft: 'auto',
        marginRight: 'auto',
      }
    : defaultStyles(tabsHeight);

  return (
    <div style={containerStyle}>
      <AppBar
        position='absolute'
        color='default'
        style={titleBarStyles}
        ref={tabsRef}>
        <Tabs
          style={{
            marginLeft: titleBarStyles.marginLeft,
          }}
          value={tabIndex}
          onChange={handleChangeTab}
          indicatorColor='primary'
          textColor='primary'
          variant='fullWidth'>
          {map(tabNames ? tabNames : DEFAULT_TAB_NAMES, (tabName) => {
            return (
              <Tab key={tabName} label={startCase(tabName)} {...a11yProps(0)} />
            );
          })}
        </Tabs>
      </AppBar>

      <>
        {map(children, (child, idx) => (
          <TabPanel
            key={`tab-panel-${idx}`}
            value={tabIndex}
            index={idx}
            dir={theme.direction}>
            {child}
          </TabPanel>
        ))}
      </>
    </div>
  );
}

TabBar.propTypes = {
  tabNames: PropTypes.array,
  handleChangeTab: PropTypes.func,
  tabIndex: PropTypes.number,
  children: PropTypes.array,
  compact: PropTypes.bool,
};

export default TabBar;
