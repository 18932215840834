import React from 'react';
import dayjs from 'dayjs';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

export const TimestampTypeProvider = (props) => {
  return (
    <DataTypeProvider
      formatterComponent={({ value }) => {
        return dayjs(value).format('MM/DD HH:mm z');
      }}
      {...props}
    />
  );
};
