import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import traverse from 'traverse';
import { nodes as initialState } from '../initialState';

import concat from 'lodash/concat';
import each from 'lodash/each';
import filter from 'lodash/filter';
import find from 'lodash/find';
import includes from 'lodash/includes';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import get from 'lodash/get';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import uniqueId from 'lodash/uniqueId';

import { buildAsyncReducers } from '../thunkTemplate';
import { navigate } from '../pages';

const effectiveStatus = (orgId, licenses) => {
  const license = find(licenses, { org_id: orgId, name: 'standard' });

  if (!license) return false;
  if (license.force_active === false) return false;
  if (license.force_active === true) return true;
  return license.active;
};

const generatePortfolioTree = (
  portfolio,
  portfolioMemberships,
  organizations,
  licenses,
  sites,
  loggers,
  meters,
  inverters,
  sensors
) => {
  const childRelations = filter(portfolioMemberships, {
    portfolio_id: portfolio.org_id,
  });
  const childOrgs = [];

  each(childRelations, (relation) => {
    let organization = find(organizations, { org_id: relation.member_id });
    if (organization) childOrgs.push(organization);
  });

  return {
    type: 'tree-node-parent',
    resourceType: 'portfolio',
    label: get(portfolio, 'name'),
    itemId: get(portfolio, 'org_id'),
    hide: false,
    icon: ['fal', 'folder'],
    children: childOrgs.map((org) =>
      generateOrgTree(org, licenses, sites, loggers, meters, inverters, sensors)
    ),
  };
};

const generateOrgTree = (
  organization,
  licenses,
  sites,
  loggers,
  meters,
  inverters,
  sensors
) => {
  const orgSites = filter(sites, { org_id: organization.org_id });
  const children = !effectiveStatus(organization.org_id, licenses)
    ? []
    : map(orgSites, (site) =>
        generateSiteTree(site, loggers, meters, inverters, sensors)
      );
  return {
    type: 'tree-node-organization',
    label: organization.name,
    resourceType: 'organization',
    itemId: organization.org_id,
    icon: ['fal', 'buildings'],
    children,
  };
};

const generateSiteTree = (site, loggers, meters, inverters, sensors) => {
  const siteLoggers = filter(loggers, { site_id: site.site_id });

  return {
    type: 'tree-node-site',
    label: site.name,
    resourceType: 'site',
    itemId: site.site_id,
    icon: ['fal', 'building'],
    children: map(siteLoggers, (logger) =>
      generateLoggerTree(logger, meters, inverters, sensors)
    ),
  };
};

const generateLoggerTree = (logger, meters, inverters, sensors) => {
  const loggerMeters = filter(meters, { logger_id: logger.logger_id });

  const meterIds = map(loggerMeters, 'meter_id');
  const loggerInverters = filter(inverters, (inverter) => {
    return includes(meterIds, inverter.meter_id);
  });
  const loggerSensors = filter(sensors, (sensor) => {
    return includes(meterIds, sensor.meter_id);
  });

  const meterTrees = map(sortBy(loggerMeters, 'parent_index'), (meter) => ({
    type: 'tree-node-meter',
    label: meter.name,
    resourceType: 'meter',
    itemId: meter.meter_id,
    icon: ['fal', 'bolt'],
    children: [],
  }));
  const inverterTrees = map(
    sortBy(loggerInverters, 'parent_index'),
    (inverter) => ({
      type: 'tree-node-inverter',
      label: inverter.name,
      resourceType: 'inverter',
      itemId: inverter.inverter_id,
      icon: ['fal', 'exchange'],
      children: [],
    })
  );
  const sensorTrees = map(sortBy(loggerSensors, 'parent_index'), (sensor) => ({
    type: 'tree-node-sensor',
    label: sensor.name,
    resourceType: 'sensor',
    itemId: sensor.sensor_id,
    icon: ['fal', 'thermometer-half'],
    children: [],
  }));

  const children = concat(meterTrees, sensorTrees, inverterTrees);
  return {
    type: 'tree-node-site',
    label: logger.name,
    resourceType: 'logger',
    itemId: logger.logger_id,
    icon: ['fal', 'broadcast-tower'],
    children: children,
  };
};

const updateNodes = createAsyncThunk(
  'nodes/update',
  (
    {
      organizations,
      portfolioMemberships,
      sites,
      licenses,
      loggers,
      meters,
      inverters,
      sensors,
      nav = false,
    },
    { getState, dispatch }
  ) => {
    const state = getState();
    let { item: user } = state.user;
    let { data: sites_ } = state.sites;
    let { data: licenses_ } = state.licenses;
    let { data: loggers_ } = state.loggers;
    let { data: meters_ } = state.meters;
    let { data: inverters_ } = state.inverters;
    let { data: sensors_ } = state.sensors;
    let { portfolioMemberships: portfolioMemberships_, data: organizations_ } =
      state.organizations;

    sites = isArray(sites) ? sites : sites_;
    licenses = isArray(licenses) ? licenses : licenses_;
    loggers = isArray(loggers) ? loggers : loggers_;
    meters = isArray(meters) ? meters : meters_;
    inverters = isArray(inverters) ? inverters : inverters_;
    sensors = isArray(sensors) ? sensors : sensors_;
    portfolioMemberships = isArray(portfolioMemberships)
      ? portfolioMemberships
      : portfolioMemberships_;
    organizations = isArray(organizations) ? organizations : organizations_;

    let nodes = [];

    const portfolios = sortBy(
      filter(organizations, { is_portfolio: true }),
      'name'
    );

    if (!isEmpty(portfolios)) {
      nodes = map(portfolios, (portfolio) =>
        generatePortfolioTree(
          portfolio,
          portfolioMemberships,
          organizations,
          licenses,
          sites,
          loggers,
          meters,
          inverters,
          sensors
        )
      );
    } else {
      nodes = map(organizations, (org) =>
        generateOrgTree(
          org,
          licenses,
          sites,
          loggers,
          meters,
          inverters,
          sensors
        )
      );
    }

    if (user.default_organization && nav) {
      const defaultOrganization = find(organizations, {
        org_id: user.default_organization,
      });
      if (isObject(defaultOrganization)) {
        if (get(defaultOrganization, 'is_portfolio', false)) {
          dispatch(
            navigate({ page: 'portfolio', id: defaultOrganization.org_id })
          );
        } else {
          dispatch(
            navigate({
              page: 'organization',
              id: defaultOrganization.org_id,
            })
          );
        }
      }
    }

    nodes = traverse(nodes).map(function (node) {
      if (this.notLeaf && !isArray(node)) {
        node.level = this.level;
        node.id = node.itemId;
        node.itemId = node.itemId + '.' + uniqueId();
      }
      if (node.children) {
        node.children = orderBy(node.children, ['type', 'label']);
      }
      return node;
    });

    return { data: nodes };
  }
);

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { reducer, actions } = createSlice({
  name: 'nodes',
  initialState,
  reducers: {
    setNodes(state, action) {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [updateNodes]);
  },
});

const { setNodes } = actions;

// Export the reducer, either as a default or named export
export { updateNodes, setNodes };
export default reducer;
