import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import filter from 'lodash/filter';
import get from 'lodash/get';
import has from 'lodash/has';
import toUpper from 'lodash/toUpper';

import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { setPage } from '../../../../store/pages';
import useOrganization from '../../../../store/hooks/useOrganization';
import usePortfolioMembers from '../../../../store/hooks/usePortfolioMembers';
import usePortfolioDevices from '../../../../store/hooks/usePortfolioDevices';
import useVerifyOrgRole from '../../../../store/hooks/useVerifyOrgRole';
import ResourceCount from '../../../../components/widgets/ResourceCount';
import CountGrid from '../../../../components/Grid/CountGrid';
import CurrentGeneration from '../../../../components/widgets/CurrentGeneration';
import ProductionIndex from '../../../../components/widgets/ProductionIndex';
import ViewStack from '../../../../components/ViewStack';
import Map from '../../../../components/Map';
import AlarmsTable from '../../../../components/table/tables/AlarmsTable';
import OrganizationsTable from '../../../../components/table/tables/OrganizationsTable';

const VIEWS = {
  ORGANIZATIONS: {
    name: 'organizations',
    description: 'Table of organizations in this portfolio',
  },
  MAP: {
    name: 'map',
    description: "Map view of the portfolio's child organizations",
  },
  ALARMS: {
    name: 'alarms',
    description: 'Table of alarms for the devices in this portfolio',
  },
};

function PortfolioDashboard() {
  const dispatch = useDispatch();
  const { id, view, expectationType } = useSelector(
    (state) => state.pages.portfolio
  );

  const portfolio = useOrganization(id);
  const { portfolioMembers, loading: membersLoading } = usePortfolioMembers(id);
  const { alarms, sites, loggers, meters, inverters, sensors, loading } =
    usePortfolioDevices(id);

  const isOrgAdmin = useVerifyOrgRole(id, 0);

  const [selectedView, setSelectedView] = useState(VIEWS.ORGANIZATIONS.name);

  useEffect(() => {
    if (has(VIEWS, toUpper(view))) {
      setSelectedView(view);
    } else if (view === '') {
      setSelectedView(VIEWS.ORGANIZATIONS.name);
    }
  }, [view]);

  const handleSetExpectationType = (type) => {
    switch (type) {
      case 'modeled':
      case 'corrected':
        dispatch(setPage({ page: 'portfolio', expectationType: type }));
        break;

      default:
        dispatch(setPage({ page: 'portfolio', expectationType: 'modeled' }));
        break;
    }
  };

  const renderAlert = () => {
    if (loading || membersLoading) return null;

    if (portfolioMembers.length === 0) {
      return (
        <Grid item xs={12}>
          <Alert
            variant='filled'
            severity='warning'
            sx={{
              '& .MuiAlert-message': {
                width: '100%',
              },
            }}>
            <Typography variant='body1'>
              {`${get(
                portfolio,
                'name',
                'This Portfolio'
              )} has no organizations configured`}
            </Typography>
            <Typography variant='body1'>
              Please contact a LightLevel admin for further assitance
            </Typography>
          </Alert>
        </Grid>
      );
    } else {
      return null;
    }
  };

  const renderView = () => {
    if (selectedView === VIEWS.ORGANIZATIONS.name) {
      return portfolioMembers.length ? (
        <OrganizationsTable
          children={portfolioMembers}
          sites={sites}
          meters={meters}
          inverters={inverters}
          alarms={alarms}
          expectationType={expectationType}
        />
      ) : null;
    } else if (selectedView === VIEWS.MAP.name) {
      return (
        <Map
          center={portfolio}
          locations={portfolioMembers}
          styles={{
            height: '58vh',
            width: '100%',
          }}
        />
      );
    } else if (selectedView === VIEWS.ALARMS.name) {
      return <AlarmsTable resource={portfolio} alarms={alarms} />;
    }
  };

  const activeAlarms = filter(alarms, { status: true });

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={2}
      padding={2}
      sx={{ pl: 4, pr: 3 }}>
      {renderAlert()}
      <Grid item xs={4}>
        <ResourceCount loading={loading}>
          <CountGrid number={portfolioMembers.length} name='Organizations' />
          <CountGrid number={sites.length} name='Sites' />
          <CountGrid number={meters.length} name='Meters' />
          <CountGrid number={activeAlarms.length} name='Alarms' />
        </ResourceCount>
      </Grid>
      <Grid item xs={4}>
        <CurrentGeneration
          loggers={loggers}
          meters={meters}
          inverters={inverters}
          loading={loading}
        />
      </Grid>
      <Grid item xs={4}>
        <ProductionIndex
          sites={sites}
          meters={meters}
          inverters={inverters}
          sensors={sensors}
          loading={loading}
          expectationType={expectationType}
          setExpectationType={handleSetExpectationType}
          disabled={!isOrgAdmin}
        />
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: '8px !important' }}>
        <ViewStack
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          views={VIEWS}
        />
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: '8px !important' }}>
        {renderView()}
      </Grid>
    </Grid>
  );
}

export default PortfolioDashboard;
