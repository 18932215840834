import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import filter from 'lodash/filter';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Badge from '@mui/material/Badge';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { openDialog } from '../../../../store/dialogs';
import { navigate } from '../../../../store/pages';
import useSensor from '../../../../store/hooks/useSensor';
import useMeter from '../../../../store/hooks/useMeter';
import useSite from '../../../../store/hooks/useSite';
import useDeviceAlarms from '../../../../store/hooks/useDeviceAlarms';
import SensorChart from '../../dashboard/SensorChart';
import { MaxWidthTooltip } from '../../../MaxWidthTooltip';

function SensorProfileChart({ sensorId }) {
  const dispatch = useDispatch();
  const sensor = useSensor(sensorId);
  const meter = useMeter(sensor.meter_id);
  const site = useSite(meter.site_id);
  const alarms = useDeviceAlarms(sensorId);

  const handleNavClick = () => {
    dispatch(
      openDialog({
        type: 'sensor',
        mode: 'edit',
        id: sensor.sensor_id,
      })
    );
  };

  const handleEditClick = () => {
    dispatch(
      navigate({
        page: 'sensor',
        id: sensor.sensor_id,
      })
    );
  };

  const renderLink = () => {
    const activeAlarms = filter(alarms, { status: true });
    const renderLink = () => {
      return (
        <Badge
          badgeContent={alarms.length ? alarms.length : null}
          color='error'
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
          <Link
            underline='hover'
            variant='h6'
            mb={1}
            ml={2}
            align='center'
            onClick={handleEditClick}>
            {sensor.name}
          </Link>
        </Badge>
      );
    };

    if (activeAlarms.length > 0) {
      return (
        <MaxWidthTooltip
          placement='right'
          title={
            <List sx={{ p: 0 }}>
              {activeAlarms.map((alarm) => (
                <ListItemText
                  key={alarm.alarm_id}
                  primaryTypographyProps={{ variant: 'caption' }}
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '1',
                    WebkitBoxOrient: 'vertical',
                  }}>
                  {alarm.message}
                </ListItemText>
              ))}
            </List>
          }>
          {renderLink()}
        </MaxWidthTooltip>
      );
    }
    return renderLink();
  };

  if (sensor) {
    return (
      <Card raised>
        <CardHeader
          disableTypography
          action={
            <IconButton onClick={() => handleNavClick()}>
              <FontAwesomeIcon icon={['fal', 'cog']} size='sm' />
            </IconButton>
          }
          title={
            <Stack
              sx={{ width: '100%' }}
              spacing={2}
              direction='row'
              justifyContent='flex-start'
              alignItems='center'>
              {renderLink()}
            </Stack>
          }
          sx={{ textAlign: 'left', pl: 0, pt: 1, pb: 0 }}
        />

        <CardContent sx={{ p: 1, pt: 0, pb: '4px !important' }}>
          <Grid container>
            <Grid container item xs={12}>
              <SensorChart site={site} sensor={sensor} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  } else {
    return null;
  }
}

SensorProfileChart.propTypes = {
  site: PropTypes.object,
  sensorId: PropTypes.string,
};

export default SensorProfileChart;
