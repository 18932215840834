import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import has from 'lodash/has';
import toUpper from 'lodash/toUpper';

import Grid from '@mui/material/Grid';

import useSite from '../../../../store/hooks/useSite';
import useOrganization from '../../../../store/hooks/useOrganization';
import ViewStack from '../../../../components/ViewStack';
import LoggersGrid from './LoggersGrid';
import Kiosk from '../../../../components/Configuration/Kiosk';
import AlarmsConfigurationTable from '../../../../components/table/tables/AlarmsConfigurationTable';
import SiteExpectationsTable from './SiteExpectationsTable';
import ReportsTable from '../../../../components/table/tables/ReportsTable';

const VIEWS = {
  DEVICES: {
    name: 'devices',
    description: "Manage the site's devices",
  },
  ALARMS: {
    name: 'alarms',
    description: 'Manage alarm triggers for the site and its devices',
  },
  EXPECTATIONS: {
    name: 'expectations',
    description:
      'Manage modeled production expectations and irradiance expectations for the site',
  },
  KIOSK: {
    name: 'kiosk',
    description: 'Configure kiosk settings for this site',
  },
};

export default function Configuration() {
  const { id, view } = useSelector((state) => state.pages.site);
  const site = useSite(id);
  const organization = useOrganization(site?.org_id);

  const [views, setViews] = useState(VIEWS);
  const [selectedView, setSelectedView] = useState(VIEWS.DEVICES.name);

  useEffect(() => {
    if (has(views, toUpper(view))) {
      setSelectedView(view);
    } else if (view === '') {
      setSelectedView(views.DEVICES.name);
    }
  }, [views, view]);

  useEffect(() => {
    if (organization?.licenses?.reporting) {
      setViews({
        ...VIEWS,
        REPORTS: { name: 'reports', description: 'Reports Table' },
      });
    }
    // eslint-disable-next-line
  }, [organization?.licenses?.reporting]);

  const renderView = () => {
    switch (selectedView) {
      case views.DEVICES.name:
        return <LoggersGrid />;

      case views.ALARMS.name:
        return <AlarmsConfigurationTable resource={site} typeSelect={true} />;

      case views.EXPECTATIONS.name:
        return <SiteExpectationsTable />;

      case views.KIOSK.name:
        return <Kiosk resourceId={site.site_id} orgId={site.org_id} />;

      case views?.REPORTS.name:
        return <ReportsTable resource={site} />;

      default:
        return null;
    }
  };

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={2}
      padding={2}
      sx={{ pl: 4, pr: 3 }}>
      <Grid item xs={12} sx={{ paddingTop: '8px !important' }}>
        <ViewStack
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          views={views}
        />
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: '8px !important' }}>
        {renderView()}
      </Grid>
    </Grid>
  );
}
