import { createSlice } from '@reduxjs/toolkit';
import { buildAsyncReducers } from '../thunkTemplate';
import { sensors as initialState } from '../initialState';

import {
  postSensor,
  getSensors,
  putSensor,
  deleteSensor,
  refreshSensors,
} from './_sensors';

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { reducer, actions } = createSlice({
  name: 'sensors',
  initialState,
  reducers: {
    setSensors: (state, { payload }) => ({
      ...state,
      data: payload,
    }),
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [
      postSensor,
      getSensors,
      putSensor,
      deleteSensor,
      refreshSensors,
    ]);
  },
});

// Extract each action creator by name
const { setSensors } = actions;

// Export the reducer, either as a default or named export
export {
  getSensors,
  postSensor,
  putSensor,
  deleteSensor,
  setSensors,
  refreshSensors,
};
export default reducer;
