import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import find from 'lodash/find';
import get from 'lodash/get';

import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

import { getImages, getPresignedUrl } from '../../../store/images/_images';
import useLicense from '../../../store/hooks/useLicense';
import DisplayKioskCode from '../../DisplayKioskCode';
import CaseStudy from './CaseStudy';
import Images from './Images';
import Slides from './Slides';
import Settings from './Settings';

function Kiosk(props) {
  const { resourceId } = props;
  const dispatch = useDispatch();

  const organizations = useSelector((state) => state.organizations.data);
  const sites = useSelector((state) => state.sites.data);

  const [resource, setResource] = useState({});
  const license = useLicense(resource?.org_id, 'kiosk');

  useEffect(() => {
    if (resourceId.startsWith('org')) {
      setResource(find(organizations, { org_id: resourceId }));
    } else if (resourceId.startsWith('site')) {
      setResource(find(sites, { site_id: resourceId }));
    }
  }, [resourceId, organizations, sites]);

  useEffect(() => {
    const fetchImages = async () => {
      const { payload: allImages } = await dispatch(getImages(resourceId));
      const resourceImages = get(allImages, resourceId);
      console.log('resourceImages: ', resourceImages);
      let _logoFile = find(resourceImages, (image) =>
        image.startsWith('logo.')
      );
      let _caseStudyFile = find(resourceImages, (image) =>
        image.startsWith('case-study.')
      );

      if (_logoFile) {
        await dispatch(getPresignedUrl({ resourceId, key: _logoFile }));
      }

      if (_caseStudyFile) {
        await dispatch(getPresignedUrl({ resourceId, key: _caseStudyFile }));
      }
    };

    if (resourceId) {
      fetchImages();
    }
  }, [resourceId, dispatch]);

  const kioskType = resourceId.split(':')[0];
  return (
    <Grid container spacing={2}>
      {!license.effectiveStatus && (
        <Grid item xs={12}>
          <Alert severity='error'>
            This Organization's Kiosk License is inactive. Contact an
            administrator for further assistance.
          </Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <Card raised>
          <CardContent sx={{ p: 1, pb: '8px !important' }}>
            <DisplayKioskCode
              type={kioskType}
              kioskCode={resource.kiosk_code}
            />
          </CardContent>
        </Card>
      </Grid>
      <Settings resource={resource} resourceId={resourceId} />
      <Grid item xs={12}>
        <Slides resource={resource} />
      </Grid>
      <Grid item md={6} xs={12}>
        <CaseStudy resourceId={resourceId} resource={resource} />
      </Grid>
      <Grid item md={6} xs={12}>
        <Images resourceId={resourceId} resource={resource} />
      </Grid>
    </Grid>
  );
}

export default Kiosk;
