import dayjs from 'dayjs';
import concat from 'lodash/concat';
import each from 'lodash/each';
import get from 'lodash/get';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import { generateCsvString } from '../../../helpers/csv-data';

export const prepareCsvData = (chartData, attributes, deviceIdxStr) => {
  let headers = concat('timestamp', attributes);

  return generateCsvString(
    headers,
    map(chartData, (datapoint) => {
      let timestamp = get(datapoint, 'timestamp');
      let values = reduce(
        Object.entries(datapoint),
        (acc, entry) => {
          let key = entry[0],
            value = entry[1];

          if (key !== 'timestamp') {
            if (deviceIdxStr) {
              key = key.replace(deviceIdxStr, '');
            }
            return { ...acc, [key]: value };
          }
          return acc;
        },
        {}
      );

      return {
        ...values,
        timestamp: dayjs(timestamp * 1000).format(),
      };
    })
  );
};

export const compileChartData = (rawData) => {
  return map(rawData, (dataPoint) => {
    let timestamp = get(dataPoint, 'timestamp');
    let values = get(dataPoint, 'values');
    let measures = {};

    each(values, (measureData) => {
      measures[get(measureData, 'measure_name')] = get(measureData, 'value');
    });

    return {
      timestamp,
      ...measures,
    };
  });
};
