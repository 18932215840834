import React from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import { dateStringToDayJS } from '../../../helpers/dates';

const Field = (props) => {
  return (
    <ListItem>
      <TextField
        sx={{ width: '100%' }}
        InputProps={{
          readOnly: true,
          disableUnderline: true,
        }}
        variant='standard'
        {...props}
      />
    </ListItem>
  );
};

const APIInfo = () => {
  const { item: user } = useSelector((state) => state.user);

  return (
    <Card raised>
      <CardContent sx={{ p: 0, pb: '0 !important' }}>
        <List disablePadding>
          <ListItem>
            <TextField
              id='api-key'
              label='API Key'
              defaultValue={user.api.key}
              sx={{ width: '100%' }}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position='start'>
                    <IconButton
                      className='copy-btn'
                      onClick={() => {}}
                      data-clipboard-target={`#api-key`}>
                      <FontAwesomeIcon icon={['fal', 'copy']} size='sm' />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant='standard'
            />
          </ListItem>
          <Field
            id='invocations'
            label='Invocations'
            defaultValue={user.api.invocations}
          />
          <Field
            id='key-generation-date'
            label='Key Generation Date'
            defaultValue={dateStringToDayJS(
              get(user, 'api.key_generation_datetime')
            ).format('MM/DD/YYYY HH:mm:ss Z')}
          />
          <Field
            id='last-invocation-date'
            label='Last Invocation Date'
            defaultValue={dateStringToDayJS(
              get(user, 'api.last_invocation_datetime')
            ).format('MM/DD/YYYY HH:mm:ss Z')}
          />
        </List>
      </CardContent>
    </Card>
  );
};

export default APIInfo;
