import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import filter from 'lodash/filter';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import useVerifyOrgRole from '../../../../../store/hooks/useVerifyOrgRole';
import { openDialog } from '../../../../../store/dialogs';

import ActionButton from './ActionButton';
import { dateStringToDayJS } from '../../../../../helpers/dates';

const cardContentStyles = { p: 1, pb: '4px !important' };

function LoggerCard(props) {
  const { site, logger, meters, inverters, sensors } = props;
  const dispatch = useDispatch();

  const [lastOk, setLastOk] = useState('');
  const [loggerMeters, setLoggerMeters] = useState([]);
  const [loggerInverters, setLoggerInverters] = useState([]);
  const [loggerSensors, setLoggerSensors] = useState([]);

  const isOrgAdmin = useVerifyOrgRole(site?.org_id, 0);
  const isOrgEditor = useVerifyOrgRole(site?.org_id, 1);

  useEffect(() => {
    let lastOk = dateStringToDayJS(get(logger, 'stats.last_ok_time'));

    if (lastOk.isValid() && dayjs().isSame(lastOk, 'day')) {
      setLastOk(lastOk.format('HH:mm z'));
    } else {
      setLastOk(lastOk.format('MM/DD/YYYY HH:mm z'));
    }
  }, [logger]);

  useEffect(() => {
    const _loggerMeters = filter(meters, {
      logger_id: logger.logger_id,
    });
    setLoggerMeters(sortBy(_loggerMeters, 'parent_index'));
  }, [logger.logger_id, meters]);

  useEffect(() => {
    const loggerMeterIds = map(loggerMeters, (meter) => meter.meter_id);
    const _loggerInverters = filter(inverters, (inverter) => {
      return includes(loggerMeterIds, inverter.meter_id);
    });
    setLoggerInverters(sortBy(_loggerInverters, 'parent_index'));
  }, [loggerMeters, inverters]);

  useEffect(() => {
    const loggerMeterIds = map(loggerMeters, (meter) => meter.meter_id);
    const _loggerSensors = filter(sensors, (inverter) => {
      return includes(loggerMeterIds, inverter.meter_id);
    });
    setLoggerSensors(sortBy(_loggerSensors, 'parent_index'));
  }, [loggerMeters, sensors]);

  const handleEditLogger = () => {
    dispatch(
      openDialog({
        mode: 'edit',
        type: 'logger',
        id: logger.logger_id,
      })
    );
  };

  const handleCreateDevice = (deviceType, index) => {
    let settings = {
      mode: 'create',
      type: deviceType,
      parentIndex: index,
    };

    if (deviceType === 'meter') {
      settings.siteId = site.site_id;
      settings.loggerId = logger.logger_id;
    } else {
      const meter = loggerMeters[0];
      settings.meterId = meter?.meter_id;
    }
    dispatch(openDialog(settings));
  };

  const handleEditDevice = (device) => {
    const deviceId = get(device, `${device.type_}_id`);
    dispatch(
      openDialog({
        mode: 'edit',
        type: device.type_,
        id: deviceId,
      })
    );
  };

  return (
    <Card raised sx={{ mb: 1 }}>
      <CardContent sx={{ pl: 1, pr: 1, pt: 1, pb: '8px !important' }}>
        <Grid container>
          <Grid item xs={3}>
            <ActionButton
              disabled={!isOrgEditor}
              onClick={handleEditLogger}
              tooltipTitle='Edit Data Logger'
              icon='broadcast-tower'
            />
            <TextField
              id='logger-name'
              label='Name'
              value={!isEmpty(logger) ? logger.name : ''}
              fullWidth
              sx={{ mb: 1, mt: 1 }}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
                fullWidth: true,
              }}
              inputProps={{
                autoComplete: 'off',
              }}
              variant='standard'
            />
            <TextField
              id='logger-ip_address'
              label='IP Address'
              value={!isEmpty(logger) ? logger.ip_address : ''}
              fullWidth
              sx={{ mb: 1 }}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
                fullWidth: true,
              }}
              variant='standard'
              inputProps={{
                autoComplete: 'off',
              }}
            />
            <TextField
              id='logger-status'
              label='status'
              value={logger.online ? 'online' : 'offline'}
              fullWidth
              sx={{ mb: 1 }}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
                fullWidth: true,
              }}
              inputProps={{
                autoComplete: 'off',
              }}
              variant='standard'
            />
            <TextField
              id='logger-last_ok'
              label='Last Ok time'
              value={lastOk}
              fullWidth
              sx={{ mb: 1 }}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
                fullWidth: true,
              }}
              inputProps={{
                autoComplete: 'off',
              }}
              variant='standard'
            />
          </Grid>
          <Grid container item xs={9}>
            {/* METERS */}
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <Stack direction='row' alignItems='center'>
                  <ActionButton
                    disabled={!isOrgAdmin || loggerMeters.length > 1}
                    onClick={() =>
                      handleCreateDevice('meter', loggerMeters.length + 1)
                    }
                    tooltipTitle='Create Meter'
                    placement='left'
                    icon='plus-circle'
                  />
                  <Typography variant='h6' sx={{ ml: 1 }}>
                    Meters
                  </Typography>
                </Stack>
                <Grid container justifyContent='flex-start' spacing={1}>
                  {map(loggerMeters, (meter) => {
                    return (
                      <Grid item xs={3} key={meter.meter_id}>
                        <Card raised>
                          <CardContent sx={cardContentStyles}>
                            <Stack
                              direction='row'
                              alignItems='center'
                              justifyContent='space-between'>
                              <TextField
                                id={`meter-name-${meter.meter_id}`}
                                value={meter.name}
                                InputProps={{
                                  readOnly: true,
                                  disableUnderline: true,
                                }}
                                variant='standard'
                              />
                              {loggerMeters.length > 1 && (
                                <TextField
                                  id={`meter-logger_index-${meter.meter_id}`}
                                  label='Logger Index'
                                  value={meter.parent_index}
                                  InputProps={{
                                    readOnly: true,
                                    disableUnderline: true,
                                  }}
                                  variant='standard'
                                />
                              )}

                              <ActionButton
                                disabled={!isOrgEditor}
                                onClick={() => handleEditDevice(meter)}
                                tooltipTitle='Edit Meter'
                                placement='top'
                                icon='bolt'
                              />
                            </Stack>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
            {/* SENSORS */}
            <Grid container item xs={12} mt={2}>
              <Grid item xs={12}>
                <Stack direction='row' alignItems='center'>
                  <ActionButton
                    disabled={!isOrgAdmin}
                    onClick={() =>
                      handleCreateDevice('sensor', loggerSensors.length + 1)
                    }
                    tooltipTitle='Create Sensor'
                    placement='left'
                    icon='plus-circle'
                  />
                  <Typography variant='h6' sx={{ ml: 1 }}>
                    Sensors
                  </Typography>
                </Stack>
                <Grid container justifyContent='flex-start' spacing={1}>
                  {map(loggerSensors, (sensor) => {
                    return (
                      <Grid item xs={3} key={sensor.sensor_id}>
                        <Card raised>
                          <CardContent sx={cardContentStyles}>
                            <Stack
                              direction='row'
                              alignItems='center'
                              justifyContent='space-between'>
                              <TextField
                                id={`sensor-name-${sensor.sensor_id}`}
                                value={sensor.name}
                                InputProps={{
                                  readOnly: true,
                                  disableUnderline: true,
                                }}
                                variant='standard'
                              />
                              <ActionButton
                                disabled={!isOrgEditor}
                                onClick={() => handleEditDevice(sensor)}
                                tooltipTitle='Edit Sensor'
                                placement='top'
                                icon='thermometer-half'
                              />
                            </Stack>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
            {/* INVERTERS */}
            <Grid container item xs={12} mt={2}>
              <Grid item xs={12}>
                <Stack direction='row' alignItems='center'>
                  <ActionButton
                    disabled={!isOrgAdmin}
                    onClick={() =>
                      handleCreateDevice('inverter', loggerInverters.length + 1)
                    }
                    tooltipTitle='Create Inverter'
                    placement='left'
                    icon='plus-circle'
                  />
                  <Typography variant='h6' sx={{ ml: 1 }}>
                    Inverters
                  </Typography>
                </Stack>
                <Grid container justifyContent='flex-start' spacing={1}>
                  {map(loggerInverters, (inverter) => {
                    const ac_size = inverter.ac_size
                      ? inverter.ac_size + ' kW'
                      : '-';

                    return (
                      <Grid item xs={3} key={inverter.inverter_id}>
                        <Card raised>
                          <CardContent sx={cardContentStyles}>
                            <Stack
                              direction='row'
                              alignItems='center'
                              justifyContent='space-between'>
                              <TextField
                                id={`inverter-name-${inverter.inverter_id}`}
                                value={inverter.name}
                                InputProps={{
                                  readOnly: true,
                                  disableUnderline: true,
                                }}
                                variant='standard'
                              />
                              <ActionButton
                                disabled={!isOrgEditor}
                                onClick={() => handleEditDevice(inverter)}
                                tooltipTitle='Edit Inverter'
                                placement='top'
                                icon='exchange'
                              />
                            </Stack>
                            <TextField
                              id={`inverter-ac_size-${inverter.inverter_id}`}
                              label='AC Size'
                              value={ac_size}
                              fullWidth
                              InputProps={{
                                readOnly: true,
                                disableUnderline: true,
                              }}
                              variant='standard'
                            />
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

LoggerCard.propTypes = {
  site: PropTypes.object.isRequired,
  logger: PropTypes.object.isRequired,
  meters: PropTypes.array.isRequired,
  inverters: PropTypes.array.isRequired,
  sensors: PropTypes.array.isRequired,
};
export default LoggerCard;
