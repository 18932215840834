import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import useTheme from '@mui/material/styles/useTheme';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export const UNITS = ['W', 'kW', 'MW', 'Wh', 'kWh', 'MWh'];

function SelectUnit(props) {
  const { selectedUnit, setSelectedUnit, units } = props;
  const theme = useTheme();

  const [unit, setUnit] = useState('');

  useEffect(() => {
    setUnit(selectedUnit);
  }, [selectedUnit]);

  const handleSelect = (e) => {
    e.preventDefault();
    setSelectedUnit(e.target.value);
  };

  return (
    <Select
      disableUnderline
      id='unit-select'
      value={unit}
      variant='standard'
      onChange={handleSelect}
      sx={{ width: '3rem', textAlign: 'center' }}
      IconComponent={() => null}
      inputProps={{
        sx: {
          padding: '0 !important',
          color: theme.palette.primary.main,
          ...theme.typography.body2,
        },
      }}
      MenuProps={{ MenuListProps: { disablePadding: true } }}>
      {units.map((unit) => {
        return (
          <MenuItem
            key={unit}
            value={unit}
            sx={{
              color: theme.palette.primary.main,
              ...theme.typography.body2,
            }}>
            {unit}
          </MenuItem>
        );
      })}
    </Select>
  );
}

SelectUnit.propTypes = {
  selectedUnit: PropTypes.string,
  setSelectedUnit: PropTypes.func,
};

export default SelectUnit;
