import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';

import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import get from 'lodash/get';
import remove from 'lodash/remove';

import {
  getOrganizationsAPI,
  putOrganizationAPI,
  postOrganizationAPI,
  deleteOrganizationAPI,
  getPortfolioMembershipsAPI,
} from '../../api';
import { getSites } from '../sites';
import { getLicenses } from '../licenses';
import { getLoggers } from '../loggers';
import { getMeters } from '../meters';
import { getInverters } from '../inverters';
import { getSensors } from '../sensors';
import { getMemberships } from '../memberships';
import { getAdminUsers } from '../admin';
import { getReportsData } from '../reports';
import { setLoader } from '../pages';
import { updateNodes } from '../nodes';

const getOrganizations = createAsyncThunk(
  'organization/getOrganizations',
  async (user, { dispatch, getState, requestId }) => {
    try {
      const { loading, currentRequestId } = getState().organizations;

      if (!user) {
        const { item: data } = getState().user;
        user = { ...data };
      }

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(getSites());
      dispatch(getLoggers());
      dispatch(getMeters());
      dispatch(getSensors());
      dispatch(getInverters());
      dispatch(getMemberships());
      dispatch(getAdminUsers(user));
      dispatch(getReportsData(user));
      dispatch(getLicenses());

      return {
        data: await getOrganizationsAPI(),
        portfolioMemberships: await getPortfolioMembershipsAPI(),
      };
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setLoader(false));
    }
  }
);

const putOrganization = createAsyncThunk(
  'organization/putOrganization',
  async (item, { dispatch, getState, requestId }) => {
    try {
      const {
        data: organizations,
        currentRequestId,
        loading,
      } = getState().organizations;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      let updatedOrg = await putOrganizationAPI(item.org_id, item);

      let _organizations = cloneDeep(organizations);
      remove(_organizations, { org_id: get(updatedOrg, 'org_id') });

      _organizations = concat(_organizations, updatedOrg);
      dispatch(updateNodes({ organizations: _organizations }));

      toastr.success('Organization updated');
      return { data: _organizations };
    } catch (err) {
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

const postOrganization = createAsyncThunk(
  'organization/postOrganization',
  async (item, { dispatch, getState, requestId }) => {
    try {
      const {
        data: organizations,
        currentRequestId,
        loading,
      } = getState().organizations;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      let newOrg = await postOrganizationAPI(item);

      toastr.success('Organization created');

      let _organizations = concat(organizations, newOrg);
      dispatch(updateNodes({ organizations: _organizations }));
      return { data: organizations };
    } catch (err) {
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deleteOrganization = createAsyncThunk(
  'organization/deleteOrganization',
  async (org_id, { dispatch, getState, requestId }) => {
    try {
      const {
        data: organizations,
        currentRequestId,
        loading,
      } = getState().organizations;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      let deletedOrgId = await deleteOrganizationAPI(org_id);

      let _organizations = cloneDeep(organizations);
      remove(_organizations, { org_id: deletedOrgId });

      toastr.success('Organization deleted');
      dispatch(updateNodes({ organizations: _organizations }));
      return { data: _organizations };
    } catch (err) {
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

export {
  getOrganizations,
  putOrganization,
  postOrganization,
  deleteOrganization,
};
