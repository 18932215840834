import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';

import AddressField from '../../AddressField';
import { TextFieldListItem } from '../../List/TextFieldListItem';
import { renderLatLngValue } from '../../../helpers';

function Form(props) {
  const {
    mode,
    organization,
    handleChange,
    handleAddressChange,
    handleAddressSelect,
  } = props;

  const { item: user } = useSelector((state) => state.user);

  return (
    <List disablePadding>
      {mode === 'edit' && get(user, 'super_user', false) && (
        <TextFieldListItem
          id='org_id'
          label='Organization ID'
          value={organization.org_id}
          InputProps={{ readOnly: true, disableUnderline: true }}
        />
      )}
      <TextFieldListItem
        label='Name'
        value={organization.name}
        onChange={(e) =>
          handleChange({ target: { id: 'name', value: e.target.value } })
        }
      />
      <ListItem sx={{ pl: 0, pr: 0, pt: 1, pb: 1 }}>
        <AddressField
          id='address'
          address={organization.address}
          onAddressSelect={handleAddressSelect}
          handleAddressChange={handleAddressChange}
        />
      </ListItem>
      {mode === 'edit' && (
        <>
          <TextFieldListItem
            label='Timezone'
            value={organization.timezone.zone}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
          />
          <ListItem
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              pl: 1,
              pb: 0,
              pt: 0,
            }}>
            <TextField
              label='Timezone Abbreviation'
              value={organization.timezone.name}
              variant='standard'
              sx={{ width: '45%' }}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
            />
            <TextField
              label='Timezone Offset'
              value={organization.timezone.offset}
              variant='standard'
              sx={{ width: '45%' }}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
            />
          </ListItem>
        </>
      )}
      <ListItem
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          pl: 0,
          pr: 0,
          pt: 1,
          pb: 1,
        }}>
        <TextField
          id='lat'
          label='Latitude'
          value={renderLatLngValue(organization.location.lat)}
          variant='standard'
          sx={{ width: '45%' }}
          InputProps={{ readOnly: true, disableUnderline: true }}
        />
        <TextField
          id='lng'
          label='Longitude'
          value={renderLatLngValue(organization.location.lng)}
          variant='standard'
          sx={{ width: '45%' }}
          InputProps={{ readOnly: true, disableUnderline: true }}
        />
      </ListItem>
      {mode === 'create' && (
        <ListItem disablePadding>
          <FormControlLabel
            disabled={mode === 'edit'}
            control={
              <Checkbox
                id='is_portfolio'
                checked={organization.is_portfolio}
                onChange={handleChange}
              />
            }
            label='Portfolio'
          />
        </ListItem>
      )}
    </List>
  );
}

Form.propTypes = {
  mode: PropTypes.string,
  organization: PropTypes.object,
  handleChange: PropTypes.func,
  handleAddressChange: PropTypes.func,
  handleAddressSelect: PropTypes.func,
};

export default Form;
