import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import filter from 'lodash/filter';
// import PropTypes from 'prop-types';

import {
  SortingState,
  IntegratedSorting,
  IntegratedFiltering,
  IntegratedPaging,
  EditingState,
  FilteringState,
  PagingState,
} from '@devexpress/dx-react-grid';

import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';

import useVerifyOrgRole from '../../../store/hooks/useVerifyOrgRole';
import { openDialog } from '../../../store/dialogs';

import BaseTable from './BaseTable';
import { EditCell } from '../cells';
import { DateTimeTypeProvider } from '../providers';

function GeneratorMetersTable({ generator }) {
  const dispatch = useDispatch();
  const isOrgAdmin = useVerifyOrgRole(generator?.org_id, 0);

  const { generatorMeters } = useSelector((state) => state.generators);

  const [tableData, setTableData] = useState([]);
  const columns = [
    { title: 'Meter', name: 'name', width: 0.2 },
    { title: 'Last Reading', name: 'last_meter_reading', width: 0.2 },
    { title: 'Last Net Generation', name: 'last_net_generation', width: 0.2 },
    { title: 'Unprocessed', name: 'unprocessed_generation', width: 0.2 },
    { title: 'Last Update', name: 'last_update', width: 0.2 },
  ];

  useEffect(() => {
    if (generatorMeters) {
      setTableData(
        filter(generatorMeters, { generator_id: generator.generator_id })
      );
    }
  }, [generatorMeters, generator.generator_id]);

  const handleViewClick = (meter) => {
    dispatch(
      openDialog({
        mode: 'view',
        type: 'generator_meter',
        id: meter.meter_id,
      })
    );
  };

  return (
    <BaseTable
      rows={tableData}
      columns={columns}
      editColumn={
        <TableEditColumn
          width={42}
          showEditCommand
          cellComponent={(props) => (
            <EditCell
              disabled={!isOrgAdmin}
              tooltipText='View Generator'
              // eslint-disable-next-line
              handleClick={() => handleViewClick(props.row)}
              icon={['fal', 'eye']}
              {...props}
            />
          )}
        />
      }>
      <DateTimeTypeProvider for={['last_update']} />
      <EditingState />
      <FilteringState />
      <SortingState
        defaultSorting={[{ columnName: 'name', direction: 'asc' }]}
      />
      <PagingState defaultCurrentPage={0} pageSize={10} />

      <IntegratedFiltering />
      <IntegratedSorting />
      <IntegratedPaging />
    </BaseTable>
  );
}

// GeneratorMetersTable.propTypes = {};

export default GeneratorMetersTable;
