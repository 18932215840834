import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import each from 'lodash/each';
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import range from 'lodash/range';
import sortBy from 'lodash/sortBy';

import useTheme from '@mui/material/styles/useTheme';

import {
  Area,
  AreaChart,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import ChartTooltip from './Tooltip';
import { navigate } from '../../../../store/pages';

function InvertersProfileChart({ site, logger, inverters }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (inverters.length) {
      let _data = map(range(start.unix(), end.unix(), 900), (timestamp) => ({
        timestamp,
      }));

      setData(
        map(_data, (record) => {
          const timestamp = get(record, 'timestamp');
          each(inverters, (inverter) => {
            let inverterPoint = find(inverter.timeseries, { timestamp });
            record[inverter.inverter_id] = get(inverterPoint, 'value');
          });

          return {
            ...record,
          };
        })
      );
    }

    /* eslint-disable-next-line */
  }, [inverters]);

  const handleClick = () => {
    dispatch(
      navigate({
        page: 'logger',
        id: logger.logger_id,
        tab: 'analysis',
      })
    );
  };

  const timezoneOffset = get(site, 'timezone.offset', 0);
  const start = dayjs()
    .utcOffset(timezoneOffset)
    .startOf('day')
    .subtract(1, 'day');
  const end = start.utcOffset(timezoneOffset).add(2, 'day');

  let startReference = start.add(3, 'hour');
  let endReference = start.add(27, 'hour');
  return (
    <ResponsiveContainer width='100%' height={175}>
      <AreaChart
        onClick={handleClick}
        width='100%'
        height={175}
        data={data}
        margin={{
          top: 0,
          right: 10,
          left: 10,
          bottom: 0,
        }}>
        <YAxis hide domain={[0, (dataMax) => Math.ceil(dataMax / 10) * 10]} />
        <XAxis
          type='number'
          dataKey='timestamp'
          domain={[start.unix(), end.unix()]}
          hide
        />
        <ReferenceLine
          x={startReference.unix()}
          stroke={'transparent'}
          label={
            <Label position='insideTopLeft'>
              {startReference.format('MMM DD')}
            </Label>
          }
        />
        <ReferenceLine
          x={endReference.unix()}
          stroke={'transparent'}
          label={
            <Label position='insideTopLeft'>
              {endReference.format('MMM DD')}
            </Label>
          }
        />
        <Tooltip
          content={
            <ChartTooltip timezone={site.timezone} inverters={inverters} />
          }
        />
        {map(sortBy(inverters, 'parent_index'), (inverter) => {
          return (
            <Area
              dot={false}
              stackId='area'
              key={`line-${inverter.inverter_id}`}
              dataKey={inverter.inverter_id}
              stroke={theme.palette.veregy_colors.yellow}
              fill={'transparent'}
              connectNulls
              strokeWidth={2}
            />
          );
        })}
      </AreaChart>
    </ResponsiveContainer>
  );
}

InvertersProfileChart.propTypes = {
  site: PropTypes.object.isRequired,
  logger: PropTypes.object.isRequired,
  inverters: PropTypes.array.isRequired,
};

export default InvertersProfileChart;
