import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import has from 'lodash/has';
import filter from 'lodash/filter';
import toUpper from 'lodash/toUpper';

import Grid from '@mui/material/Grid';

import useOrganization from '../../../../store/hooks/useOrganization';
import useVerifyOrgRole from '../../../../store/hooks/useVerifyOrgRole';
import ViewStack from '../../../../components/ViewStack';
import MembershipsTable from '../../../../components/table/tables/MembershipsTable';
import OrgPortfolioRelationsTable from '../../../../components/table/tables/OrgPortfolioRelationsTable';

const VIEWS = {
  ORGANIZATIONS: {
    name: 'organizations',
    description: "Manage the portfolio's child organizations",
  },
};

export default function Configuration() {
  const { id, view } = useSelector((state) => state.pages.portfolio);
  const allMemberships = useSelector((state) => state.memberships.data);

  const [views, setViews] = useState(VIEWS);
  const [selectedView, setSelectedView] = useState(views.ORGANIZATIONS.name);
  const [memberships, setMemberships] = useState([]);
  const portfolio = useOrganization(id);

  const isOrgAdmin = useVerifyOrgRole(id, 0);

  useEffect(() => {
    setMemberships(filter(allMemberships, { org_id: id }));
  }, [allMemberships, id]);

  useEffect(() => {
    if (isOrgAdmin) {
      setViews({
        ...VIEWS,
        USERS: {
          name: 'users',
          description: "Manage the portfolio's user memberships",
        },
      });
    }
  }, [isOrgAdmin]);

  useEffect(() => {
    if (has(views, toUpper(view))) {
      setSelectedView(view);
    } else if (view === '') {
      setSelectedView(views.ORGANIZATIONS.name);
    }
  }, [view, views]);

  const renderView = () => {
    switch (selectedView) {
      case views?.USERS?.name:
        return (
          <MembershipsTable relations={memberships} orgId={portfolio.org_id} />
        );

      case views.ORGANIZATIONS.name:
        return <OrgPortfolioRelationsTable />;

      default:
        return null;
    }
  };

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={2}
      padding={2}
      sx={{ pl: 4, pr: 3 }}>
      <Grid item xs={12} sx={{ paddingTop: '8px !important' }}>
        <ViewStack
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          views={views}
        />
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: '8px !important' }}>
        {renderView()}
      </Grid>
    </Grid>
  );
}
