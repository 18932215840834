import React, { useEffect, useState } from 'react';
import { useStateValidator } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import size from 'lodash/size';
import startCase from 'lodash/startCase';
import isEmail from 'validator/lib/isEmail';

import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';

import { resendConfirmationCode } from '../../../helpers/auth/cognito';
import { closeDialog } from '../../../store/dialogs';
import {
  deleteUser,
  generateAPIKey,
  postUser,
  putUser,
  resendAccountCreationEmail,
} from '../../../store/admin';
import Form from './Form';
import DialogTitleOptions from '../DialogTitleOptions';

const defaultState = {
  user_id: '',
  name: '',
  email: '',
  super_user: false,
  sign_in_count: '',
  account_status: '',
  default_organization: '',
  api: {
    key: '',
  },
};

function UserDialog() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [user, setUser] = useState(defaultState);
  const [_memberships, setMemberships] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [portfolios, setPortfolios] = useState([]);
  const [emailValid] = useStateValidator(user.email, (email) => isEmail(email));

  const { id, mode } = useSelector((state) => state.dialogs.user);
  const { users, memberships } = useSelector((state) => state.admin);
  const { data: allOrganizations } = useSelector(
    (state) => state.organizations
  );

  useEffect(() => {
    if (size(id) > 0) {
      setUser(find(users, { user_id: id }));
      setMemberships(filter(memberships, { user_id: id }));
    } else {
      setUser(defaultState);
      setMemberships([]);
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    let _organizations = allOrganizations;
    if (mode === 'edit') {
      if (user?.super_user) {
        _organizations = allOrganizations;
      } else {
        _organizations = map(_memberships, (membership) => {
          return find(allOrganizations, { org_id: membership.org_id });
        });
      }
    }
    setPortfolios(filter(_organizations, { is_portfolio: true }));
    setOrganizations(filter(_organizations, { is_portfolio: false }));
  }, [user, _memberships, mode, allOrganizations]);

  const handleClose = () => {
    dispatch(closeDialog('user'));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    switch (mode) {
      case 'create':
        if (!user.email) {
          toastr.error('Email is required');
          return;
        }

        if (!emailValid) {
          toastr.error('Invalid email address');
          return;
        }

        if (!user.name) {
          toastr.error('Name is required');
          return;
        }

        dispatch(
          postUser({
            email: user.email.toLowerCase(),
            name: user.name,
            super_user: user.super_user,
            default_organization: user.default_organization,
          })
        );
        break;

      case 'edit':
        dispatch(putUser(user));
        break;

      default:
        return null;
    }

    handleClose();
  };

  const handleDelete = (e) => {
    e.preventDefault();
    dispatch(deleteUser(user.user_id));
    handleClose();
  };

  const handleResendEmail = async () => {
    let org_id = user.default_organization;

    const email = get(user, 'email');
    const userId = get(user, 'user_id');

    switch (get(user, 'account_status')) {
      case 'UNCONFIRMED':
        try {
          const username = userId.replace('user:', '');
          await resendConfirmationCode(username);
          toastr.success(`Account confirmation email resent to ${email}`);
        } catch (error) {
          toastr.error('Confirmation email not sent');
        }

        break;
      case 'FORCE_CHANGE_PASSWORD':
        dispatch(
          resendAccountCreationEmail({ ...user, default_organization: org_id })
        );
        break;
      default:
        break;
    }
    handleClose();
  };

  const handleGenerateAPIKey = async () => {
    dispatch(generateAPIKey(get(user, 'user_id')));
    handleClose();
  };

  const handleChange = (e) => {
    let value = e.target.value;
    let id = e.target.id;
    if (e.target.id === 'super_user') {
      value = !user.super_user;
    }
    if (e.target.value.startsWith('org:')) {
      id = 'default_organization';
    }
    setUser({ ...user, [id]: value });
  };

  return (
    <Dialog open={id !== ''} onClose={handleClose} fullScreen={fullScreen}>
      <form onSubmit={handleSubmit} style={theme.dialog.form}>
        <DialogTitle sx={{ p: 1, pl: 2, pb: 0 }}>
          {startCase(mode) + ' User'}
          <DialogTitleOptions mode={mode} handleClose={handleClose}>
            {user.account_status === 'FORCE_CHANGE_PASSWORD' ||
            user.account_status === 'UNCONFIRMED' ? (
              <MenuItem onClick={handleResendEmail}>
                Resend Verification Email
              </MenuItem>
            ) : null}
            {user.account_status === 'CONFIRMED' ? (
              <MenuItem onClick={handleGenerateAPIKey}>
                Generate API Key
              </MenuItem>
            ) : null}
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
          </DialogTitleOptions>
        </DialogTitle>
        <DialogContent
          sx={{
            overflow: 'inherit',
            p: 2,
            pt: 0,
            pb: 0,
          }}>
          <Form
            mode={mode}
            user={user}
            organizations={organizations}
            portfolios={portfolios}
            handleChange={handleChange}
          />
        </DialogContent>
        <DialogActions sx={{ p: '4px' }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type='submit'
            onClick={handleSubmit}
            color='primary'
            variant='contained'>
            {(() => {
              switch (mode) {
                case 'create':
                  return 'Create';
                case 'edit':
                  return 'Update';
                default:
                  return null;
              }
            })()}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default UserDialog;
