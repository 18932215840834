import React from 'react';
import { useDispatch } from 'react-redux';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import IconButton from '@mui/material/IconButton';

import { openSubscribedUsersDialog } from '../../../store/dialogs';

export const SubscribedUsersTypeProvider = (props) => {
  const dispatch = useDispatch();

  const handleClick = (triggerDevice) => {
    dispatch(openSubscribedUsersDialog(triggerDevice));
  };

  return (
    <DataTypeProvider
      formatterComponent={({ row }) => {
        return (
          <IconButton
            onClick={() => handleClick(row)}
            disabled={!row.trigger_device_id}
            sx={{
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'center',
            }}>
            <FontAwesomeIcon
              icon={['fal', 'users']}
              style={{ height: 25, width: 25 }}
            />
          </IconButton>
        );
      }}
      {...props}
    />
  );
};
