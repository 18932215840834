import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export const DateTimeTypeProvider = (props) => {
  return (
    <DataTypeProvider
      formatterComponent={({ value }) => {
        const offset = value.slice(-6);
        const datetime = value.substring(0, 19);

        return dayjs(datetime + offset).format('MM/DD/YYYY HH:mm z');
      }}
      {...props}
    />
  );
};
