import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import styled from '@mui/material/styles/styled';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

const TopContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  paddingBottom: theme.spacing(1),
}));

const BottomContent = styled('div')({
  display: 'flex',
  paddingLeft: '10px',
  justifyContent: 'flex-start',
  alignItems: 'center',
  minHeight: 32,
});

const BottomPanel = styled('div')(({ color }) => ({
  height: 10,
  width: '100%',
  bottom: 0,
  backgroundColor: color,
}));

function InfoGrid(props) {
  const { title, attribute1, attribute2, color } = props;
  return (
    <Grid item xs={4}>
      <Card raised>
        <TopContainer>
          {!isEmpty(attribute1) && (
            <TextField
              id={attribute1.label}
              label={attribute1.label}
              value={attribute1.value}
              fullWidth
              sx={{ mb: 1 }}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
                fullWidth: true,
              }}
              variant='standard'
            />
          )}

          {!isEmpty(attribute2) && (
            <TextField
              id={attribute2.label}
              label={attribute2.label}
              value={attribute2.value}
              fullWidth
              InputProps={{
                readOnly: true,
                disableUnderline: true,
                fullWidth: true,
              }}
              variant='standard'
            />
          )}
        </TopContainer>
        <BottomContent>{title}</BottomContent>
        <BottomPanel color={color} />
      </Card>
    </Grid>
  );
}

InfoGrid.propTypes = {
  title: PropTypes.object,
  attribute1: PropTypes.object,
  attribute2: PropTypes.object,
  color: PropTypes.string,
};

export default InfoGrid;
