import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';
import { push } from 'redux-first-history';

import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import get from 'lodash/get';
import includes from 'lodash/includes';
import map from 'lodash/map';
import remove from 'lodash/remove';

import { user as initialState } from '../initialState';
import ROUTES from '../../constants/routes';
import {
  authAPI,
  getSubscriptionsAPI,
  postSubscriptionAPI,
  deleteSubscriptionAPI,
} from '../../api';
import { getOrganizations } from '../organizations/_organizations';
import { setLoader } from '../pages';
import { removeTokens } from '../../helpers/auth/tokens';

const authenticateUser = createAsyncThunk(
  'user/authenticateUser',
  async (_, { dispatch }) => {
    try {
      dispatch(setLoader(true));
      const { user, memberships } = await authAPI();
      const subscriptions = await getSubscriptionsAPI(user.user_id);
      dispatch(getOrganizations(user));

      return { item: user, memberships, subscriptions };
    } catch (err) {
      console.error('Authentication Error: ', err);
      dispatch(push(ROUTES.UNAUTH.LOGIN));
      dispatch(setLoader(false));
    }
  }
);

const logoutUser = createAsyncThunk(
  'user/logoutUser',
  async (_, { dispatch }) => {
    try {
      setLoader(true);
      removeTokens();
      return initialState;
    } catch (err) {
      console.error('LOGOUT FAILED', err);
    } finally {
      dispatch(push(ROUTES.UNAUTH.LOGIN));
      setLoader(false);
    }
  }
);

const createSubscriptions = createAsyncThunk(
  'user/createSubscriptions',
  async (newSubscriptions, { dispatch, getState }) => {
    const { subscriptions } = getState().user;
    let subscriptionsAdded = [];
    try {
      dispatch(showLoading());
      let _subscriptions = cloneDeep(subscriptions);

      subscriptionsAdded = await Promise.all(
        map(newSubscriptions, async (sub) => await postSubscriptionAPI(sub))
      );

      toastr.success('Alarm Subscriptions created');
      return {
        subscriptions: concat(_subscriptions, subscriptionsAdded),
      };
    } catch (err) {
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deleteSubscriptions = createAsyncThunk(
  'user/deleteSubscriptions',
  async (removeSubscriptions, { dispatch, getState }) => {
    const { subscriptions } = getState().user;
    let subscriptionsRemoved = [];
    try {
      dispatch(showLoading());
      let _subscriptions = cloneDeep(subscriptions);

      subscriptionsRemoved = await Promise.all(
        map(
          removeSubscriptions,
          async (subscription) =>
            await deleteSubscriptionAPI(subscription.subscription_id)
        )
      );

      // update store
      remove(_subscriptions, (subscription) =>
        includes(subscriptionsRemoved, subscription.subscription_id)
      );

      toastr.success('Alarm Subscriptions deleted');
      return {
        subscriptions: _subscriptions,
      };
    } catch (err) {
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

export {
  authenticateUser,
  createSubscriptions,
  deleteSubscriptions,
  logoutUser,
};
