import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';

import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import get from 'lodash/get';
import remove from 'lodash/remove';

import { postSiteAPI, getSitesAPI, putSiteAPI, deleteSiteAPI } from '../../api';
import { updateNodes } from '../nodes';

const getSites = createAsyncThunk(
  'sites/getSites',
  async (_, { getState, requestId }) => {
    const { currentRequestId, loading } = getState().sites;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    return { data: await getSitesAPI() };
  }
);

const postSite = createAsyncThunk(
  'sites/postSite',
  async (data, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading, data: allSites } = getState().sites;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      let newSite = await postSiteAPI(data);
      toastr.success('Site created');
      const sites = concat(allSites, newSite);

      dispatch(updateNodes({ sites }));

      return { data: sites };
    } catch (err) {
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

const putSite = createAsyncThunk(
  'sites/putSite',
  async (site, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading, data: sites } = getState().sites;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      let updatedSite = await putSiteAPI(site);

      let _sites = cloneDeep(sites);
      remove(_sites, { site_id: get(updatedSite, 'site_id') });
      toastr.success('Site updated');
      return { data: concat(_sites, updatedSite) };
    } catch (err) {
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deleteSite = createAsyncThunk(
  'sites/deleteSite',
  async (site, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading, data: allSites } = getState().sites;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      let deletedSiteId = await deleteSiteAPI(site.site_id);
      toastr.success('Site Deleted');

      let sites = cloneDeep(allSites);
      remove(sites, { site_id: deletedSiteId });

      dispatch(updateNodes({ sites }));

      return { data: sites };
    } catch (err) {
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { postSite, getSites, putSite, deleteSite };
