import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import filter from 'lodash/filter';
import has from 'lodash/has';
import toUpper from 'lodash/toUpper';

import Grid from '@mui/material/Grid';

import { setPage } from '../../../../store/pages';
import CountGrid from '../../../../components/Grid/CountGrid';
import ResourceCount from '../../../../components/widgets/ResourceCount';
import CurrentGeneration from '../../../../components/widgets/CurrentGeneration';
import ProductionIndex from '../../../../components/widgets/ProductionIndex';
import Map from '../../../../components/Map';
import SitesGrid from './SitesGrid';
import ViewStack from '../../../../components/ViewStack';
import useOrganizationDevices from '../../../../store/hooks/useOrganizationDevices';
import AlarmsTable from '../../../../components/table/tables/AlarmsTable';
import useVerifyOrgRole from '../../../../store/hooks/useVerifyOrgRole';
import useOrganization from '../../../../store/hooks/useOrganization';

const VIEWS = {
  SITES: {
    name: 'sites',
    description: 'View a snapshot of recent data for each site',
  },
  MAP: {
    name: 'map',
    description: "Map view of the organization's sites",
  },
  ALARMS: {
    name: 'alarms',
    description: 'Table of alarms for the devices in this organization',
  },
};

function OrganizationDashboard() {
  const dispatch = useDispatch();
  const { id, view, expectationType } = useSelector(
    (state) => state.pages.organization
  );
  const organization = useOrganization(id);
  const { sites, loggers, meters, inverters, sensors, alarms, loading } =
    useOrganizationDevices(id);

  const isOrgAdmin = useVerifyOrgRole(id, 0);
  const [selectedView, setSelectedView] = useState(VIEWS.SITES.name);

  useEffect(() => {
    if (has(VIEWS, toUpper(view))) {
      setSelectedView(view);
    } else if (view === '') {
      setSelectedView(VIEWS.SITES.name);
    }
  }, [view]);

  const handleSetExpectationType = (type) => {
    switch (type) {
      case 'modeled':
      case 'corrected':
        dispatch(setPage({ page: 'organization', expectationType: type }));
        break;

      default:
        dispatch(setPage({ page: 'organization', expectationType: 'modeled' }));
        break;
    }
  };

  const renderView = () => {
    if (selectedView === VIEWS.SITES.name) {
      return (
        <SitesGrid
          organization={organization}
          sites={sites}
          expectationType={expectationType}
          loading={loading}
        />
      );
    } else if (selectedView === VIEWS.MAP.name) {
      return (
        <Map
          center={organization}
          locations={sites}
          styles={{
            height: '58vh',
            width: '100%',
          }}
        />
      );
    } else if (selectedView === VIEWS.ALARMS.name) {
      return <AlarmsTable resource={organization} alarms={alarms} />;
    }
  };

  const activeAlarms = filter(alarms, { status: true });

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={2}
      padding={2}
      sx={{ pl: 4, pr: 3 }}>
      <Grid item xs={4}>
        <ResourceCount loading={loading}>
          <CountGrid number={sites.length} name='Sites' />
          <CountGrid number={meters.length} name='Meters' />
          <CountGrid number={activeAlarms.length} name='Alarms' />
        </ResourceCount>
      </Grid>
      <Grid item xs={4}>
        <CurrentGeneration
          loggers={loggers}
          meters={meters}
          inverters={inverters}
          loading={loading}
        />
      </Grid>
      <Grid item xs={4}>
        <ProductionIndex
          sites={sites}
          meters={meters}
          inverters={inverters}
          sensors={sensors}
          loading={loading}
          expectationType={expectationType}
          setExpectationType={handleSetExpectationType}
          disabled={!isOrgAdmin}
        />
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: '8px !important' }}>
        <ViewStack
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          views={VIEWS}
        />
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: '8px !important' }}>
        {renderView()}
      </Grid>
    </Grid>
  );
}

export default OrganizationDashboard;
