import axios from '../xhr_libs/axios';
import { processResponse } from '.';

export const getDailyCostDataAPI = async (start, end) => {
  return processResponse(
    await axios.get(`/resource/kpis/costs/${start}/${end}`)
  );
};

export const getReleasesDataAPI = async (start, end) => {
  return processResponse(
    await axios.get(`/resource/kpis/releases/${start}/${end}`)
  );
};
