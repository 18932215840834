import axios from '../xhr_libs/axios';

export const processResponse = (response) => {
  if (response?.data?.result === 'success') {
    return response?.data?.value;
  }
};

export const resendAccountCreationEmailAPI = async (body) => {
  return processResponse(
    await axios.post(`/auth/resend-confirmation-email/${body.user_id}`, body)
  );
};

export * from './alarms';
export * from './generators';
export * from './images';
export * from './inverters';
export * from './kiosk';
export * from './kpis';
export * from './licenses';
export * from './loggers';
export * from './memberships';
export * from './meters';
export * from './organizations';
export * from './portfolio_memberships';
export * from './report_files';
export * from './reports';
export * from './sensors';
export * from './sites';
export * from './subscriptions';
export * from './timestream';
export * from './trigger_devices';
export * from './user_reports';
export * from './users';
