import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { yesterday } from '../../../../helpers/dates';
import SelectRange from '../../selectors/SelectRange';
import SelectTimezone, { TIMEZONES } from '../../selectors/SelectTimezone';
import EstimatedChart from '.';
import SelectSites from '../../selectors/SelectSites';

function EstimatedChartContainer(props) {
  const { defaultTimezone, sites } = props;

  const [fetch, setFetch] = useState(false);
  const [selectedSites, setSelectedSites] = useState([]);

  const [range, setRange] = useState({ key: '', name: '' });
  const [timezone, setTimezone] = useState(TIMEZONES[0]);

  useEffect(() => {
    if (sites.length === 1) {
      setSelectedSites(sites);
    } else if (sites.length > 1) {
      setSelectedSites(sites.slice(0, 1));
    }
  }, [sites]);

  // set timezone
  useEffect(() => {
    if (!isEmpty(defaultTimezone)) {
      setTimezone(defaultTimezone);
    }
  }, [defaultTimezone]);

  // set range
  useEffect(() => {
    if (!isEmpty(defaultTimezone)) {
      setRange(yesterday(defaultTimezone.offset));
    }
  }, [defaultTimezone]);

  const fetchData = () => {
    if (selectedSites.length === 0) {
      toastr.warning('Select at least one site');
      return;
    }
    setFetch(true);
  };

  const handleRangeSelect = (newRange) => {
    setRange(newRange);
    setFetch(true);
  };

  return (
    <>
      <Grid item xs={12}>
        <Card raised>
          <CardHeader
            sx={{ p: 0 }}
            title={
              <Stack
                direction='row'
                justifyContent='space-evenly'
                alignItems='center'
                divider={
                  <Divider
                    orientation='vertical'
                    flexItem
                    sx={{ mt: 1, mb: 1 }}
                  />
                }>
                <SelectSites
                  allSites={sites}
                  selectedSites={selectedSites}
                  setSelectedSites={setSelectedSites}
                />

                <SelectRange
                  range={range}
                  handleRangeSelect={handleRangeSelect}
                  timezone={timezone}
                />

                <SelectTimezone
                  selectedTimezone={timezone}
                  setSelectedTimezone={setTimezone}
                />

                <Button
                  color='primary'
                  variant='contained'
                  size='small'
                  onClick={() => fetchData()}>
                  Generate
                </Button>
              </Stack>
            }
          />
        </Card>
      </Grid>

      {map(selectedSites, (site) => {
        return (
          <EstimatedChart
            key={site.site_id}
            site={site}
            range={range}
            timezone={timezone}
            fetch={fetch}
            setFetch={setFetch}
          />
        );
      })}
    </>
  );
}

EstimatedChartContainer.propTypes = {
  sites: PropTypes.array,
  meters: PropTypes.array,
};

export default EstimatedChartContainer;
