import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import find from 'lodash/find';
import findKey from 'lodash/findKey';
import includes from 'lodash/includes';

import { setPage } from '../../../store/pages';
import useMeters from '../../../store/hooks/useMeters';
import { TABS } from '../../../constants/tabs';
import TabBar from '../../../components/tab/TabBar';
import ROUTES from '../../../constants/routes';
import Analysis from './Analysis';
import Configuration from './Configuration';
import MeterDashboard from './Dashboard';

export default function Meter() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const { id, tab } = useSelector((state) => state.pages.meter);
  useMeters(id);

  useEffect(() => {
    if (location.hash && !tab) {
      let _tab = find(TABS, (value) => value === location.hash);
      dispatch(setPage({ page: 'meter', tab: _tab, view: '' }));
    } else if (tab) {
      let _tabIndex = findKey(TABS, (_tab) => {
        return includes(_tab, tab);
      });

      if (_tabIndex) {
        setTabIndex(Number(_tabIndex));
      }
    } else {
      setTabIndex(0);
    }
  }, [dispatch, location.hash, tab]);

  useEffect(() => {
    const meterId = localStorage.getItem('solar.meter');
    if (id) {
      localStorage.setItem('solar.meter', id);
    } else if (meterId) {
      dispatch(setPage({ page: 'meter', id: meterId }));
    }
  }, [dispatch, id]);

  const handleChangeTab = (event, value) => {
    dispatch(push(ROUTES.AUTH.METER + TABS[value]));
    dispatch(setPage({ page: 'meter', tab: TABS[value], view: '' }));
  };

  return (
    <TabBar handleChangeTab={handleChangeTab} tabIndex={tabIndex}>
      <MeterDashboard />
      <Analysis />
      <Configuration />
    </TabBar>
  );
}
