import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';

import { TextFieldListItem } from '../../List/TextFieldListItem';
import { IntegerFieldListItem } from '../../List/IntegerFieldListItem';
import { dateStringToHumanReadable } from '../../../helpers/dates';

function Form(props) {
  const { mode, generator } = props;
  const user = useSelector((state) => state.user.item);

  return (
    <List disablePadding>
      {mode === 'view' && get(user, 'super_user', false) ? (
        <TextFieldListItem
          id='generator_id'
          label='Generator ID'
          value={generator.generator_id}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
          }}
        />
      ) : null}
      <TextFieldListItem
        id='account_id'
        label='Account ID'
        value={generator.account_id}
        InputProps={{
          readOnly: true,
          disableUnderline: true,
        }}
      />
      <TextFieldListItem
        id='account_name'
        label='Account Name'
        value={generator.account_name}
        InputProps={{
          readOnly: true,
          disableUnderline: true,
        }}
      />
      <TextFieldListItem
        label='Name'
        value={generator.name}
        InputProps={{
          readOnly: true,
          disableUnderline: true,
        }}
      />
      <IntegerFieldListItem
        label='Capacity'
        value={generator.capacity}
        InputProps={{
          readOnly: true,
          disableUnderline: true,
          endAdornment: <InputAdornment position='end'>kW</InputAdornment>,
        }}
      />
      <TextFieldListItem
        label='Last Month of Generation'
        value={generator.last_month_of_generation}
        InputProps={{
          readOnly: true,
          disableUnderline: true,
        }}
      />
      <TextFieldListItem
        label='Last Update'
        value={
          generator.last_update
            ? dateStringToHumanReadable(generator.last_update)
            : ''
        }
        InputProps={{
          readOnly: true,
          disableUnderline: true,
        }}
      />
    </List>
  );
}

Form.propTypes = {
  generator: PropTypes.object,
  setGenerator: PropTypes.func,
  handleClose: PropTypes.func,
};

export default Form;
