import React from 'react';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';

import useSite from '../../../../store/hooks/useSite';
import useMeter from '../../../../store/hooks/useMeter';
import useSensor from '../../../../store/hooks/useSensor';
import SensorChart from '../../../../components/charts/analysis/SensorChart';

export default function Analysis() {
  const { id } = useSelector((state) => state.pages.sensor);
  const sensor = useSensor(id);
  const meter = useMeter(sensor.meter_id);
  const site = useSite(meter.site_id);

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={2}
      padding={2}
      sx={{ pl: 4, pr: 3 }}>
      <SensorChart site={site} sensor={sensor} />
    </Grid>
  );
}
