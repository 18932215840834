import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';
import reduce from 'lodash/reduce';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import {
  EditingState,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';

import { openConfirmDialog } from '../../../store/dialogs';
import {
  deleteUserReport,
  postUserReport,
} from '../../../store/reports/_reports';
import useCompareOrgSiteName from '../../../store/hooks/useCompareOrgSiteName';
import useCompareReportName from '../../../store/hooks/useCompareReportName';
import BaseTable from '../../../components/table/tables/BaseTable';
import SelectOrganization from '../../../components/selectors/SelectOrganization';
import SelectReportTemplate from '../../../components/charts/selectors/SelectReportTemplate';
import {
  CheckBoxTypeProvider,
  OrgSiteIdNameLinkTypeProvider,
  ReportTypeProvider,
} from '../../../components/table/providers';

const ReportSubscriptionsTable = () => {
  const dispatch = useDispatch();
  const compareOrgSiteName = useCompareOrgSiteName();
  const compareReportName = useCompareReportName();

  const { item: user } = useSelector((state) => state.user);
  const { data: memberships } = useSelector((state) => state.memberships);
  const { data: reports, userReports } = useSelector((state) => state.reports);
  const [selectedOrganization, setSelectedOrganization] = useState({});
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [relations, setRelations] = useState([]);

  const [columns] = useState([
    { title: 'Subscribed', name: 'subscribed', width: 0.1 },
    { title: 'Site', name: 'device_id', width: 0.35 },
    { title: 'Report', name: 'report_id', width: 0.35 },
  ]);

  const [integratedSortingColumnExtensions] = useState([
    { columnName: 'device_id', compare: compareOrgSiteName },
    { columnName: 'report_id', compare: compareReportName },
  ]);

  useEffect(() => {
    let _reportingMemberships = filter(
      memberships,
      (membership) =>
        membership?.user_id === user?.user_id && membership?.licenses?.reporting
    );

    let _relations = reduce(
      reports,
      (acc, report) => {
        let membership = find(_reportingMemberships, { org_id: report.org_id });
        if (membership || user?.super_user) return [...acc, report];
        return acc;
      },
      []
    );

    if (selectedOrganization?.org_id) {
      _relations = filter(reports, {
        org_id: selectedOrganization.org_id,
      });
    }

    if (selectedTemplate) {
      _relations = filter(_relations, (relation) => {
        return relation.name === selectedTemplate;
      });
    }

    _relations = map(_relations, (relation) => {
      let _userReport = find(userReports, { report_id: relation.report_id });
      return {
        ...relation,
        subscribed: !!_userReport,
        report_id: relation.report_id,
      };
    });

    setRelations(_relations);
  }, [
    user,
    memberships,
    reports,
    userReports,
    selectedOrganization,
    selectedTemplate,
  ]);

  const handleClick = (attrs) => {
    dispatch(
      openConfirmDialog({
        title: `${attrs?.subscribed ? 'Unsubscribe' : 'Subscribe'} to Report`,
        message: `Are you sure you want to ${
          attrs?.subscribed ? 'unsubscribe from' : 'subscribe to'
        } this report?`,
        onConfirm: () =>
          dispatch(
            attrs?.subscribed
              ? deleteUserReport({
                  report_id: attrs.report_id,
                })
              : postUserReport({
                  org_id: attrs.org_id,
                  device_id: attrs.device_id,
                  report_id: attrs.report_id,
                })
          ),
      })
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} display='flex' justifyContent='center'>
        <Box sx={{ minWidth: 600 }}>
          <Stack
            direction='row'
            ml={1}
            spacing={2}
            justifyContent='flex-start'
            alignItems='center'
            divider={<Divider orientation='vertical' flexItem />}>
            <SelectOrganization
              selectedOrganization={selectedOrganization}
              setSelectedOrganization={setSelectedOrganization}
              showPortfolios={false}
            />
            <SelectReportTemplate
              selectedTemplate={selectedTemplate}
              setSelectedTemplate={setSelectedTemplate}
            />
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Card raised>
          <CardContent sx={{ p: 1, pt: 0, pb: '4px !important' }}>
            <BaseTable
              rows={relations}
              columns={columns}
              editColumn={<TableEditColumn width={5} />}>
              <CheckBoxTypeProvider
                for={['subscribed']}
                handleClick={handleClick}
              />
              <OrgSiteIdNameLinkTypeProvider for={['device_id']} />
              <ReportTypeProvider for={['report_id']} />

              <EditingState />

              <SortingState />
              <IntegratedSorting
                columnExtensions={integratedSortingColumnExtensions}
              />

              <PagingState defaultCurrentPage={0} pageSize={10} />
              <IntegratedPaging />
            </BaseTable>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ReportSubscriptionsTable;
