import React from 'react';
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';

function ChartHeader({ title, action, sx }) {
  const styles = sx || {
    '& .MuiCardHeader-content': { display: 'flex' },
    p: 1,
  };
  return (
    <Grid item xs={12}>
      <Card raised>
        <CardHeader sx={styles} title={title} action={action} />
      </Card>
    </Grid>
  );
}

ChartHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  action: PropTypes.object,
  sx: PropTypes.object,
};

export default ChartHeader;
