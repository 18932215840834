import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import find from 'lodash/find';
import get from 'lodash/get';

import List from '@mui/material/List';

import { TextFieldListItem } from '../../List/TextFieldListItem';
import useOrganization from '../../../store/hooks/useOrganization';
import usePortfolioMembers from '../../../store/hooks/usePortfolioMembers';

function DeleteForm(props) {
  const { selectedOrg, setSelectedOrg } = props;
  const { id } = useSelector((state) => state.pages.portfolio);
  const { id: memberId } = useSelector(
    (state) => state.dialogs.portfolio_membership
  );

  const portfolio = useOrganization(id);
  const { portfolioMembers } = usePortfolioMembers(id);

  useEffect(() => {
    if (memberId && portfolioMembers) {
      setSelectedOrg(find(portfolioMembers, { org_id: memberId }));
    }
  }, [memberId, portfolioMembers, setSelectedOrg]);

  return (
    <List disablePadding>
      <TextFieldListItem
        label='Portfolio'
        value={get(portfolio, 'name', '')}
        InputProps={{
          readOnly: true,
          disableUnderline: true,
        }}
      />
      <TextFieldListItem
        label='Child Organization'
        value={get(selectedOrg, 'name', '')}
        InputProps={{
          readOnly: true,
          disableUnderline: true,
        }}
      />
    </List>
  );
}

export default DeleteForm;
