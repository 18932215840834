import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';

import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';

import {
  closeDialog,
  openConfirmDialog,
  openDialog,
} from '../../../store/dialogs';

import Form from './Form';
import DialogTitleOptions from '../DialogTitleOptions';
import useVerifyOrgRole from '../../../store/hooks/useVerifyOrgRole';
import {
  deactivateReport,
  deleteUserReport,
  postReport,
  postUserReport,
  putReport,
} from '../../../store/reports/_reports';

const defaultState = {
  name: '',
  report_id: '',
  device_id: '',
  day: 1,
  period: 'month',
  optional: [],
};
function ReportDialog() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    id,
    mode,
    template: _template,
    resource,
  } = useSelector((state) => state.dialogs.report);
  const {
    data: reports,
    templates,
    userReports,
  } = useSelector((state) => state.reports);

  const [report, setReport] = useState(defaultState);
  const [template, setTemplate] = useState({});
  const [userReport, setUserReport] = useState({});
  const isOrgAdmin = useVerifyOrgRole(report?.org_id, 0);

  useEffect(() => {
    if (id && reports) {
      const _report = find(reports, { report_id: id });
      setTemplate(find(templates, { title: _report.name }));
      setReport(_report);
    } else {
      setReport(defaultState);
      setTemplate(_template);
    }
  }, [id, reports, templates, _template]);

  useEffect(() => {
    setUserReport(find(userReports, { report_id: report.report_id }) || {});
  }, [userReports, report]);

  const handleClose = () => {
    dispatch(closeDialog('report'));
    setReport(defaultState);
  };

  const handleSubscribe = () => {
    if (isEmpty(userReport)) {
      dispatch(postUserReport(report));
    } else {
      dispatch(deleteUserReport(userReport));
    }
    handleClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    switch (mode) {
      case 'create':
        const deviceId = get(resource, `${resource.type_}_id`);
        let newReport = {
          org_id: resource.org_id,
          name: template.title,
          period: report.period,
          day: Number(report.day),
          optional: report.optional,
          device_id: deviceId,
          equipment: report.equipment,
          contact: report.contact,
        };
        dispatch(postReport(newReport));
        break;

      case 'edit':
        dispatch(putReport({ ...report, day: Number(report.day) }));
        break;

      default:
        break;
    }
    handleClose();
  };

  const handleDelete = () => {
    dispatch(
      openConfirmDialog({
        title: 'Deactivate Report',
        message: 'Are you sure you want to deactivate this report?',
        onConfirm: () => {
          dispatch(deactivateReport(report));
          handleClose();
        },
      })
    );
  };

  const handleOpenGenerateReportDialog = () => {
    dispatch(
      openDialog({ type: 'generateReport', reportId: report.report_id })
    );
  };

  return (
    <Dialog open={id !== ''} onClose={handleClose} fullScreen={fullScreen}>
      <form onSubmit={handleSubmit} style={theme.dialog.form}>
        <DialogTitle sx={{ p: 1, pl: 2, pb: 0 }}>
          {startCase(mode) + ' Report'}
          <DialogTitleOptions mode={mode} handleClose={handleClose}>
            {isOrgAdmin && mode === 'edit' ? (
              <MenuItem onClick={handleDelete}>Deactivate</MenuItem>
            ) : null}
            {mode !== 'view' ? (
              <MenuItem onClick={handleSubscribe}>
                {!isEmpty(userReport) ? 'Unsubscribe' : 'Subscribe'}
              </MenuItem>
            ) : null}
            {isOrgAdmin && mode === 'edit' ? (
              <MenuItem onClick={handleOpenGenerateReportDialog}>
                Generate
              </MenuItem>
            ) : null}
          </DialogTitleOptions>
        </DialogTitle>
        <DialogContent
          sx={{
            overflow: 'inherit',
            p: 2,
            pt: 0,
            pb: 0,
          }}>
          <Form
            template={template}
            report={report}
            mode={mode}
            setReport={setReport}
            handleClose={handleClose}
          />
        </DialogContent>
        <DialogActions sx={{ p: '4px' }}>
          <Button onClick={handleClose}>Cancel</Button>
          {mode !== 'view' && (
            <Button
              type='submit'
              onClick={handleSubmit}
              color='primary'
              variant='contained'
              disabled={!isOrgAdmin}>
              {(() => {
                switch (mode) {
                  case 'create':
                    return 'Create';
                  case 'edit':
                    return 'Update';
                  default:
                    return null;
                }
              })()}
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default ReportDialog;
