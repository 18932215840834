import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import useTheme from '@mui/material/styles/useTheme';
import styled from '@mui/material/styles/styled';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Icon from '@mui/material/Icon';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import WidgetLoader from '../../../../components/widgets/Loader';
import { dateStringToDayJS } from '../../../../helpers/dates';

const BottomContent = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginTop: '30px',
  marginBottom: '4px',
}));

const BottomPanel = styled('div')(({ theme }) => ({
  height: 10,
  width: '100%',
  bottom: 0,
  backgroundColor: theme.palette.veregy_colors.grey,
}));

function MeterInfo(props) {
  const { logger, meter } = props;
  const theme = useTheme();

  let lastOk = dateStringToDayJS(get(logger, 'stats.last_ok_time'));
  let lastFail = dateStringToDayJS(get(logger, 'stats.last_fail_time'));

  const online = get(logger, 'online', false);
  const color = (() => {
    if (!isEmpty(meter)) {
      if (online) {
        return theme.palette.veregy_colors.green;
      } else return theme.palette.veregy_colors.red;
    } else return theme.palette.veregy_colors.grey;
  })();

  const lastUpdate = (() => {
    if (lastOk.isValid() || lastFail.isValid()) {
      return (online ? lastOk : lastFail).format('MM/DD/YYYY HH:mm z');
    }
    return '';
  })();

  return (
    <Card raised>
      <CardContent sx={{ p: 2, pb: '0px !important' }}>
        <TextField
          id='last-update'
          label={`Last ${online ? 'OK' : 'Fail'} Time`}
          value={lastUpdate}
          fullWidth
          sx={{ marginBottom: '64px' }}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
            fullWidth: true,
          }}
          variant='standard'
        />
        <BottomContent>
          <Tooltip
            title={get(logger, 'online', false) ? 'online' : 'offline'}
            placement='left'>
            <Icon
              sx={{
                color,
                mr: 1,
                fontSize: '1.5rem',
                height: 'inherit',
                width: 'inherit',
              }}>
              <div
                style={{
                  backgroundColor: color,
                  borderRadius: '50%',
                  width: '1.3rem',
                  height: '1.3rem',
                }}
              />
            </Icon>
          </Tooltip>
          <Typography align='left' variant='h5'>
            {meter?.name}
          </Typography>
          {isEmpty(meter) ? <WidgetLoader /> : null}
        </BottomContent>
      </CardContent>

      <BottomPanel />
    </Card>
  );
}

MeterInfo.propTypes = {
  logger: PropTypes.object,
  meter: PropTypes.object,
};

export default MeterInfo;
