import { createSlice } from '@reduxjs/toolkit';
import { buildAsyncReducers } from '../thunkTemplate';
import { user as initialState } from '../initialState';
import get from 'lodash/get';

import {
  authenticateUser,
  logoutUser,
  createSubscriptions,
  deleteSubscriptions,
} from './_users';

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { reducer, actions } = createSlice({
  name: 'user',
  initialState,
  reducers: {
    toggleThemeMode: (state) => {
      const item = { ...state.item };

      const currentMode = get(item, 'theme_mode', 'light');
      state.item = {
        ...item,
        theme_mode: currentMode === 'light' ? 'dark' : 'light',
      };
      return state;
    },
    setUser: (state, action) => {
      state.item = action.payload;
      return state;
    },
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [
      authenticateUser,
      logoutUser,
      createSubscriptions,
      deleteSubscriptions,
    ]);
  },
});

const { toggleThemeMode, setUser } = actions;

// Export the reducer, either as a default or named export
export {
  toggleThemeMode,
  authenticateUser,
  logoutUser,
  setUser,
  createSubscriptions,
  deleteSubscriptions,
};
export default reducer;
