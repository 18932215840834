import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import filter from 'lodash/filter';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import ComponentLoader from '../../../../components/Loaders/ComponentLoader';
import BaseTable from '../../../../components/table/tables/BaseTable';
import {
  CheckBoxTypeProvider,
  DateTypeProvider,
} from '../../../../components/table/providers';
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';

export default function LicensesConfig() {
  const { id } = useSelector((state) => state.pages.organization);
  const { data: allLicenses, loading } = useSelector((state) => state.licenses);

  const [licenses, setLicenses] = useState([]);

  const columns = [
    { title: 'License', name: 'name', width: 0.15 },
    { title: 'Active', name: 'active', width: 0.15 },
    { title: 'Force Active', name: 'force_active', width: 0.15 },
    { title: 'End Date', name: 'end_date', width: 0.25 },
  ];

  useEffect(() => {
    let _licenses = [];
    if (id) {
      _licenses = filter(allLicenses, { org_id: id });
    }
    setLicenses(_licenses);
  }, [id, allLicenses]);

  if (loading) {
    return <ComponentLoader />;
  }

  return (
    <Card raised>
      <CardContent sx={{ p: 1, pt: 0, pb: '4px !important' }}>
        <BaseTable rows={licenses} columns={columns}>
          <CheckBoxTypeProvider for={['active', 'force_active']} />
          <DateTypeProvider for={['end_date']} />

          <FilteringState />
          <SortingState />
          <PagingState defaultCurrentPage={0} pageSize={10} />

          <IntegratedFiltering />
          <IntegratedSorting />
          <IntegratedPaging />
        </BaseTable>
      </CardContent>
    </Card>
  );
}
