import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import startCase from 'lodash/startCase';

import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';

import { closeDialog } from '../../store/dialogs';

import { ALARM_LEVELS } from '../table/providers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DialogTitleOptions from './DialogTitleOptions';
import useTrigger from '../../store/hooks/useTrigger';

function TriggerDialog() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { id } = useSelector((state) => state.dialogs.trigger);
  const trigger = useTrigger(id);

  const handleClose = () => {
    dispatch(closeDialog('trigger'));
  };

  return (
    <Dialog open={id !== ''} onClose={handleClose} fullScreen={fullScreen}>
      <DialogTitle sx={{ p: 1, pl: 2, pb: 1 }}>
        {startCase(trigger.trigger_id) + ' Trigger'}
        <DialogTitleOptions mode='view' handleClose={handleClose} />
      </DialogTitle>
      <DialogContent
        sx={{
          minWidth: theme.dialog.form.minWidth,
          overflow: 'inherit',
          p: 2,
          pt: 0,
          pb: 0,
        }}>
        <List disablePadding>
          <ListItem sx={{ pl: 0, pr: 0, pt: 1, pb: 1 }}>
            <TextField
              id='device_type'
              label='Device Type'
              value={startCase(trigger.device_type)}
              fullWidth
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
              variant='standard'
            />
          </ListItem>
          <ListItem sx={{ pl: 0, pr: 0, pt: 1, pb: 1 }}>
            <TextField
              id='level'
              label='Severity'
              value={ALARM_LEVELS[trigger.level]}
              fullWidth
              InputProps={{
                readOnly: true,
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position='start'>
                    <FontAwesomeIcon
                      icon={['fal', `signal-${trigger.level + 1}`]}
                    />
                  </InputAdornment>
                ),
              }}
              variant='standard'
            />
          </ListItem>
          <ListItem sx={{ pl: 0, pr: 0, pt: 1, pb: 1 }}>
            <TextField
              id='message'
              label='Description'
              value={trigger.message}
              fullWidth
              multiline
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
              variant='standard'
            />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions sx={{ p: '4px' }}>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

export default TriggerDialog;
