import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';

import {
  FilteringState,
  IntegratedPaging,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { closeDialog, openDialog } from '../../store/dialogs';
import { getSubscribedUsers } from '../../store/admin';
import useDevice from '../../store/hooks/useDevice';
import useTrigger from '../../store/hooks/useTrigger';
import DialogTitleOptions from './DialogTitleOptions';
import BaseTable from '../table/tables/BaseTable';
import ComponentLoader from '../Loaders/ComponentLoader';
import { TextFieldListItem } from '../List/TextFieldListItem';

function SubscribedUsersDialog() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { triggerDevice } = useSelector((state) => state.dialogs.subscribers);
  const subscribedUsers = useSelector((state) => state.admin.subscribedUsers);

  const device = useDevice(triggerDevice.device_id);
  const trigger = useTrigger(triggerDevice.trigger_id);

  const [loading, setLoading] = useState(false);
  const [relation, setRelation] = useState({});

  const [columns] = useState([
    { title: 'Name', name: 'name', width: 0.45 },
    { title: 'Email', name: 'email', width: 0.45 },
  ]);

  useEffect(() => {
    if (!isEmpty(triggerDevice)) {
      setLoading(true);
      setRelation(triggerDevice);
    }
  }, [triggerDevice]);

  useEffect(() => {
    if (!isEmpty(relation)) {
      dispatch(getSubscribedUsers(relation)).finally(() => setLoading(false));
    }
  }, [relation, dispatch]);

  const handleClose = () => {
    dispatch(closeDialog('subscribers'));
    setRelation({});
  };

  return (
    <Dialog
      open={!isEmpty(relation)}
      onClose={handleClose}
      fullScreen={fullScreen}>
      <DialogTitle sx={{ p: 1, pl: 2, pb: 0 }}>
        Subscribed Users
        <DialogTitleOptions handleClose={handleClose} />
      </DialogTitle>
      <DialogContent
        sx={{
          minHeight: 50,
          minWidth: theme.dialog.form.minWidth,
          p: 2,
          pt: 0,
          pb: 0,
        }}>
        <List disablePadding>
          <ListItem sx={{ pl: 0, pr: 0, pt: 1, pb: 0 }}>
            <Link
              underline='hover'
              sx={{ color: theme.palette.veregy_colors.blue }}
              onClick={() => {
                handleClose();
                dispatch(
                  openDialog({
                    type: 'trigger',
                    id: trigger.trigger_id || '',
                  })
                );
              }}>
              {startCase(get(trigger, 'trigger_id')) + ' Trigger'}
            </Link>
          </ListItem>
          <TextFieldListItem
            label='Device'
            value={device.name || ''}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
              fullWidth: true,
            }}
          />
        </List>

        {loading ? (
          <div
            style={{
              height: '50px',
            }}>
            <ComponentLoader height='35px' width='35px' />
          </div>
        ) : (
          <BaseTable rows={subscribedUsers} columns={columns}>
            <FilteringState />
            <SortingState
              defaultSorting={[{ columnName: 'name', direction: 'asc' }]}
            />
            <PagingState defaultCurrentPage={0} pageSize={10} />
            <IntegratedPaging />
          </BaseTable>
        )}
      </DialogContent>
      <DialogActions sx={{ p: '4px' }}>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

export default SubscribedUsersDialog;
