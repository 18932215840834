import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import get from 'lodash/get';

import { displaykW } from '../../../../helpers/display-energy';
import BaseTooltip from '../../tooltips/BaseTooltip';

function Tooltip({ active, payload, label, timezone, meter }) {
  return (
    <BaseTooltip
      active={active}
      payload={payload}
      label={dayjs(Number(label) * 1000)
        .utcOffset(get(timezone, 'offset', 0))
        .format('MM/DD h:mm A')}
      getName={() => meter?.name}
      getValue={(resource) => displaykW(resource?.value)}
    />
  );
}

Tooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  label: PropTypes.number,
  timezone: PropTypes.object,
  meters: PropTypes.array,
};

export default Tooltip;
