import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

function lastMonth(offset = 0) {
  // adjust start and end of previous month with offset
  let start = dayjs
    .utc()
    .subtract(1, 'month')
    .startOf('month')
    .subtract(offset, 'hour')
    .valueOf();
  let end = dayjs
    .utc()
    .subtract(1, 'month')
    .endOf('month')
    .subtract(offset, 'hour')
    .valueOf();

  return {
    key: 0,
    label: 'Last Month',
    start,
    end,
  };
}

function thisMonth(offset = 0) {
  // adjust start and end current Month with offset
  let start = dayjs.utc().startOf('month').subtract(offset, 'hour').valueOf();
  let end = dayjs.utc().endOf('month').subtract(offset, 'hour').valueOf();

  return {
    key: 1,
    label: 'This Month',
    start,
    end,
  };
}

function lastWeek(offset = 0) {
  // adjust start and end of previous week with offset
  let start = dayjs
    .utc()
    .subtract(1, 'week')
    .startOf('week')
    .subtract(offset, 'hour')
    .valueOf();
  let end = dayjs
    .utc()
    .subtract(1, 'week')
    .endOf('week')
    .subtract(offset, 'hour')
    .valueOf();

  return {
    key: 2,
    label: 'Last Week',
    start,
    end,
  };
}

function thisWeek(offset = 0) {
  // adjust start and end of current week with offset
  let start = dayjs.utc().startOf('week').subtract(offset, 'hour').valueOf();
  let end = dayjs.utc().endOf('week').subtract(offset, 'hour').valueOf();

  return {
    key: 3,
    label: 'This Week',
    start,
    end,
  };
}

function yesterday(offset = 0) {
  // adjust start and end of previous day with offset
  let start = dayjs
    .utc()
    .subtract(1, 'day')
    .startOf('day')
    .subtract(offset, 'hour')
    .valueOf();
  let end = dayjs
    .utc()
    .subtract(1, 'day')
    .endOf('day')
    .subtract(offset, 'hour')
    .valueOf();

  return {
    key: 4,
    label: 'Yesterday',
    start,
    end,
  };
}

function today(offset = 0) {
  // utc start and end of day
  let start = dayjs.utc().startOf('day').subtract(offset, 'hour').valueOf();
  let end = dayjs.utc().endOf('day').subtract(offset, 'hour').valueOf();

  return {
    key: 5,
    label: 'Today',
    start,
    end,
  };
}

const generateRanges = (allowCustomRange, offset) => {
  let ranges = [today, yesterday, thisWeek, lastWeek, thisMonth, lastMonth].map(
    (f) => f(offset)
  );

  if (allowCustomRange === true)
    ranges.push({
      key: 6,
      label: 'Custom Range',
      start: dayjs.utc().startOf('month').subtract(offset, 'hour').valueOf(),
      end: dayjs.utc().endOf('day').subtract(offset, 'hour').valueOf(),
    });

  return ranges;
};

function dayRange(dayObject, offset = 0) {
  let start = dayObject.startOf('day').subtract(offset, 'hour').valueOf();
  let end = dayObject.endOf('day').subtract(offset, 'hour').valueOf();
  return { start, end };
}

const getLatestInterval = () => {
  let now = dayjs();
  return now
    .minute(now.minute() - (now.minute() % 15))
    .second(0)
    .millisecond(0);
};

const dateStringToDayJS = (dateString) => {
  return dayjs(dateString?.substring(0, 19) + dateString?.slice(-6));
};

const dateStringToHumanReadable = (dateString) => {
  return dateStringToDayJS(dateString).format('MMM D, YYYY h:mm A');
};

export {
  yesterday,
  today,
  lastWeek,
  thisMonth,
  lastMonth,
  thisWeek,
  generateRanges,
  dayRange,
  getLatestInterval,
  dateStringToDayJS,
  dateStringToHumanReadable,
};
