import axios from '../xhr_libs/axios';
import { processResponse } from '.';

export const getPortfolioMembershipsAPI = async () => {
  return processResponse(await axios.get(`/resource/portfolio_memberships`));
};

export const postPortfolioMembershipAPI = async (portfolioId, childIds) => {
  return processResponse(
    await axios.post(`/resource/portfolio_memberships/${portfolioId}`, childIds)
  );
};

export const deletePortfolioMembershipAPI = async (portfolio_id, child_id) => {
  return processResponse(
    await axios.delete(
      `/resource/portfolio_memberships/${portfolio_id}/${child_id}`
    )
  );
};
