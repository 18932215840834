import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MuiGrid from '@mui/material/Grid';

import {
  SortingState,
  IntegratedSorting,
  IntegratedFiltering,
  IntegratedPaging,
  EditingState,
  FilteringState,
  PagingState,
} from '@devexpress/dx-react-grid';
import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';

import useVerifyOrgRole from '../../../store/hooks/useVerifyOrgRole';
import { openDialog } from '../../../store/dialogs';
import BaseTable from './BaseTable';
import { DateTypeProvider, DeviceTypeProvider } from '../providers';
import { EditCell, HeaderEditCell } from '../cells';

function SitesTable({ sites, orgId }) {
  const dispatch = useDispatch();
  const isOrgAdmin = useVerifyOrgRole(orgId, 0);
  const isOrgEditor = useVerifyOrgRole(orgId, 1);

  const { data: allSites } = useSelector((state) => state.sites);
  const [tableData, setTableData] = useState([]);
  const columns = [
    { title: 'Site', name: 'site_id', width: 0.25 },
    { title: 'Address', name: 'address', width: 0.25 },
    { title: 'Install Date', name: 'install_date', width: 0.25 },
  ];

  useEffect(() => {
    if (sites) {
      setTableData(sites);
    } else {
      setTableData(allSites);
    }
  }, [sites, allSites]);

  const handleEditClick = (site) => {
    dispatch(
      openDialog({
        mode: 'edit',
        type: 'site',
        id: site.site_id,
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      openDialog({
        orgId: orgId,
        type: 'site',
        mode: 'create',
      })
    );
  };

  return (
    <MuiGrid item xs={12}>
      <Card raised>
        <CardContent sx={{ p: 1, pt: 0, pb: '4px !important' }}>
          <BaseTable
            rows={tableData}
            columns={columns}
            editColumn={
              <TableEditColumn
                width={42}
                showEditCommand
                cellComponent={(props) => (
                  <EditCell
                    disabled={!isOrgEditor}
                    tooltipText='Edit Site'
                    // eslint-disable-next-line
                    handleClick={() => handleEditClick(props.row)}
                    {...props}
                  />
                )}
                headerCellComponent={(props) => {
                  return (
                    <HeaderEditCell
                      disabled={!isOrgAdmin}
                      tooltipText='Create Site'
                      handleClick={handleCreateClick}
                      {...props}
                    />
                  );
                }}
              />
            }>
            <DeviceTypeProvider for={['site_id']} />
            <DateTypeProvider for={['install_date']} />

            <EditingState />
            <FilteringState />
            <SortingState
              defaultSorting={[{ columnName: 'name', direction: 'asc' }]}
            />
            <PagingState defaultCurrentPage={0} pageSize={10} />

            <IntegratedFiltering />
            <IntegratedSorting />
            <IntegratedPaging />
          </BaseTable>
        </CardContent>
      </Card>
    </MuiGrid>
  );
}

SitesTable.propTypes = {
  sites: PropTypes.array,
  orgId: PropTypes.string,
};

export default SitesTable;
