import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import each from 'lodash/each';
import findIndex from 'lodash/findIndex';
import range from 'lodash/range';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';

import useTheme from '@mui/material/styles/useTheme';
import {
  Area,
  Bar,
  ComposedChart,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { navigate } from '../../../../store/pages';
import useMeter from '../../../../store/hooks/useMeter';
import useSite from '../../../../store/hooks/useSite';
import ChartTooltip from './Tooltip';
import { addEstimatedProductionToChartData } from '../../../../helpers/chart-data';

function MeterProfileChart({ meterId }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [data, setData] = useState([]);
  const meter = useMeter(meterId);
  const site = useSite(meter.site_id);

  const timezoneOffset = get(site, 'timezone.offset', 0);
  const start = dayjs()
    .utcOffset(timezoneOffset)
    .startOf('day')
    .subtract(1, 'day');
  const end = start.utcOffset(timezoneOffset).add(2, 'day');

  let startReference = start.add(3, 'hour');
  let endReference = start.add(27, 'hour');

  useEffect(() => {
    if (!isEmpty(meter)) {
      let _data = map(range(start.unix(), end.unix(), 900), (timestamp) => ({
        timestamp,
      }));

      each(meter.timeseries, (datapoint) => {
        let idx = findIndex(_data, { timestamp: datapoint.timestamp });
        if (idx >= 0) {
          _data[idx] = {
            ..._data[idx],
            [meter.meter_id]: datapoint.value,
          };
        }
      });

      setData(
        addEstimatedProductionToChartData(
          _data,
          [meter],
          site?.timezone?.offset
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site?.timezone?.offset, meter]);

  const handleClick = () => {
    dispatch(
      navigate({
        page: 'meter',
        id: meterId,
        tab: 'analysis',
      })
    );
  };
  return (
    <ResponsiveContainer width='100%' height={175}>
      <ComposedChart
        onClick={handleClick}
        width='100%'
        height={175}
        data={data}
        margin={{
          top: 0,
          right: 10,
          left: 10,
          bottom: 0,
        }}>
        <YAxis hide domain={[0, (dataMax) => Math.ceil(dataMax / 10) * 10]} />
        <XAxis
          type='number'
          dataKey='timestamp'
          domain={[start.unix(), end.unix()]}
          hide
        />
        <ReferenceLine
          x={startReference.unix()}
          stroke={'transparent'}
          label={
            <Label position='insideTopLeft'>
              {startReference.format('MMM Do')}
            </Label>
          }
        />
        <ReferenceLine
          x={endReference.unix()}
          stroke={'transparent'}
          label={
            <Label position='insideTopLeft'>
              {endReference.format('MMM Do')}
            </Label>
          }
        />
        <Tooltip
          content={<ChartTooltip timezone={site.timezone} meter={meter} />}
        />
        <Area
          key={`area-${meter.meter_id}`}
          type='monotone'
          dataKey={meter.meter_id}
          fill={theme.palette.veregy_colors.blue}
        />
        <Bar
          key={`bar-${meter.meter_id}`}
          dataKey={`${meter.meter_id}:estimated`}
          fill={theme.palette.veregy_colors.lightgrey}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

MeterProfileChart.propTypes = {
  meterId: PropTypes.string,
};

export default MeterProfileChart;
