import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toastr } from 'react-redux-toastr';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import get from 'lodash/get';
import remove from 'lodash/remove';
import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import sortBy from 'lodash/sortBy';

import { buildAsyncReducers } from '../thunkTemplate';
import { admin as initialState } from '../initialState';
import {
  putUserAPI,
  postUserAPI,
  deleteUserAPI,
  resendAccountCreationEmailAPI,
  deletePortfolioMembershipAPI,
  generateAPIKeyAPI,
  getSubscribedUsersAPI,
  getUsersAPI,
  postPortfolioMembershipAPI,
} from '../../api';
import { setUser } from '../user';
import { getOrganizations } from '../organizations/_organizations';
import { updateNodes } from '../nodes';

const getAdminUsers = createAsyncThunk(
  'admin/getAdminUsers',
  async (user, { getState, requestId, dispatch }) => {
    try {
      const { currentRequestId, loading } = getState().admin;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());

      // Determine what to do with the user
      if (get(user, 'super_user')) {
        let { users, memberships } = await getUsersAPI(user);
        return { users, memberships };
      }

      return {};
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const getSubscribedUsers = createAsyncThunk(
  'admin/getSubscribedUsers',
  async (triggerDevice, { getState, requestId, dispatch }) => {
    try {
      const { currentRequestId, loading } = getState().admin;
      const { item: user } = getState().user;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }
      dispatch(showLoading());

      if (get(user, 'super_user', false)) {
        let users = await getSubscribedUsersAPI(triggerDevice);
        return { subscribedUsers: users };
      }

      return {};
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const putUser = createAsyncThunk(
  'admin/putUser',
  async (item, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading, users } = getState().admin;
      const { item: user } = getState().user;
      const super_user = get(user, 'super_user', false);

      // User can update their user or be a super user to update actual user data
      if (
        (!super_user && item.user_id !== user.user_id) ||
        loading !== true ||
        requestId !== currentRequestId
      ) {
        return;
      }
      dispatch(showLoading());
      let updatedUser = await putUserAPI(item.user_id, item);
      if (user.user_id === item.user_id) dispatch(setUser(updatedUser));
      let _users = cloneDeep(users);
      remove(_users, { user_id: get(updatedUser, 'user_id') });

      toastr.success('User updated', get(updatedUser, 'email'));
      return { users: sortBy(concat(_users, updatedUser), 'name') };
    } catch (err) {
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

const generateAPIKey = createAsyncThunk(
  'admin/generateAPIKey',
  async (user_id, { dispatch, getState, requestId }) => {
    let updatedUser = {};
    try {
      const { currentRequestId, loading, users } = getState().admin;
      const { item: user } = getState().user;
      const super_user = get(user, 'super_user', false);

      // User can update their user or be a super user to update actual user data
      if (!super_user || loading !== true || requestId !== currentRequestId) {
        return;
      }
      dispatch(showLoading());
      updatedUser = await generateAPIKeyAPI(user_id);
      let _users = cloneDeep(users);
      remove(_users, { user_id: get(updatedUser, 'user_id') });

      toastr.success('API Key Generated', get(updatedUser, 'email'));
      return { users: concat(_users, updatedUser) };
    } catch (err) {
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

const postUser = createAsyncThunk(
  'admin/postUser',
  async (item, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading, users } = getState().admin;
      const { item: user } = getState().user;
      const super_user = get(user, 'super_user', false);

      if (!super_user || loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      let newUser = await postUserAPI(item);

      toastr.success('User created', get(item, 'email'));
      return { users: concat(users, newUser) };
    } catch (err) {
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deleteUser = createAsyncThunk(
  'admin/deleteUser',
  async (user_id, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading, users } = getState().admin;
      const { item: user } = getState().user;
      const super_user = get(user, 'super_user', false);

      if (!super_user || loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      let deletedUserId = await deleteUserAPI(user_id);
      let _users = cloneDeep(users);
      remove(_users, { user_id: deletedUserId });

      toastr.success('User deleted');
      return { users: _users };
    } catch (err) {
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

const resendAccountCreationEmail = createAsyncThunk(
  'admin/resendAccountCreationEmail',
  async (data, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading } = getState().admin;
      const { item: user } = getState().user;
      const super_user = get(user, 'super_user', false);

      if (!super_user || loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      await resendAccountCreationEmailAPI(data);
      toastr.success('Verification email resent', get(data, 'email'));
    } catch (err) {
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

const postPortfolioMemberships = createAsyncThunk(
  'organization/postPortfolioMemberships',
  async ({ portfolioId, childOrgIds }, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading } = getState().admin;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      await postPortfolioMembershipAPI(portfolioId, childOrgIds);

      const message = childOrgIds.length > 1 ? 'Organizations' : 'Organization';
      toastr.success(`${message} added to Portfolio`);

      const {
        payload: { data: organizations, portfolioMemberships },
      } = await dispatch(getOrganizations());

      dispatch(
        updateNodes({
          organizations,
          portfolioMemberships,
        })
      );

      return;
    } catch (err) {
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deletePortfolioMembership = createAsyncThunk(
  'organization/deletePortfolioMembership',
  async ({ portfolioId, childOrgId }, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading } = getState().admin;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      await deletePortfolioMembershipAPI(portfolioId, childOrgId);
      toastr.success('Organization removed from Portfolio');

      const {
        payload: { data: organizations, portfolioMemberships },
      } = await dispatch(getOrganizations());

      dispatch(
        updateNodes({
          organizations,
          portfolioMemberships,
        })
      );

      return;
    } catch (err) {
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { reducer } = createSlice({
  name: 'admin',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [
      getAdminUsers,
      getSubscribedUsers,
      putUser,
      postUser,
      deleteUser,
      resendAccountCreationEmail,
      generateAPIKey,
      postPortfolioMemberships,
      deletePortfolioMembership,
    ]);
  },
});

// Export the reducer, either as a default or named export
export {
  getAdminUsers,
  getSubscribedUsers,
  putUser,
  postUser,
  deleteUser,
  resendAccountCreationEmail,
  generateAPIKey,
  postPortfolioMemberships,
  deletePortfolioMembership,
};
export default reducer;
