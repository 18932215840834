import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import BaseTable from '../../table/tables/BaseTable';

function CreateTable(props) {
  const { childOrgs, selection, setSelection } = props;
  let columns = [{ title: 'Organization', name: 'name' }];
  const [sorting, setSorting] = useState([{ columnName: 'name' }]);

  return (
    <BaseTable rows={childOrgs} columns={columns} selection>
      <SelectionState selection={selection} onSelectionChange={setSelection} />
      <FilteringState />
      <SortingState sorting={sorting} onSortingChange={setSorting} />
      <PagingState defaultCurrentPage={0} pageSize={10} />
      <IntegratedFiltering />
      <IntegratedSorting />
      <IntegratedSelection />
      <IntegratedPaging />
    </BaseTable>
  );
}

CreateTable.propTypes = {
  childOrgs: PropTypes.array,
  selection: PropTypes.array,
  setSelection: PropTypes.func,
};

export default CreateTable;
