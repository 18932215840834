import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import startCase from 'lodash/startCase';

import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';

import { closeDialog, openConfirmDialog } from '../../../store/dialogs';
import useVerifyOrgRole from '../../../store/hooks/useVerifyOrgRole';
import { navigate } from '../../../store/pages';
import {
  deleteMeter,
  postMeter,
  putMeter,
} from '../../../store/meters/_meters';
import useMeter from '../../../store/hooks/useMeter';
import useSite from '../../../store/hooks/useSite';
import useLogger from '../../../store/hooks/useLogger';
import Form from './Form';
import DialogTitleOptions from '../DialogTitleOptions';

const defaultState = {
  org_id: '',
  meter_id: '',
  name: '',
  parent_index: '',
  logger_id: '',
  site_id: '',
};

function MeterDialog() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { id, mode, siteId, parentIndex, loggerId } = useSelector(
    (state) => state.dialogs.meter
  );
  const meterItem = useMeter(id);
  const site = useSite(siteId || meterItem.site_id);
  const logger = useLogger(loggerId || meterItem.logger_id);

  const isOrgAdmin = useVerifyOrgRole(meterItem?.org_id, 0);
  const isOrgEditor = useVerifyOrgRole(meterItem?.org_id, 1);

  const [meter, setMeter] = useState(defaultState);

  useEffect(() => {
    if (meterItem.meter_id) {
      setMeter({ ...meterItem });
    } else if (site.site_id) {
      setMeter({
        ...meterItem,
        org_id: site?.org_id,
        site_id: site?.site_id,
        parent_index: parentIndex ? parentIndex : 1,
        logger_id: loggerId ? loggerId : '',
      });
    }
  }, [site, id, meterItem, loggerId, parentIndex]);

  const handleClose = () => {
    dispatch(closeDialog('meter'));
    setMeter(defaultState);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    switch (mode) {
      case 'create':
        dispatch(
          postMeter({
            ...meter,
            org_id: site.org_id,
            site_id: site.site_id,
          })
        );
        break;

      case 'edit':
        dispatch(
          putMeter({
            org_id: meter.org_id,
            meter_id: meter.meter_id,
            name: meter.name,
          })
        );
        break;

      default:
        break;
    }

    handleClose();
  };

  const handleDelete = () => {
    handleClose();
    dispatch(
      openConfirmDialog({
        title: 'Delete Meter',
        message: 'Are you sure you want to delete this meter?',
        onConfirm: () => dispatch(deleteMeter(meter)),
      })
    );
  };

  const handleOpenConfigView = (view) => {
    dispatch(
      navigate({
        page: 'meter',
        id: meter.meter_id,
        tab: 'config',
        view,
      })
    );
    handleClose();
  };

  return (
    <Dialog open={id !== ''} onClose={handleClose} fullScreen={fullScreen}>
      <form onSubmit={handleSubmit} style={theme.dialog.form}>
        <DialogTitle sx={{ p: 1, pl: 2, pb: 0 }}>
          {startCase(mode) + ' Meter'}
          <DialogTitleOptions mode={mode} handleClose={handleClose}>
            <MenuItem onClick={() => handleOpenConfigView('alarms')}>
              Alarms
            </MenuItem>
            {isOrgAdmin && mode === 'edit' && (
              <MenuItem onClick={handleDelete}>Delete</MenuItem>
            )}
          </DialogTitleOptions>
        </DialogTitle>
        <DialogContent
          sx={{
            overflow: 'inherit',
            p: 2,
            pt: 0,
            pb: 0,
          }}>
          <Form
            site={site}
            logger={logger}
            mode={mode}
            meter={meter}
            setMeter={setMeter}
            handleClose={handleClose}
          />
        </DialogContent>
        <DialogActions sx={{ p: '4px' }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type='submit'
            onClick={handleSubmit}
            color='primary'
            variant='contained'
            disabled={
              (mode === 'edit' && !isOrgEditor) ||
              (mode === 'create' && !isOrgAdmin)
            }>
            {(() => {
              switch (mode) {
                case 'create':
                  return 'Create';
                case 'edit':
                  return 'Update';
                default:
                  return null;
              }
            })()}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default MeterDialog;
