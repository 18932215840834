import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import numeral from 'numeral';
import map from 'lodash/map';
import get from 'lodash/get';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

function GeneralTooltip({ active, payload, label, timezone }) {
  if (active && payload && payload.length) {
    return (
      <Card raised sx={{ width: '20rem' }}>
        <CardContent sx={{ p: 1, pb: '0 !important' }}>
          <List disablePadding>
            <ListItemText
              primary={dayjs(Number(label) * 1000)
                .utcOffset(timezone.offset)
                .format('MMMM Do h:mm A')}
              primaryTypographyProps={{ align: 'center' }}
              sx={{ m: 0 }}
            />
            {map(payload.slice(0, 9), (resource) => {
              return (
                <ListItem disableGutters disablePadding key={resource?.dataKey}>
                  <ListItemText
                    primary={resource.dataKey}
                    primaryTypographyProps={{ align: 'left' }}
                    sx={{
                      color: get(resource, 'color'),
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: '1',
                      WebkitBoxOrient: 'vertical',
                    }}
                  />
                  <ListItemText
                    primary={numeral(resource.value.toPrecision(4)).format(
                      '0,0.[000]'
                    )}
                    primaryTypographyProps={{ align: 'right' }}
                    sx={{ color: get(resource, 'color') }}
                  />
                </ListItem>
              );
            })}
            {payload.length > 10 && (
              <>
                <Divider />
                <ListItem disableGutters disablePadding>
                  <ListItemText
                    primary={`+${payload.length - 10} more. Click to view all`}
                    primaryTypographyProps={{ align: 'center' }}
                    sx={{ m: 0 }}
                  />
                </ListItem>
              </>
            )}
          </List>
        </CardContent>
      </Card>
    );
  }

  return null;
}

GeneralTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  label: PropTypes.number,
  timezone: PropTypes.object,
};

export default GeneralTooltip;
