import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import remove from 'lodash/remove';

import {
  EditingState,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';

import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { deleteReportFileAPI, getReportFilesAPI } from '../../api';
import { closeDialog } from '../../store/dialogs';
import useVerifyOrgRole from '../../store/hooks/useVerifyOrgRole';
import DialogTitleOptions from './DialogTitleOptions';
import BaseTable from '../table/tables/BaseTable';
import ComponentLoader from '../Loaders/ComponentLoader';
import { ReportFileDownloadTypeProvider } from '../table/providers/ReportFileDownloadTypeProvider';
import { EditCell } from '../table/cells';

function ReportFilesDialog() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { reportId } = useSelector((state) => state.dialogs.reportFiles);
  const reports = useSelector((state) => state.reports.data);

  const [report, setReport] = useState({});
  const [files, setFiles] = useState([]);
  const isOrgAdmin = useVerifyOrgRole(report?.org_id, 0);

  const [columns] = useState([
    { title: 'filename', name: 'filename', width: 1 },
  ]);

  useEffect(() => {
    if (reportId) {
      setReport(find(reports, { report_id: reportId }));
    }
  }, [reportId, reports]);

  useEffect(() => {
    async function fetchFiles(report) {
      const files = await getReportFilesAPI(report.device_id, report.period);
      setFiles(
        map(files, (filename) => ({
          filename,
          resourceId: report.device_id,
          period: report.period,
        }))
      );
    }
    if (!isEmpty(report)) {
      fetchFiles(report);
    }
  }, [report]);

  const handleClose = () => {
    dispatch(closeDialog('reportFiles'));
    setReport({});
  };

  const handleDelete = async (row) => {
    let updatedFiles = cloneDeep(files);
    dispatch(showLoading());
    await deleteReportFileAPI(row.resourceId, row.period, row.filename);
    remove(updatedFiles, row);
    setFiles(updatedFiles);
    dispatch(hideLoading());
  };

  return (
    <Dialog
      open={reportId !== ''}
      onClose={handleClose}
      fullScreen={fullScreen}>
      <DialogTitle sx={{ p: 1, pl: 2, pb: 0 }}>
        Report PDFs
        <DialogTitleOptions handleClose={handleClose} />
      </DialogTitle>
      <DialogContent
        sx={{
          minHeight: 50,
          minWidth: theme.dialog.form.minWidth,
          p: 2,
          pt: 0,
          pb: 0,
        }}>
        {files.length === 0 ? (
          <div
            style={{
              height: '300px',
              width: '500px',
            }}>
            <ComponentLoader height='50px' width='50px' />
          </div>
        ) : (
          <BaseTable
            headerRow={false}
            rows={files}
            columns={columns}
            editColumn={
              <TableEditColumn
                width={42}
                showEditCommand
                cellComponent={(props) => (
                  <EditCell
                    disabled={!isOrgAdmin}
                    tooltipText='Delete'
                    handleClick={() => handleDelete(props.row)}
                    icon={['fal', 'trash']}
                    {...props}
                  />
                )}
              />
            }>
            <ReportFileDownloadTypeProvider for={['filename']} />
            <SortingState
              defaultSorting={[{ columnName: 'filename', direction: 'asc' }]}
            />
            <PagingState defaultCurrentPage={0} pageSize={10} />
            <EditingState />
            <IntegratedSorting />
          </BaseTable>
        )}
      </DialogContent>
      <DialogActions sx={{ p: '4px' }}>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ReportFilesDialog;
