import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';
import map from 'lodash/map';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import {
  EditingState,
  FilteringState,
  PagingState,
  SortingState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
} from '@devexpress/dx-react-grid';
import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';

import useDeviceNamePredicate from '../../../store/hooks/useDeviceNamePredicate';
import useTriggerNamePredicate from '../../../store/hooks/useTriggerNamePredicate';
import useCompareDeviceName from '../../../store/hooks/useCompareDeviceName';
import useCompareTriggerName from '../../../store/hooks/useCompareTriggerName';
import { openAlarmDialog, openConfirmDialog } from '../../../store/dialogs';

import SelectOrganization from '../../selectors/SelectOrganization';
import SelectDeviceType from '../../charts/selectors/SelectDeviceType';
import BaseTable from './BaseTable';
import {
  AlarmConfigCheckBoxTypeProvider,
  AlarmLevelTypeProvider,
  DeviceTypeProvider,
  SubscribedUsersTypeProvider,
  TriggerTypeProvider,
} from '../providers';
import useAllAlarmConfig from '../../../store/hooks/useAllAlarmConfig';
import SelectAll from '../../selectors/SelectAll';
import { createSubscriptions, deleteSubscriptions } from '../../../store/user';
import {
  createTriggerDevices,
  deleteTriggerDevices,
} from '../../../store/alarms';

function AlarmsConfigurationTable(props) {
  const {
    resource,
    isOrgEditor = false,
    orgSelect = false,
    typeSelect = false,
  } = props;
  const dispatch = useDispatch();

  const deviceNamePredicate = useDeviceNamePredicate();
  const triggerNamePredicate = useTriggerNamePredicate();
  const compareDeviceName = useCompareDeviceName();
  const compareTriggerName = useCompareTriggerName();

  const user = useSelector((state) => state.user.item);
  const allDevices = useAllAlarmConfig(resource);

  const [selectedOrganization, setSelectedOrganization] = useState({});
  const [selectedType, setSelectedType] = useState('');
  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [integratedFilteringColumnExtensions] = useState([
    { columnName: 'device_id', predicate: deviceNamePredicate },
    { columnName: 'trigger_id', predicate: triggerNamePredicate },
  ]);
  const [integratedSortingColumnExtensions] = useState([
    { columnName: 'device_id', compare: compareDeviceName },
    { columnName: 'trigger_id', compare: compareTriggerName },
  ]);

  useEffect(() => {
    let _columns = [
      { title: 'Active', name: 'trigger_device_id', width: 0.1 },
      { title: 'Subscribed', name: 'subscription_id', width: 0.1 },
      { title: 'Severity', name: 'level', width: 0.1 },
      { title: 'Device', name: 'device_id', width: 0.3 },
      { title: 'Trigger', name: 'trigger_id', width: 0.2 },
    ];

    if (user?.super_user) {
      _columns.push({
        title: 'Subscribed Users',
        name: 'subscribed_users',
        width: 0.1,
      });
    }

    setColumns(_columns);
  }, [user]);

  useEffect(() => {
    let _tableData = allDevices;

    if (selectedOrganization?.org_id) {
      _tableData = filter(_tableData, { org_id: selectedOrganization.org_id });
    }
    if (selectedType) {
      _tableData = filter(_tableData, { device_type: selectedType });
    }
    setTableData(_tableData);
  }, [selectedOrganization.org_id, selectedType, allDevices]);

  const handleClick = (attrs) => {
    dispatch(openAlarmDialog(attrs.row));
  };

  const handleActivateAll = () => {
    let triggerDevices = filter(tableData, (relation) => {
      return !relation.trigger_device_id;
    });

    dispatch(
      openConfirmDialog({
        title: 'Activate All',
        message: `Are you sure you want to activate all selected alarms (${triggerDevices.length})?`,
        onConfirm: () => dispatch(createTriggerDevices(triggerDevices)),
      })
    );
  };

  const handleDeactivateAll = () => {
    let triggerDevices = filter(tableData, (relation) => {
      return relation.trigger_device_id;
    });
    dispatch(
      openConfirmDialog({
        title: 'De-activate All',
        message: `Are you sure you want to de-activate all selected alarms (${triggerDevices.length})?`,
        onConfirm: () => dispatch(deleteTriggerDevices(triggerDevices)),
      })
    );
  };

  const handleSubscribeAll = () => {
    let triggerDevices = filter(tableData, (relation) => {
      return relation.trigger_device_id && !relation.subscription_id;
    });

    const subscriptions = map(triggerDevices, (triggerDevice) => ({
      userId: user.user_id,
      orgId: triggerDevice.org_id,
      deviceId: triggerDevice.device_id,
      triggerId: triggerDevice.trigger_id,
    }));
    dispatch(
      openConfirmDialog({
        title: 'Subscribe to All',
        message: (
          <>
            <p>
              Are you sure you want to subscribe to all selected alarms (
              {triggerDevices.length})?
            </p>
            <p>Users can only subscribe to active alarms.</p>
          </>
        ),
        onConfirm: () => dispatch(createSubscriptions(subscriptions)),
      })
    );
  };

  const handleUnsubscribeAll = () => {
    let triggerDevices = filter(tableData, (relation) => {
      return relation.subscription_id && relation.trigger_device_id;
    });

    dispatch(
      openConfirmDialog({
        title: 'Unsubscribe from All',
        message: `Are you sure you want to unsubscribe from all selected alarms (${triggerDevices.length})?`,
        onConfirm: () => dispatch(deleteSubscriptions(triggerDevices)),
      })
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack direction='row' spacing={1} justifyContent='flex-start'>
          {(user?.super_user || isOrgEditor) && tableData.length > 1 && (
            <SelectAll
              label='Active'
              selectLabel='Activate All'
              deselectLabel='Deactivate All'
              attribute='trigger_device_id'
              items={tableData}
              handleSelectAll={handleActivateAll}
              handleDeselectAll={handleDeactivateAll}
            />
          )}
          {tableData.length > 1 && (
            <SelectAll
              label='Subscribed'
              selectLabel='Subscribe to All'
              deselectLabel='Unsubscribe from All'
              attribute='subscription_id'
              items={tableData}
              handleSelectAll={handleSubscribeAll}
              handleDeselectAll={handleUnsubscribeAll}
            />
          )}

          {orgSelect && (
            <SelectOrganization
              selectedOrganization={selectedOrganization}
              setSelectedOrganization={setSelectedOrganization}
              showPortfolios={false}
            />
          )}
          {typeSelect && (
            <SelectDeviceType
              selectedType={selectedType}
              setSelectedType={setSelectedType}
            />
          )}
        </Stack>
      </Grid>

      <Grid item xs={12}>
        <Card raised>
          <CardContent sx={{ p: 1, pt: 0, pb: '4px !important' }}>
            <BaseTable
              rows={tableData}
              columns={columns}
              editColumn={<TableEditColumn width={5} />}>
              <DeviceTypeProvider for={['device_id']} />
              <TriggerTypeProvider for={['trigger_id']} />
              <AlarmConfigCheckBoxTypeProvider
                for={['trigger_device_id', 'subscription_id']}
                handleClick={handleClick}
              />
              <AlarmLevelTypeProvider for={['level']} />
              <SubscribedUsersTypeProvider for={['subscribed_users']} />

              <EditingState />
              <FilteringState />
              <SortingState
                defaultSorting={[{ columnName: 'level', direction: 'desc' }]}
              />
              <PagingState defaultCurrentPage={0} pageSize={10} />

              <IntegratedFiltering
                columnExtensions={integratedFilteringColumnExtensions}
              />
              <IntegratedSorting
                columnExtensions={integratedSortingColumnExtensions}
              />
              <IntegratedPaging />
            </BaseTable>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

AlarmsConfigurationTable.propTypes = {
  resource: PropTypes.object,
  orgSelect: PropTypes.bool,
  typeSelect: PropTypes.bool,
};

export default AlarmsConfigurationTable;
