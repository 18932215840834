import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import dayjs from 'dayjs';
import get from 'lodash/get';
import find from 'lodash/find';

import BaseTooltip from '../../tooltips/BaseTooltip';

function Tooltip(props) {
  const { active, payload, label, timezone, meters, inverters, unit } = props;
  const resourceName = (resource) => {
    const deviceId = get(resource, 'dataKey');
    let device = {};
    if (deviceId.startsWith('meter')) {
      device = find(meters, { meter_id: deviceId });
    } else {
      device = find(inverters, { inverter_id: deviceId });
    }
    return device?.name;
  };

  return (
    <BaseTooltip
      active={active}
      payload={payload}
      label={dayjs(Number(label) * 1000)
        .utcOffset(get(timezone, 'offset', 0))
        .format('MM/DD h:mm A')}
      getName={resourceName}
      getValue={(resource) =>
        numeral(Math.round(resource.value * 100) / 100).format('0,0.0') +
        ' ' +
        unit
      }
    />
  );
}

Tooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  label: PropTypes.number,
  timezone: PropTypes.object,
  meters: PropTypes.array,
};

export default Tooltip;
