import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import filter from 'lodash/filter';
import find from 'lodash/find';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

function UserSelect(props) {
  const { relation, setRelation } = props;
  const { data: memberships } = useSelector((state) => state.memberships);
  const allUsers = useSelector((state) => state.admin.users);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setUsers(
      filter(allUsers, (user) => {
        return !find(memberships, {
          org_id: relation.org_id,
          user_id: user.user_id,
        });
      })
    );
  }, [allUsers, relation.org_id, memberships]);

  const handleChangeUser = (e) => {
    setRelation({ ...relation, email: e.target.value });
  };

  return (
    <ListItem sx={{ pl: 0, pr: 0, pt: 1, pb: 1 }}>
      <FormControl variant='standard' fullWidth>
        <InputLabel id='user-label'>User</InputLabel>
        <Select
          labelId='user-label'
          id='email'
          value={relation.email}
          onChange={handleChangeUser}
          label='User'
          sx={{
            '& .MuiSelect-select': {
              display: 'flex',
              alignItems: 'center',
            },
            '& .MuiListItemIcon-root': {
              minWidth: '35px',
            },
          }}
          MenuProps={{ MenuListProps: { disablePadding: true } }}>
          <MenuItem id={`empty-menu-item`} value={''} />
          {users.map((user) => (
            <MenuItem
              key={user.user_id}
              id={`${user.user_id}-menu-item`}
              value={user.email}>
              <Typography variant='inherit'>
                {user.name || user.email}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ListItem>
  );
}

export default UserSelect;
