import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Badge from '@mui/material/Badge';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';

import { navigate } from '../../../../store/pages';
import useMeter from '../../../../store/hooks/useMeter';
import useLogger from '../../../../store/hooks/useLogger';
import useDeviceAlarms from '../../../../store/hooks/useDeviceAlarms';
import MeterProfileChart from '../../../../components/charts/preview/MeterProfileChart';
import { openDialog } from '../../../../store/dialogs';
import { MaxWidthTooltip } from '../../../../components/MaxWidthTooltip';

function MeterCard({ meterId }) {
  const dispatch = useDispatch();
  const meter = useMeter(meterId);
  const logger = useLogger(meter?.logger_id);
  const alarms = useDeviceAlarms(meterId);

  const handleMeterClick = () => {
    dispatch(
      navigate({
        page: 'meter',
        id: meter.meter_id,
      })
    );
  };

  const handleIconClick = () => {
    dispatch(
      openDialog({
        type: 'meter',
        mode: 'edit',
        id: meter.meter_id,
      })
    );
  };

  const renderMeterLink = () => {
    const activeAlarms = filter(alarms, { status: true });
    const renderLink = () => {
      return (
        <Badge
          badgeContent={alarms.length ? alarms.length : null}
          color='error'
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
          <Link
            underline='hover'
            variant='h6'
            mb={1}
            ml={2}
            align='center'
            onClick={handleMeterClick}>
            {meter.name}
          </Link>
        </Badge>
      );
    };

    if (activeAlarms.length > 0 && logger.active) {
      return (
        <MaxWidthTooltip
          placement='right'
          title={
            <List sx={{ p: 0 }}>
              {activeAlarms.map((alarm) => (
                <ListItem key={alarm.alarm_id} disablePadding disableGutters>
                  <ListItemText
                    primaryTypographyProps={{ variant: 'caption' }}
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: '1',
                      WebkitBoxOrient: 'vertical',
                      maxWidth: 250,
                    }}>
                    {alarm.message}
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          }>
          {renderLink()}
        </MaxWidthTooltip>
      );
    }
    return renderLink();
  };

  return (
    <Card raised>
      <CardHeader
        action={
          <IconButton onClick={() => handleIconClick(meter)}>
            <FontAwesomeIcon icon={['fal', 'cog']} size='sm' />
          </IconButton>
        }
        disableTypography
        title={
          <Stack
            sx={{ width: '100%' }}
            spacing={2}
            direction='row'
            justifyContent='flex-start'
            alignItems='center'>
            {renderMeterLink()}
          </Stack>
        }
        sx={{ textAlign: 'left', pl: 0, pt: 1, pb: 0 }}
      />
      <CardContent sx={{ p: 1, pt: 0, pb: '4px !important' }}>
        <Grid container>
          <Grid container item xs={12}>
            <MeterProfileChart meterId={meter.meter_id} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

MeterCard.propTypes = {
  meterId: PropTypes.string.isRequired,
};

export default MeterCard;
