import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import has from 'lodash/has';
import toUpper from 'lodash/toUpper';

import Grid from '@mui/material/Grid';

import useLogger from '../../../../store/hooks/useLogger';
import useSite from '../../../../store/hooks/useSite';
import useLoggerDevices from '../../../../store/hooks/useLoggerDevices';
import CurrentGeneration from '../../../../components/widgets/CurrentGeneration';
import ViewStack from '../../../../components/ViewStack';
import AlarmsTable from '../../../../components/table/tables/AlarmsTable';
import DevicesGrid from './DevicesGrid';
import LoggerInfo from './LoggerInfo';
import LoggerProduction from './LoggerProduction';

const VIEWS = {
  DEVICES: {
    name: 'devices',
    description:
      'View a snapshot of all recent device data for the data logger',
  },
  ALARMS: {
    name: 'alarms',
    description: 'View alarms for the data logger and its devices',
  },
};

function LoggerDashboard() {
  const { id, view } = useSelector((state) => state.pages.logger);
  const logger = useLogger(id);
  const site = useSite(logger.site_id);
  const { meters, inverters, alarms, loading } = useLoggerDevices(id);

  const [selectedView, setSelectedView] = useState(VIEWS.DEVICES.name);

  useEffect(() => {
    if (has(VIEWS, toUpper(view))) {
      setSelectedView(view);
    } else if (view === '') {
      setSelectedView(VIEWS.DEVICES.name);
    }
  }, [view]);

  const renderView = () => {
    if (selectedView === VIEWS.DEVICES.name) {
      return <DevicesGrid site={site} logger={logger} />;
    } else if (selectedView === VIEWS.ALARMS.name) {
      return <AlarmsTable resource={logger} alarms={alarms} />;
    }
  };

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={2}
      padding={2}
      sx={{ pl: 4, pr: 3 }}>
      <Grid item xs={4}>
        <LoggerInfo logger={logger} loading={loading} />
      </Grid>
      <Grid item xs={4}>
        <CurrentGeneration
          loggers={[logger]}
          meters={meters}
          inverters={inverters}
          loading={loading}
        />
      </Grid>
      <Grid item xs={4}>
        <LoggerProduction meters={meters} />
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: '8px !important' }}>
        <ViewStack
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          views={VIEWS}
        />
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: '8px !important' }}>
        {renderView()}
      </Grid>
    </Grid>
  );
}

export default LoggerDashboard;
