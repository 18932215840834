import React from 'react';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';

import Badge from '@mui/material/Badge';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';

import useSite from '../../../../store/hooks/useSite';
import useLoggerDevices from '../../../../store/hooks/useLoggerDevices';
import InvertersProfileChart from '../../../../components/charts/preview/InvertersProfileChart';
import SelectInverter from '../../../../components/charts/selectors/SelectInverter';
import { MaxWidthTooltip } from '../../../../components/MaxWidthTooltip';

function InvertersProfileCard({ logger }) {
  const site = useSite(logger.site_id);
  const { inverters, alarms } = useLoggerDevices(logger.logger_id);
  const inverterAlarms = filter(alarms, (alarm) =>
    alarm.device_id.startsWith('inverter')
  );

  const renderLink = () => {
    return (
      <Badge
        badgeContent={inverterAlarms.length ? inverterAlarms.length : null}
        color='error'
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <SelectInverter inverters={inverters} />
      </Badge>
    );
  };

  if (inverters.length === 0) return null;
  return (
    <Card raised>
      <CardHeader
        disableTypography
        title={
          <Stack
            sx={{ width: '100%' }}
            spacing={2}
            direction='row'
            justifyContent='flex-start'
            alignItems='center'>
            {inverterAlarms.length > 0 && logger.active ? (
              <MaxWidthTooltip
                placement='right'
                title={
                  <List sx={{ p: 0 }}>
                    {inverterAlarms.map((alarm) => (
                      <ListItem
                        key={alarm.alarm_id}
                        disablePadding
                        disableGutters>
                        <ListItemText
                          primaryTypographyProps={{ variant: 'caption' }}
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: '1',
                            WebkitBoxOrient: 'vertical',
                            maxWidth: 250,
                          }}>
                          {alarm.message}
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>
                }>
                {renderLink()}
              </MaxWidthTooltip>
            ) : (
              renderLink()
            )}
          </Stack>
        }
        sx={{ textAlign: 'left', pl: 0, pt: 1, pb: 0 }}
      />

      <CardContent sx={{ p: 1, pt: 0, pb: '4px !important' }}>
        <InvertersProfileChart
          site={site}
          logger={logger}
          inverters={inverters}
        />
      </CardContent>
    </Card>
  );
}

InvertersProfileCard.propTypes = {
  logger: PropTypes.object.isRequired,
};

export default InvertersProfileCard;
