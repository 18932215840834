import axios from '../xhr_libs/axios';
import { processResponse } from '.';

export const refreshMeterAPI = async (orgId, meterId) => {
  return processResponse(
    await axios.get(`/resource/refresh_meter/${orgId}/${meterId}`)
  );
};

export const getMetersAPI = async () => {
  return processResponse(await axios.get('/resource/meters'));
};

export const postMeterAPI = async (body) => {
  return processResponse(await axios.post(`/resource/meters`, body));
};

export const putMeterAPI = async (meter) => {
  return processResponse(
    await axios.put(`/resource/meters/${meter.meter_id}`, meter)
  );
};

export const deleteMeterAPI = async (meterId) => {
  return processResponse(await axios.delete(`/resource/meters/${meterId}`));
};
