import axios from '../xhr_libs/axios';
import { processResponse } from '.';

export const getImagesAPI = async (resourceId) => {
  return processResponse(await axios.get(`/resource/images/${resourceId}`));
};

export const fetchImageAPI = async (resourceId, key) => {
  return processResponse(
    await axios.get(`/resource/images/${resourceId}/${key}`)
  );
};

export const generatePutUrlAPI = async (props) => {
  const body = {
    filename: props.filename,
    ContentType: props.contentType,
  };
  const config = {
    headers: {
      ContentType: props.contentType,
      Accept: 'application/json',
    },
  };
  return processResponse(
    await axios.post(`/resource/images/${props.resource_id}`, body, config)
  );
};

export const renameImageAPI = async (body) => {
  return processResponse(
    await axios.put(`/resource/images/${body.resourceId}`, body)
  );
};

export const deleteImageAPI = async (body) => {
  return processResponse(
    await axios.delete(`/resource/images/${body.resourceId}/${body.filename}`)
  );
};
