import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';
import get from 'lodash/get';
import has from 'lodash/has';
import head from 'lodash/head';
import includes from 'lodash/includes';
import toUpper from 'lodash/toUpper';

import Grid from '@mui/material/Grid';

import ViewStack from '../../../components/ViewStack';
import { VIEWS } from '../../../components/charts/analysis/views';
import ProductionProfileChartContainer from '../../../components/charts/analysis/ProductionProfileChart/Container';
import PerformanceChartContainer from '../../../components/charts/analysis/PerformanceChart/Container';
import EstimatedChartContainer from '../../../components/charts/analysis/EstimatedChart/Container';
import WeatherStationChartContainer from '../../../components/charts/analysis/WeatherStationChart/Container';
import MeterChart from '../../../components/charts/analysis/MeterChart';

function AnalysisPage(props) {
  const { resource, timezone, view, sites, meters, inverters, sensors } = props;

  const [views, setViews] = useState(VIEWS);
  const [selectedView, setSelectedView] = useState(get(VIEWS, '0.name', ''));

  useEffect(() => {
    if (has(views, toUpper(view))) {
      setSelectedView(view);
    } else if (view === '') {
      if (resource?.type_ === 'organization' && resource?.is_portfolio) {
        setSelectedView(VIEWS.PERFORMANCE.name);
      } else {
        setSelectedView(VIEWS.PRODUCTION.name);
      }
    }
  }, [view, views, resource]);

  useEffect(() => {
    const type = get(resource, 'type_', 'organization');
    if (
      type === 'organization' &&
      get(resource, 'is_portfolio', false) === true
    ) {
      setViews(
        filter(VIEWS, ({ name }) => {
          return includes(['performance'], name);
        })
      );
    } else if (type === 'organization' || type === 'site') {
      setViews(
        filter(VIEWS, ({ name }) => {
          return includes(
            [
              'production profile',
              'performance',
              'estimated production',
              'weather',
            ],
            name
          );
        })
      );
    } else if (type === 'logger') {
      setViews(
        filter(VIEWS, ({ name }) => {
          return includes(['production profile', 'weather'], name);
        })
      );
    } else if (type === 'meter') {
      setViews(
        filter(VIEWS, ({ name }) => {
          return includes(['production profile', 'meter', 'weather'], name);
        })
      );
    }
  }, [resource]);

  const renderView = () => {
    if (selectedView === VIEWS.PRODUCTION.name) {
      return (
        <ProductionProfileChartContainer
          defaultTimezone={timezone}
          meters={meters}
          inverters={inverters}
        />
      );
    } else if (selectedView === VIEWS.PERFORMANCE.name) {
      return <PerformanceChartContainer sites={sites} meters={meters} />;
    } else if (selectedView === VIEWS.ESTIMATED.name) {
      return (
        <EstimatedChartContainer defaultTimezone={timezone} sites={sites} />
      );
    } else if (selectedView === VIEWS.WEATHER.name) {
      return (
        <WeatherStationChartContainer
          defaultTimezone={timezone}
          meters={meters}
          sensors={sensors}
        />
      );
    } else if (selectedView === VIEWS.METER.name) {
      return <MeterChart site={head(sites)} meter={resource} />;
    }

    return null;
  };

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={1}
      padding={1}
      sx={{ pl: 4, pr: 3 }}>
      <Grid item xs={12} sx={{ paddingTop: '8px !important' }}>
        <ViewStack
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          views={views}
        />
      </Grid>
      {renderView()}
    </Grid>
  );
}

AnalysisPage.propTypes = {
  resource: PropTypes.object,
  timezone: PropTypes.object,
  view: PropTypes.string,
  sites: PropTypes.array,
  meters: PropTypes.array,
  inverters: PropTypes.array,
  sensors: PropTypes.array,
};

export default AnalysisPage;
