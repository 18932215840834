import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import MaskedInput from 'react-text-mask';
import get from 'lodash/get';

import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';

import { navigate } from '../../../store/pages';
import { TextFieldListItem } from '../../List/TextFieldListItem';

function IPAddressMask(props) {
  const { onChange } = props;
  return (
    <MaskedInput
      onChange={onChange}
      guide={false}
      mask={(value) => Array(value.length).fill(/[\d.]/)}
      render={(ref, innerProps) => {
        return <TextField inputRef={ref} {...props} {...innerProps} />;
      }}
    />
  );
}

function Form(props) {
  const { mode, site, logger, setLogger, handleClose } = props;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.item);

  const handleChange = (e) => {
    setLogger((_logger) => ({ ..._logger, [e.target.id]: e.target.value }));
  };

  return (
    <List disablePadding>
      <ListItem sx={{ pl: 0, pr: 0, pt: 1, pb: 1 }}>
        <Link
          underline='hover'
          onClick={
            mode === 'edit'
              ? () => {
                  dispatch(
                    navigate({
                      page: site.type_,
                      id: site.site_id,
                    })
                  );
                  handleClose();
                }
              : null
          }>
          {site?.name}
        </Link>
      </ListItem>
      {mode === 'edit' && get(user, 'super_user', false) ? (
        <TextFieldListItem
          id='logger_id'
          label='Logger ID'
          value={logger.logger_id}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
          }}
        />
      ) : null}
      <TextFieldListItem
        label='Name'
        value={logger.name}
        onChange={(e) =>
          handleChange({ target: { id: 'name', value: e.target.value } })
        }
      />
      <ListItem sx={{ pl: 0, pr: 0, pt: 1, pb: 1 }}>
        <IPAddressMask
          id='ip_address'
          label='IP Address'
          value={logger.ip_address}
          fullWidth
          variant='standard'
          onChange={handleChange}
        />
      </ListItem>
      {get(user, 'super_user') && (
        <FormControl variant='standard' sx={{ width: '100%' }}>
          <InputLabel id='ingestion_method-label'>
            Data Ingestion Method
          </InputLabel>
          <Select
            label='Device Type'
            labelId='ingestion_method-label'
            id='type-select'
            value={logger.ingestion_method || ''}
            onChange={(e) =>
              handleChange({
                target: { id: 'ingestion_method', value: e.target.value },
              })
            }
            sx={{
              backgroundColor: 'unset',
              '& .MuiSelect-select': { display: 'flex', alignItems: 'center' },
            }}>
            {['vpn', 'public', 'api', 'demo'].map((method) => {
              return (
                <MenuItem key={method} value={method}>
                  {method}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}

      {get(user, 'super_user', false) && mode === 'edit' ? (
        <ListItem key={logger.active} disablePadding sx={{ pl: 0, pr: 0 }}>
          <ListItemButton
            role={undefined}
            onClick={() => setLogger({ ...logger, active: !logger.active })}
            dense>
            <ListItemIcon>
              <Checkbox
                edge='start'
                checked={logger.active}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary={logger.active ? 'Active' : 'Inactive'} />
          </ListItemButton>
        </ListItem>
      ) : null}
    </List>
  );
}

Form.propTypes = {
  logger: PropTypes.object,
  setLogger: PropTypes.func,
  handleClose: PropTypes.func,
};

export default Form;
