import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import startCase from 'lodash/startCase';

import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import InfoButton from './buttons/InfoButton';

function ViewLink({ view, selectedView, handleClick }) {
  return (
    <Link
      onClick={() => handleClick(view)}
      variant='h6'
      align='left'
      ml={2}
      underline={view === selectedView ? 'none' : 'hover'}
      sx={{ fontWeight: view === selectedView ? 800 : 100 }}>
      {startCase(view)}
    </Link>
  );
}

ViewLink.propTypes = {
  selectedView: PropTypes.string,
  view: PropTypes.string,
  handleClick: PropTypes.func,
};

function ViewStack(props) {
  const { selectedView, setSelectedView, views } = props;

  const handleClick = (view) => {
    if (view !== selectedView) {
      setSelectedView(view);
    }
  };

  const viewDescription = get(
    find(views, { name: selectedView }),
    'description'
  );

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 30,
        paddingTop: '20px',
        paddingBottom: '20px',
      }}>
      <Stack direction='row' justifyContent='flex-start' alignItems='center'>
        {map(views, (values, key) => {
          return (
            <ViewLink
              key={key}
              view={values.name}
              selectedView={selectedView}
              handleClick={handleClick}
            />
          );
        })}
      </Stack>
      {viewDescription ? (
        <InfoButton
          title={selectedView}
          content={
            <Typography variant='body2' color='text.secondary'>
              {viewDescription}
            </Typography>
          }
        />
      ) : null}
    </div>
  );
}

ViewStack.propTypes = {
  selectedView: PropTypes.string,
  setSelectedView: PropTypes.func,
  views: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  infoTitle: PropTypes.string,
  viewDescription: PropTypes.string,
};

export default ViewStack;
