import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import reduce from 'lodash/reduce';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { displaykW } from '../../../../helpers/display-energy';

function WeatherStationChartTotals(props) {
  const { rawData, selectedMeters, selectedSensors } = props;

  const [production, setProduction] = useState(0);
  const [irradiance, setIrradiance] = useState(0);

  useEffect(() => {
    const _production = reduce(
      rawData,
      (acc, device) => {
        if (device.device_id.startsWith('meter')) {
          return (
            acc +
            reduce(
              device.records,
              (acc, record) => {
                return acc + get(record, 'value', 0) / 4;
              },
              0
            )
          );
        } else {
          return acc;
        }
      },
      0
    );
    const _irradiance = reduce(
      rawData,
      (acc, device) => {
        if (device.device_id.startsWith('sensor')) {
          // sum
          return (
            acc +
            reduce(
              device.records,
              (acc, record) => {
                return acc + get(record, 'value', 0) / 4;
              },
              0
            )
          );
        } else {
          return acc;
        }
      },
      0
    );

    setProduction(_production);
    setIrradiance(_irradiance);
  }, [rawData]);

  return (
    <Card raised>
      <CardContent sx={{ p: '12px', pb: '12px !important' }}>
        <Stack direction='row'>
          {selectedMeters.length > 0 && (
            <Typography sx={{ mr: 2 }} color='text.secondary'>
              Total Production:
            </Typography>
          )}
          {selectedMeters.length > 0 && (
            <Typography sx={{ mr: 15 }}>
              {displaykW(production, true)}
            </Typography>
          )}
          {selectedSensors.length > 0 && (
            <Typography sx={{ mr: 2 }} color='text.secondary'>
              Total Irradiance:
            </Typography>
          )}
          {selectedSensors.length > 0 && (
            <Typography>{displaykW(irradiance, false, true)}</Typography>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}

WeatherStationChartTotals.propTypes = {
  rawData: PropTypes.array,
  selectedMeters: PropTypes.array,
  selectedSensors: PropTypes.array,
};

export default WeatherStationChartTotals;
