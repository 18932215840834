import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';

export const OrganizationIconTypeProvider = (props) => {
  return (
    <DataTypeProvider
      formatterComponent={({ value }) => {
        return (
          <Tooltip title={value ? 'Portfolio' : 'Organization'}>
            <Icon
              sx={{
                height: '35px',
                width: '35px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <FontAwesomeIcon
                size='xs'
                style={{ width: 22, height: 22 }}
                icon={value ? ['fal', 'folder'] : ['fal', 'buildings']}
              />
            </Icon>
          </Tooltip>
        );
      }}
      {...props}
    />
  );
};
