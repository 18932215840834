import React from 'react';

import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';

export const TextFieldListItem = (props) => {
  return (
    <ListItem sx={{ pl: 0, pr: 0, pt: 1, pb: 1 }}>
      <TextField fullWidth variant='standard' {...props} />
    </ListItem>
  );
};
