import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@mui/material/IconButton';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

export const GithubLinkTypeProvider = (props) => {
  return (
    <DataTypeProvider
      formatterComponent={({ value }) => {
        return (
          <IconButton onClick={() => window.open(value, '_blank')}>
            <FontAwesomeIcon icon={['fab', 'github']} />
          </IconButton>
        );
      }}
      {...props}
    />
  );
};
