import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import useTheme from '@mui/material/styles/useTheme';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';

import { navigate } from '../../../store/pages';
import { TextFieldListItem } from '../../List/TextFieldListItem';
import { IntegerFieldListItem } from '../../List/IntegerFieldListItem';

function Form(props) {
  const { mode, meter, sensor, setSensor, handleClose } = props;
  const user = useSelector((state) => state.user.item);
  const { parentIndex } = useSelector((state) => state.dialogs.sensor);

  const theme = useTheme();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setSensor({ ...sensor, [e.target.id]: e.target.value });
  };

  useEffect(() => {
    if (parentIndex) {
      setSensor((s) => ({
        ...s,
        name: `Sensor ${parentIndex}`,
        parent_index: parentIndex,
      }));
    }
  }, [parentIndex, setSensor]);

  return (
    <List disablePadding>
      <ListItem sx={{ pl: 0, pr: 0, pt: 1, pb: 1 }}>
        <Link
          underline='hover'
          sx={{ color: theme.palette.veregy_colors.blue }}
          onClick={() => {
            dispatch(
              navigate({
                page: 'meter',
                id: meter.org_id,
              })
            );
            handleClose();
          }}>
          {get(meter, 'name')}
        </Link>
      </ListItem>
      {mode === 'edit' && get(user, 'super_user', false) && (
        <TextFieldListItem
          id='sensor_id'
          label='Sensor ID'
          value={sensor.sensor_id}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
          }}
        />
      )}
      <TextFieldListItem
        label='Name'
        value={sensor.name}
        onChange={(e) =>
          handleChange({ target: { id: 'name', value: e.target.value } })
        }
      />
      <IntegerFieldListItem
        id='parent_index'
        label='Index'
        value={sensor.parent_index}
        onChange={handleChange}
      />
      <TextFieldListItem
        label='Orientation'
        id='orientation'
        defaultValue=''
        select
        value={sensor.orientation}
        fullWidth
        onChange={(e) => {
          setSensor({ ...sensor, orientation: e.target.value });
        }}>
        <MenuItem value=''></MenuItem>
        <MenuItem value='Plane of Array'>Plane of Array (POA)</MenuItem>
        <MenuItem value='Global Horizontal Irradiance'>
          Global Horizontal Irradiance (GHI)
        </MenuItem>
      </TextFieldListItem>
      <IntegerFieldListItem
        id='tilt'
        label='Tilt'
        value={sensor.tilt}
        onChange={handleChange}
        InputProps={{
          startAdornment: <InputAdornment position='start'>°</InputAdornment>,
        }}
      />
      <IntegerFieldListItem
        id='azimuth'
        label='Azimuth'
        value={sensor.azimuth}
        onChange={handleChange}
        InputProps={{
          startAdornment: <InputAdornment position='start'>°</InputAdornment>,
        }}
      />
    </List>
  );
}

Form.propTypes = {
  meter: PropTypes.object,
  sensor: PropTypes.object,
  setSensor: PropTypes.func,
  handleClose: PropTypes.func,
};

export default Form;
