import React from 'react';
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import ROUTES from '../../../constants/routes';
import NavTree from './NavTree';

const Root = styled('div')(() => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  overflowX: 'hidden',
  minWidth: 280,
}));

function Drawer(props) {
  const { open, toggleOpen, drawerRef } = props;
  const dispatch = useDispatch();
  const { item: user } = useSelector((state) => state.user);

  return (
    <MuiDrawer
      variant='permanent'
      anchor='left'
      open={open}
      onClose={toggleOpen}
      sx={{ display: 'flex', overflow: 'visible' }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile
      }}>
      <Root ref={drawerRef}>
        <Box
          component='img'
          alt='LightLevel By Veregy'
          src='/images/LightLevel_by_Veregy.svg'
          title='LightLevel By Veregy'
          sx={{ p: 1 }}
        />
        <Divider />
        <NavTree />
        <Divider />
        <div style={{ flex: 1 }} />
        <Divider />

        <List dense={true} style={{ margin: '0px', padding: '0px' }}>
          {get(user, 'super_user') && get(user, 'kpi_tracking') ? (
            <ListItemButton
              dense={true}
              divider={true}
              onClick={() => dispatch(push(ROUTES.AUTH.KPIS))}>
              <ListItemIcon style={{ minWidth: '34px' }}>
                <FontAwesomeIcon icon={['fal', 'radar']} size='lg' />
              </ListItemIcon>
              <ListItemText>Key Performance Indicators</ListItemText>
            </ListItemButton>
          ) : null}
          <ListItemButton
            dense={true}
            divider={true}
            onClick={() => dispatch(push(ROUTES.AUTH.PROFILE))}>
            <ListItemIcon style={{ minWidth: '34px' }}>
              <FontAwesomeIcon icon={['fal', 'user-circle']} size='lg' />
            </ListItemIcon>
            <ListItemText>Profile</ListItemText>
          </ListItemButton>
          {get(user, 'super_user') ? (
            <ListItemButton
              dense={true}
              divider={true}
              onClick={() => dispatch(push(ROUTES.AUTH.ADMIN))}>
              <ListItemIcon style={{ minWidth: '34px' }}>
                <FontAwesomeIcon icon={['fal', 'shield-alt']} size='lg' />
              </ListItemIcon>
              <ListItemText>Admin</ListItemText>
            </ListItemButton>
          ) : null}
        </List>

        <List dense={true} style={{ margin: '0px', padding: '0px' }}>
          <ListItem dense={true} divider={true}>
            <ListItemText secondary='LightLevel' />
            <ListItemText
              secondary='Terms'
              onClick={() => dispatch(push(ROUTES.AUTH.TERMS))}
              sx={{
                cursor: 'pointer',
              }}
            />
            <ListItemText
              secondary='Privacy'
              onClick={() => dispatch(push(ROUTES.AUTH.PRIVACY))}
              sx={{
                cursor: 'pointer',
              }}
            />
          </ListItem>
        </List>
      </Root>
    </MuiDrawer>
  );
}

Drawer.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  drawerRef: PropTypes.func,
};

export default Drawer;
