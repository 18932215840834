import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import each from 'lodash/each';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import map from 'lodash/map';
import range from 'lodash/range';
import sortBy from 'lodash/sortBy';

import { alpha } from '@mui/material/styles';
import useTheme from '@mui/material/styles/useTheme';
import {
  Area,
  Bar,
  ComposedChart,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { navigate } from '../../../../store/pages';
import useSite from '../../../../store/hooks/useSite';
import useSiteDevices from '../../../../store/hooks/useSiteDevices';
import Loader from '../../../Loaders/ComponentLoader';
import ChartTooltip from './Tooltip';
import { addEstimatedProductionToChartData } from '../../../../helpers/chart-data';

function SiteProfileChart({ siteId }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [data, setData] = useState([]);
  const site = useSite(siteId);
  const { meters, loading } = useSiteDevices(siteId);

  useEffect(() => {
    if (site.timezone) {
      let _data = map(range(start.unix(), end.unix(), 900), (timestamp) => ({
        timestamp,
      }));

      each(meters, (meter) => {
        each(meter.timeseries, (datapoint) => {
          let idx = findIndex(_data, { timestamp: datapoint.timestamp });
          if (idx >= 0) {
            _data[idx] = {
              ..._data[idx],
              [meter.meter_id]: datapoint.value,
            };
          }
        });
      });

      setData(
        addEstimatedProductionToChartData(_data, meters, site.timezone.offset)
      );
    }

    /* eslint-disable-next-line */
  }, [meters, site.timezone]);

  const handleClick = (view) => {
    dispatch(
      navigate({
        page: 'site',
        id: siteId,
        tab: 'analysis',
        view: view,
      })
    );
  };

  let start = dayjs()
    .utcOffset(get(site, 'timezone.offset', 0))
    .startOf('day')
    .subtract(1, 'day');
  let end = start.utcOffset(get(site, 'timezone.offset', 0)).add(2, 'day');

  let startReference = start;
  let endReference = start.add(24, 'hour');

  if (loading) return <Loader />;

  const _meters = sortBy(meters, 'parent_index');
  return (
    <ResponsiveContainer width='100%' height='100%'>
      <ComposedChart
        onClick={handleClick}
        width='100%'
        height='100%'
        data={data}
        margin={{
          top: 0,
          right: 10,
          left: 10,
          bottom: 0,
        }}>
        <YAxis hide domain={[0, (dataMax) => Math.ceil(dataMax / 10) * 10]} />
        <XAxis
          type='number'
          dataKey='timestamp'
          domain={[start.unix(), end.unix()]}
          hide
        />
        <ReferenceLine
          x={startReference.unix()}
          stroke={'transparent'}
          label={
            <Label position='insideTopLeft'>
              {startReference.format('MMM DD')}
            </Label>
          }
        />
        <ReferenceLine
          x={endReference.unix()}
          stroke={'transparent'}
          label={
            <Label position='insideTopLeft'>
              {endReference.format('MMM DD')}
            </Label>
          }
        />
        <Tooltip
          content={<ChartTooltip timezone={site.timezone} meters={_meters} />}
        />
        {map(_meters, (meter, idx) => {
          return (
            <Area
              stackId='areas'
              key={`area-${meter.meter_id}`}
              type='monotone'
              dataKey={meter.meter_id}
              fill={alpha(
                theme.palette.veregy_colors.blue,
                1 - idx * (1 / Math.max(_meters.length, 4))
              )}
            />
          );
        })}
        {map(_meters, (meter, idx) => {
          return (
            <Bar
              stackId='estimated'
              key={`estimated-${meter.meter_id}`}
              dataKey={`${meter.meter_id}:estimated`}
              fill={alpha(theme.palette.veregy_colors.lightgrey, 1 - idx * 0.2)}
            />
          );
        })}
      </ComposedChart>
    </ResponsiveContainer>
  );
}

SiteProfileChart.propTypes = {
  siteId: PropTypes.string,
};

export default SiteProfileChart;
