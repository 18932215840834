import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import startCase from 'lodash/startCase';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';

import useVerifyOrgRole from '../../../store/hooks/useVerifyOrgRole';
import { closeDialog, openConfirmDialog } from '../../../store/dialogs';
import { navigate } from '../../../store/pages';
import { deleteSite, postSite, putSite } from '../../../store/sites';
import Form from './Form';
import DialogTitleOptions from '../DialogTitleOptions';
import useSite, { defaultState } from '../../../store/hooks/useSite';

function SiteDialog() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { id, mode, orgId } = useSelector((state) => state.dialogs.site);
  const siteItem = useSite(id);

  const [site, setSite] = useState(defaultState);

  const isOrgAdmin = useVerifyOrgRole(siteItem?.org_id, 0);
  const isOrgEditor = useVerifyOrgRole(siteItem?.org_id, 1);

  useEffect(() => {
    if (siteItem.site_id) {
      setSite({ ...siteItem });
    } else if (orgId) {
      setSite((_site) => ({
        ..._site,
        org_id: orgId,
      }));
    }
  }, [siteItem, orgId]);

  const handleClose = () => {
    dispatch(closeDialog('site'));
    setSite(defaultState);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    switch (mode) {
      case 'create':
        dispatch(postSite(site));
        break;

      case 'edit':
        dispatch(putSite(site));
        break;

      default:
        break;
    }

    handleClose();
  };

  const handleDelete = () => {
    handleClose();
    dispatch(
      openConfirmDialog({
        title: 'Delete Site',
        message: 'Are you sure you want to delete this site?',
        onConfirm: () => dispatch(deleteSite(site)),
      })
    );
  };

  const handleOpenConfigView = (view) => {
    dispatch(
      navigate({
        page: 'site',
        id: site.site_id,
        tab: 'config',
        view,
      })
    );
    handleClose();
  };

  const handleChange = (e) => {
    setSite((_site) => ({ ..._site, [e.target.id]: e.target.value }));
  };

  const handleAddressSelect = (address) => {
    setSite((_site) => ({
      ..._site,
      address: address.formatted_address,
      location: {
        lat: address.geometry.location.lat(),
        lng: address.geometry.location.lng(),
      },
    }));
  };

  const handleAddressChange = (e) => {
    setSite((_site) => ({ ..._site, address: e.target.value }));
  };

  return (
    <Dialog open={id !== ''} onClose={handleClose} fullScreen={fullScreen}>
      <form onSubmit={handleSubmit} style={theme.dialog.form}>
        <DialogTitle sx={{ p: 1, pl: 2, pb: 0 }}>
          {startCase(mode) + ' Site'}
          <DialogTitleOptions mode={mode} handleClose={handleClose}>
            <MenuItem
              key='kiosk-url'
              onClick={() => {
                navigator.clipboard.writeText(
                  process.env.REACT_APP_WEB_URL +
                    'kiosk?site=' +
                    site.kiosk_code
                );
              }}>
              <ListItemIcon>
                <FontAwesomeIcon icon={['fal', 'copy']} size='lg' />
              </ListItemIcon>
              <ListItemText>Kiosk URL</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleOpenConfigView('devices')}>
              Devices
            </MenuItem>
            <MenuItem onClick={() => handleOpenConfigView('alarms')}>
              Alarms
            </MenuItem>
            <MenuItem onClick={() => handleOpenConfigView('expectations')}>
              Expectations
            </MenuItem>
            {isOrgAdmin && mode === 'edit' && (
              <MenuItem onClick={handleDelete}>Delete</MenuItem>
            )}
          </DialogTitleOptions>
        </DialogTitle>
        <DialogContent
          sx={{
            overflow: 'inherit',
            p: 2,
            pt: 0,
            pb: 0,
          }}>
          <Form
            mode={mode}
            site={site}
            handleChange={handleChange}
            handleAddressChange={handleAddressChange}
            handleAddressSelect={handleAddressSelect}
            handleClose={handleClose}
          />
        </DialogContent>
        <DialogActions sx={{ p: '4px' }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type='submit'
            onClick={handleSubmit}
            color='primary'
            variant='contained'
            disabled={
              (mode === 'edit' && !isOrgEditor) ||
              (mode === 'create' && !isOrgAdmin)
            }>
            {(() => {
              switch (mode) {
                case 'create':
                  return 'Create';
                case 'edit':
                  return 'Update';
                default:
                  return null;
              }
            })()}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default SiteDialog;
