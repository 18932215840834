import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

import { SLIDES } from '../../../constants/kiosk_slides';
import { putOrganization } from '../../../store/organizations/_organizations';
import { putSite } from '../../../store/sites';
import useVerifyOrgRole from '../../../store/hooks/useVerifyOrgRole';
import SlideContainer from './SlideContainer';

function Slides(props) {
  const { resource } = props;
  const dispatch = useDispatch();
  const isOrgEditor = useVerifyOrgRole(resource?.org_id, 1);

  const [slides, setSlides] = useState([]);

  useEffect(() => {
    if (resource?.theme?.slides.length) {
      setSlides(resource?.theme?.slides);
    } else {
      setSlides(SLIDES);
    }
  }, [resource]);

  const handleSave = async (e) => {
    e.preventDefault();

    const settings = {
      theme: { slides },
    };
    if (resource.type_ === 'organization') {
      dispatch(
        putOrganization({
          org_id: resource.org_id,
          ...settings,
        })
      );
    } else if (resource.type_ === 'site') {
      dispatch(
        putSite({
          org_id: resource.org_id,
          site_id: resource.site_id,
          ...settings,
        })
      );
    }
  };

  const handleRevertToDefault = () => {
    setSlides(SLIDES);
  };

  const handleUpdateSlide = (idx, newSlide) => {
    const updatedSlides = [...slides];

    updatedSlides[idx] = {
      ...newSlide,
      interval_seconds: Number(newSlide.interval_seconds),
    };
    setSlides(updatedSlides);
  };

  const updateIndex = (item, newIndex) => {
    const currentIndex = slides.findIndex((listItem) => listItem === item);

    // If the item is not found, or the new index is out of bounds, return
    if (currentIndex === -1 || newIndex < 0 || newIndex >= slides.length) {
      return;
    }

    const updatedSlides = [...slides];
    const removedItem = updatedSlides.splice(currentIndex, 1)[0];

    // Insert the item at the new index
    updatedSlides.splice(newIndex, 0, removedItem);
    setSlides(updatedSlides);
  };

  const changeDetected = (() => {
    const list1 = slides;
    const list2 = resource?.theme?.slides;

    if (list1.length !== list2?.length) {
      return true;
    }

    // Iterate through the items of the lists and compare them
    for (let i = 0; i < list1.length; i++) {
      // compare slide properties
      if (JSON.stringify(list1[i]) !== JSON.stringify(list2[i])) {
        return true;
      }
    }

    return false;
  })();

  return (
    <Card raised>
      <CardHeader
        sx={{ p: 1, pb: 0 }}
        title='Slides'
        titleTypographyProps={{ variant: 'h6' }}
        action={
          <>
            <Tooltip placement='top' title='default'>
              <span>
                <IconButton
                  disabled={!isOrgEditor}
                  onClick={handleRevertToDefault}>
                  <FontAwesomeIcon icon={['fal', 'undo']} size='sm' />
                </IconButton>
              </span>
            </Tooltip>
            <IconButton
              sx={{ mr: 1 }}
              disabled={!isOrgEditor || !changeDetected}
              onClick={handleSave}>
              <FontAwesomeIcon
                icon={['fal', 'save']}
                style={{ height: 23, width: 23 }}
              />
            </IconButton>
          </>
        }
      />
      <CardContent sx={{ p: 1, pt: 0, pb: '8px !important' }}>
        <Stack
          direction='row'
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          width='100%'
          spacing={1}>
          {slides.map((slide, idx) => (
            <SlideContainer
              key={idx}
              slide={slide}
              idx={idx}
              updateIndex={updateIndex}
              updateSlide={handleUpdateSlide}
            />
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
}

export default Slides;
