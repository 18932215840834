import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';
import get from 'lodash/get';

import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';

import { navigate } from '../../../store/pages';
import { TextFieldListItem } from '../../List/TextFieldListItem';
import SelectSensor from './SelectSensor';
import { IntegerFieldListItem } from '../../List/IntegerFieldListItem';

function Form(props) {
  const { meter, mode, inverter, setInverter, handleClose } = props;
  const dispatch = useDispatch();

  const { parentIndex } = useSelector((state) => state.dialogs.inverter);
  const user = useSelector((state) => state.user.item);
  const sensors = useSelector((state) => state.sensors.data);

  const [orgSensors, setOrgSensors] = useState([]);

  useEffect(() => {
    setOrgSensors(filter(sensors, { org_id: meter?.org_id }));
  }, [sensors, meter?.org_id]);

  useEffect(() => {
    if (parentIndex) {
      setInverter({
        ...inverter,
        name: `Inverter ${parentIndex}`,
        parent_index: parentIndex,
      });
    }
    /* eslint-disable-next-line */
  }, [parentIndex]);

  const handleChange = (e) => {
    setInverter({ ...inverter, [e.target.id]: e.target.value });
  };

  return (
    <List disablePadding>
      <ListItem sx={{ pl: 0, pr: 0, pt: 1, pb: 1 }}>
        <Link
          underline='hover'
          onClick={() => {
            dispatch(
              navigate({
                page: 'meter',
                id: meter.org_id,
              })
            );
            handleClose();
          }}>
          {get(meter, 'name')}
        </Link>
      </ListItem>

      {mode === 'edit' && get(user, 'super_user', false) ? (
        <TextFieldListItem
          id='inverter_id'
          label='Inverter ID'
          value={inverter.inverter_id}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
          }}
        />
      ) : null}
      <TextFieldListItem
        label='Name'
        value={inverter.name}
        onChange={(e) =>
          handleChange({ target: { id: 'name', value: e.target.value } })
        }
      />
      <TextFieldListItem
        id='parent_index'
        label='Index'
        value={inverter.parent_index}
        onChange={handleChange}
      />
      <TextFieldListItem
        label='Manufacturer'
        id='manufacturer'
        select
        value={inverter.manufacturer}
        fullWidth
        onChange={(e) => {
          setInverter({ ...inverter, manufacturer: e.target.value });
        }}>
        <MenuItem value={''}></MenuItem>
        <MenuItem value='Chint Power Systems'>
          Chint Power Systems/Yaskawa
        </MenuItem>
        <MenuItem value='Canadian Solar Inc'>Canadian Solar Inc</MenuItem>
        <MenuItem value='SMA Solar Technology'>SMA Solar Technology</MenuItem>
      </TextFieldListItem>
      <TextFieldListItem
        id='serial_number'
        label='Serial Number'
        value={inverter.serial_number}
        onChange={handleChange}
      />
      <IntegerFieldListItem
        id='dc_size'
        label='DC Size'
        value={inverter.dc_size ? inverter.dc_size : ''}
        onChange={handleChange}
        InputProps={{
          endAdornment: <InputAdornment position='end'>kW</InputAdornment>,
        }}
      />
      <IntegerFieldListItem
        id='ac_size'
        label='AC Size'
        value={inverter.ac_size ? inverter.ac_size : ''}
        onChange={handleChange}
        InputProps={{
          endAdornment: <InputAdornment position='end'>kW</InputAdornment>,
        }}
      />
      <SelectSensor
        sensorId={inverter.sensor_id}
        sensors={orgSensors}
        handleSelect={(e) => {
          setInverter({ ...inverter, sensor_id: e.target.value });
        }}
      />
    </List>
  );
}

Form.propTypes = {
  mode: PropTypes.string,
  inverter: PropTypes.object,
  setInverter: PropTypes.func,
  handleClose: PropTypes.func,
};

export default Form;
