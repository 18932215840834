import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import get from 'lodash/get';

import { getGeneratorsAPI } from '../../api';

const getGenerators = createAsyncThunk(
  'generators/getGenerators',
  async (orgId, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().generators;
    const { item: user } = getState().user;

    if (loading !== true || requestId !== currentRequestId || !user) {
      return;
    }

    try {
      dispatch(showLoading());
      const payload = await getGeneratorsAPI(orgId);

      const generators = get(payload, 'generators', []);
      const meters = get(payload, 'meters', []);

      return {
        data: generators,
        generatorMeters: meters,
        orgId,
      };
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { getGenerators };
