import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { closeDialog } from '../../store/dialogs';

function ConfirmDialog() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { title, message, onConfirm } = useSelector(
    (state) => state.dialogs.confirm
  );

  const handleClose = () => {
    dispatch(closeDialog('confirm'));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onConfirm();
    handleClose();
  };

  return (
    <Dialog open={title !== ''} onClose={handleClose} fullScreen={fullScreen}>
      <form onSubmit={handleSubmit} style={theme.dialog.form}>
        <DialogTitle sx={{ p: 1, pl: 2, pb: 1 }}>{title}</DialogTitle>
        <DialogContent
          sx={{
            overflow: 'inherit',
            p: 2,
            pt: 0,
            pb: 0,
          }}>
          {message}
        </DialogContent>
        <DialogActions sx={{ p: '4px' }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type='submit'
            onClick={handleSubmit}
            color='primary'
            variant='contained'>
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default ConfirmDialog;
