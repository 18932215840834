import React from 'react';
import { useSelector } from 'react-redux';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

import useSensor from '../../../../store/hooks/useSensor';
import useMeter from '../../../../store/hooks/useMeter';
import useSite from '../../../../store/hooks/useSite';
import useDeviceAlarms from '../../../../store/hooks/useDeviceAlarms';
import SensorChart from '../../../../components/charts/dashboard/SensorChart';
import AlarmsTable from '../../../../components/table/tables/AlarmsTable';
import SensorInfo from './SensorInfo';

function SensorDashboard() {
  const { id } = useSelector((state) => state.pages.sensor);
  const sensor = useSensor(id);
  const meter = useMeter(sensor.meter_id);
  const site = useSite(meter.site_id);
  const alarms = useDeviceAlarms(id);

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={2}
      padding={2}
      sx={{ pl: 4, pr: 3 }}>
      <Grid item xs={4}>
        <SensorInfo sensor={sensor} />
      </Grid>
      <Grid item xs={12}>
        <Card raised>
          <CardContent sx={{ p: 1, pb: '4px !important' }}>
            <SensorChart
              height={250}
              site={site}
              sensor={sensor}
              hideAxisLabels={false}
            />
          </CardContent>
        </Card>
      </Grid>
      {alarms.length > 0 ? (
        <AlarmsTable title={true} resource={sensor} alarms={alarms} />
      ) : null}
    </Grid>
  );
}

export default SensorDashboard;
