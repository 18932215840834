import React from 'react';
import { useSelector } from 'react-redux';

import useTheme from '@mui/material/styles/useTheme';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import useLogger from '../../../../store/hooks/useLogger';
import useSite from '../../../../store/hooks/useSite';
import useMeter from '../../../../store/hooks/useMeter';
import useDeviceAlarms from '../../../../store/hooks/useDeviceAlarms';
import ProductionSums from './ProductionSums';
import MeterInfo from './MeterInfo';
import MeterChart from '../../../../components/charts/dashboard/MeterChart';
import AlarmsTable from '../../../../components/table/tables/AlarmsTable';

function MeterDashboard() {
  const theme = useTheme();
  const { id } = useSelector((state) => state.pages.meter);
  const meter = useMeter(id);
  const site = useSite(meter.site_id);
  const logger = useLogger(meter.logger_id);
  const alarms = useDeviceAlarms(id);

  if (!logger.active) {
    return (
      <Card raised sx={{ m: 4, mt: 8 }}>
        <CardContent
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 500,
          }}>
          <Typography color={theme.palette.veregy_colors.grey}>
            Meter Inactive
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={2}
      padding={2}
      sx={{ pl: 4, pr: 3 }}>
      <Grid item xs={4}>
        <MeterInfo logger={logger} meter={meter} />
      </Grid>
      <Grid item xs={4}>
        <ProductionSums logger={logger} meter={meter} />
      </Grid>
      <Grid item xs={12}>
        <Card raised>
          <CardContent sx={{ p: 1, pb: '4px !important' }}>
            <MeterChart site={site} meter={meter} />
          </CardContent>
        </Card>
      </Grid>
      {alarms.length > 0 ? (
        <AlarmsTable title={true} resource={meter} alarms={alarms} />
      ) : null}
    </Grid>
  );
}

export default MeterDashboard;
