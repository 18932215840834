import { createSlice } from '@reduxjs/toolkit';
import { buildAsyncReducers } from '../thunkTemplate';
import { loggers as initialState } from '../initialState';

import {
  getLoggers,
  refreshLoggers,
  postLogger,
  putLogger,
  deleteLogger,
} from './_loggers';

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { reducer, actions } = createSlice({
  name: 'loggers',
  initialState,
  reducers: {
    setLoggers: (state, { payload }) => ({
      ...state,
      data: payload,
    }),
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [
      getLoggers,
      refreshLoggers,
      postLogger,
      putLogger,
      deleteLogger,
    ]);
  },
});

// Extract each action creator by name
const { setLoggers } = actions;

// Export the reducer, either as a default or named export
export {
  getLoggers,
  refreshLoggers,
  postLogger,
  putLogger,
  deleteLogger,
  setLoggers,
};
export default reducer;
