import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import startCase from 'lodash/startCase';

import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';

import { closeDialog, openConfirmDialog } from '../../../store/dialogs';
import DialogTitleOptions from '../DialogTitleOptions';
import { navigate } from '../../../store/pages';
import {
  deleteLogger,
  putLogger,
  postLogger,
} from '../../../store/loggers/_loggers';
import Form from './Form';
import useVerifyOrgRole from '../../../store/hooks/useVerifyOrgRole';
import useLogger, { defaultState } from '../../../store/hooks/useLogger';
import useSite from '../../../store/hooks/useSite';

function LoggerDialog() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { id, mode, siteId } = useSelector((state) => state.dialogs.logger);

  const loggerItem = useLogger(id);
  const site = useSite(siteId || loggerItem.site_id);

  const [logger, setLogger] = useState(defaultState);

  const isOrgAdmin = useVerifyOrgRole(logger?.org_id, 0);
  const isOrgEditor = useVerifyOrgRole(logger?.org_id, 1);

  useEffect(() => {
    if (loggerItem.logger_id) {
      setLogger({ ...loggerItem });
    } else if (site.site_id) {
      setLogger((_logger) => ({
        ..._logger,
        org_id: site.org_id,
        site_id: site.site_id,
      }));
    }
  }, [loggerItem, site]);

  const handleClose = () => {
    dispatch(closeDialog('logger'));
    setLogger(defaultState);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    switch (mode) {
      case 'create':
        dispatch(postLogger(logger));
        break;

      case 'edit':
        dispatch(putLogger(logger));
        break;

      default:
        break;
    }

    handleClose();
  };

  const handleDelete = () => {
    handleClose();
    dispatch(
      openConfirmDialog({
        title: 'Delete Logger',
        message:
          'Are you sure you want to delete this logger? Deleting this logger will also delete all child meters, inverters and sensors.',
        onConfirm: () => dispatch(deleteLogger(logger)),
      })
    );
  };

  const handleOpenConfigView = (view) => {
    dispatch(
      navigate({
        page: 'site',
        id: logger.site_id,
        tab: 'config',
        view,
      })
    );
    handleClose();
  };

  return (
    <Dialog open={id !== ''} onClose={handleClose} fullScreen={fullScreen}>
      <form onSubmit={handleSubmit} style={theme.dialog.form}>
        <DialogTitle sx={{ p: 1, pl: 2, pb: 1 }}>
          {startCase(mode) + ' Logger'}
          <DialogTitleOptions mode={mode} handleClose={handleClose}>
            <MenuItem onClick={() => handleOpenConfigView('alarms')}>
              Alarms
            </MenuItem>
            {isOrgAdmin && mode === 'edit' && (
              <MenuItem onClick={handleDelete}>Delete</MenuItem>
            )}
          </DialogTitleOptions>
        </DialogTitle>
        <DialogContent
          sx={{
            overflow: 'inherit',
            p: 2,
            pt: 0,
            pb: 0,
          }}>
          <Form
            site={site}
            mode={mode}
            logger={logger}
            setLogger={setLogger}
            handleClose={handleClose}
          />
        </DialogContent>
        <DialogActions sx={{ p: '4px' }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type='submit'
            onClick={handleSubmit}
            color='primary'
            variant='contained'
            disabled={
              (mode === 'edit' && !isOrgEditor) ||
              (mode === 'create' && !isOrgAdmin)
            }>
            {(() => {
              switch (mode) {
                case 'create':
                  return 'Create';
                case 'edit':
                  return 'Update';
                default:
                  return null;
              }
            })()}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default LoggerDialog;
