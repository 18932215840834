import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import find from 'lodash/find';
import get from 'lodash/get';
import startCase from 'lodash/startCase';

import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { closeDialog, openDialog } from '../../../store/dialogs';
import {
  createTriggerDevices,
  deleteTriggerDevices,
} from '../../../store/alarms';
import DialogTitleOptions from '../DialogTitleOptions';
import { TextFieldListItem } from '../../List/TextFieldListItem';
import {
  createSubscriptions,
  deleteSubscriptions,
} from '../../../store/user/_users';
import useVerifyOrgRole from '../../../store/hooks/useVerifyOrgRole';
import useDevice from '../../../store/hooks/useDevice';
import useTrigger from '../../../store/hooks/useTrigger';

function TriggerDeviceSubscriptionDialog() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { item: user, subscriptions } = useSelector((state) => state.user);
  const triggerDevice = useSelector((state) => state.dialogs.trigger_device);

  const trigger = useTrigger(triggerDevice.trigger_id);
  const device = useDevice(triggerDevice.device_id);
  const isOrgEditor = useVerifyOrgRole(triggerDevice.org_id, 1);

  const [active, setActive] = useState(false);
  const [subscribed, setSubscribed] = useState(false);

  useEffect(() => {
    setActive(!!triggerDevice.trigger_device_id);
  }, [triggerDevice.trigger_device_id]);

  useEffect(() => {
    setSubscribed(!!triggerDevice.subscription_id);
  }, [triggerDevice.subscription_id]);

  const handleActiveClick = () => {
    if (active) {
      setSubscribed(false);
    }
    setActive(!active);
  };

  const handleClose = () => {
    dispatch(closeDialog('trigger_device'));
    setActive(false);
    setSubscribed(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (active !== !!triggerDevice.trigger_device_id) {
      if (active) {
        const devices = [{ ...device, trigger_id: trigger.trigger_id }];
        dispatch(createTriggerDevices(devices));
      } else {
        dispatch(deleteTriggerDevices([triggerDevice]));
      }
    }

    if (subscribed !== !!triggerDevice.subscription_id) {
      if (subscribed) {
        dispatch(
          createSubscriptions([
            {
              userId: user.user_id,
              orgId: device.org_id,
              deviceId: triggerDevice.device_id,
              triggerId: triggerDevice.trigger_id,
            },
          ])
        );
      } else {
        const subscription = find(subscriptions, {
          trigger_id: triggerDevice.trigger_id,
          device_id: triggerDevice.device_id,
        });
        dispatch(deleteSubscriptions([subscription]));
      }
    }

    handleClose();
  };

  return (
    <Dialog
      open={triggerDevice.trigger_id !== ''}
      onClose={handleClose}
      fullScreen={fullScreen}>
      <form style={{ minWidth: '30rem' }}>
        <DialogTitle sx={{ p: 1, pl: 2, pb: 1 }}>
          Update Alarm Settings
          <DialogTitleOptions handleClose={handleClose} />
        </DialogTitle>
        <DialogContent
          sx={{
            minWidth: theme.dialog.form.minWidth,
            p: 2,
            pt: 0,
            pb: 0,
          }}>
          <List disablePadding>
            <ListItem sx={{ pl: 0, pr: 0, pt: 1, pb: 1 }}>
              <Link
                underline='hover'
                sx={{ color: theme.palette.veregy_colors.blue }}
                onClick={() => {
                  handleClose();
                  dispatch(
                    openDialog({
                      type: 'trigger',
                      id: trigger.trigger_id,
                    })
                  );
                }}>
                {startCase(get(trigger, 'trigger_id')) + ' Trigger'}
              </Link>
            </ListItem>
            <TextFieldListItem
              label='Device'
              value={device.name || ''}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
                fullWidth: true,
              }}
            />
            <ListItem sx={{ pl: 0, pr: 0, pt: 1, pb: 1 }}>
              <ListItemButton onClick={handleActiveClick} dense>
                <ListItemIcon
                  sx={{
                    height: 15,
                    '&.MuiListItemIcon-root': { minWidth: 40 },
                  }}>
                  <Checkbox
                    edge='start'
                    checked={active}
                    tabIndex={-1}
                    disableRipple
                    disabled={!isOrgEditor}
                  />
                </ListItemIcon>
                <ListItemText
                  id='active'
                  primary={`Alarm trigger ${active ? 'active' : 'inactive'}`}
                />
              </ListItemButton>
            </ListItem>
            <ListItem sx={{ pl: 0, pr: 0, pt: 1, pb: 1 }}>
              <ListItemButton
                disabled={!active}
                onClick={() => setSubscribed(!subscribed)}
                dense>
                <ListItemIcon
                  sx={{
                    height: 15,
                    '&.MuiListItemIcon-root': { minWidth: 40 },
                  }}>
                  <Checkbox
                    edge='start'
                    checked={subscribed}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText
                  id='subscribed'
                  primary={`${user?.email} ${
                    subscribed ? 'is subscribed' : 'is not subscribed'
                  }`}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions sx={{ p: '4px' }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type='submit'
            onClick={handleSubmit}
            color='primary'
            variant='contained'
            disabled={
              active === !!triggerDevice.trigger_device_id &&
              subscribed === triggerDevice.subscription_id
            }>
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default TriggerDeviceSubscriptionDialog;
