import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import find from 'lodash/find';

const defaultState = {
  org_id: '',
  name: '',
  address: '',
  location: { lat: '', lng: '' },
  timezone: {
    name: '',
    zone: '',
    offset: '',
  },
  is_portfolio: false,
};

const useOrganization = (orgId) => {
  const organizations = useSelector((state) => state.organizations.data);
  const [organization, setOrganization] = useState({});

  useEffect(() => {
    if (orgId && orgId.startsWith('org') && organizations.length) {
      setOrganization(find(organizations, { org_id: orgId }));
    } else setOrganization(defaultState);
  }, [organizations, orgId]);

  return organization;
};

export { defaultState };
export default useOrganization;
