import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import startCase from 'lodash/startCase';

import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';

import { navigate } from '../../../store/pages';
import { closeDialog, openConfirmDialog } from '../../../store/dialogs';
import { deleteSensor, postSensor, putSensor } from '../../../store/sensors';
import useSensor, { defaultState } from '../../../store/hooks/useSensor';
import useMeter from '../../../store/hooks/useMeter';
import useVerifyOrgRole from '../../../store/hooks/useVerifyOrgRole';
import DialogTitleOptions from '../DialogTitleOptions';
import Form from './Form';

function SensorDialog() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { id, mode, meterId } = useSelector((state) => state.dialogs.sensor);
  const sensorItem = useSensor(id);
  const meter = useMeter(meterId || sensorItem?.meter_id);

  const isOrgAdmin = useVerifyOrgRole(sensorItem?.org_id, 0);
  const isOrgEditor = useVerifyOrgRole(sensorItem?.org_id, 1);

  const [sensor, setSensor] = useState(defaultState);

  useEffect(() => {
    if (sensorItem.sensor_id) {
      setSensor({ ...sensorItem });
    } else if (meter.meter_id) {
      setSensor((sensor) => ({
        ...sensor,
        org_id: meter.org_id,
        meter_id: meter.meter_id,
      }));
    }
  }, [sensorItem, meter]);

  const handleClose = () => {
    dispatch(closeDialog('sensor'));
    setSensor(defaultState);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    switch (mode) {
      case 'create':
        dispatch(postSensor(sensor));
        break;

      case 'edit':
        dispatch(putSensor(sensor));
        break;

      default:
        break;
    }

    handleClose();
  };

  const handleDelete = () => {
    handleClose();
    dispatch(
      openConfirmDialog({
        title: 'Delete Sensor',
        message: 'Are you sure you want to delete this sensor?',
        onConfirm: () => dispatch(deleteSensor(sensor)),
      })
    );
  };

  const handleOpenConfigView = (view) => {
    dispatch(
      navigate({
        page: 'sensor',
        id: sensor.sensor_id,
        tab: 'config',
        view,
      })
    );
    handleClose();
  };

  return (
    <Dialog open={id !== ''} onClose={handleClose} fullScreen={fullScreen}>
      <form onSubmit={handleSubmit} style={theme.dialog.form}>
        <DialogTitle sx={{ p: 1, pl: 2, pb: 0 }}>
          {startCase(mode)} Sensor
          <DialogTitleOptions mode={mode} handleClose={handleClose}>
            <MenuItem onClick={() => handleOpenConfigView('alarms')}>
              Alarms
            </MenuItem>
            {isOrgAdmin && mode === 'edit' && (
              <MenuItem onClick={handleDelete}>Delete</MenuItem>
            )}
          </DialogTitleOptions>
        </DialogTitle>
        <DialogContent
          sx={{
            overflow: 'inherit',
            p: 2,
            pt: 0,
            pb: 0,
          }}>
          <Form
            mode={mode}
            meter={meter}
            sensor={sensor}
            setSensor={setSensor}
            handleClose={handleClose}
          />
        </DialogContent>
        <DialogActions sx={{ p: '4px' }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type='submit'
            onClick={handleSubmit}
            color='primary'
            variant='contained'
            disabled={
              (mode === 'edit' && !isOrgEditor) ||
              (mode === 'create' && !isOrgAdmin)
            }>
            {(() => {
              switch (mode) {
                case 'create':
                  return 'Create';
                case 'edit':
                  return 'Update';
                default:
                  return null;
              }
            })()}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default SensorDialog;
