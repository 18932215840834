import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import find from 'lodash/find';
import findKey from 'lodash/findKey';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';

import { setPage } from '../../../store/pages';
import useLoggers from '../../../store/hooks/useLoggers';
import useMeters from '../../../store/hooks/useMeters';
import TabBar from '../../../components/tab/TabBar';
import ROUTES from '../../../constants/routes';
import Dashboard from './Dashboard';
import Configuration from './Configuration';
import Analysis from './Anlaysis';
import useOrganization from '../../../store/hooks/useOrganization';

const TABS = {
  0: '#dashboard',
  1: '#analysis',
  2: '#config',
};

export default function Portfolio() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState(0);
  const { id, tab } = useSelector((state) => state.pages.portfolio);
  const portfolio = useOrganization(id);
  useLoggers(id);
  useMeters(id);

  useEffect(() => {
    if (location.hash && !tab) {
      let _tab = find(TABS, (value) => value === location.hash);
      dispatch(setPage({ page: 'portfolio', tab: _tab, view: '' }));
    } else if (tab) {
      let _tabIndex = findKey(TABS, (_tab) => {
        return includes(_tab, tab);
      });

      if (_tabIndex) {
        setTabIndex(Number(_tabIndex));
      }
    } else {
      setTabIndex(0);
    }
  }, [dispatch, location.hash, tab]);

  useEffect(() => {
    if (!isEmpty(portfolio) && !get(portfolio, 'is_portfolio')) {
      dispatch(push(ROUTES.AUTH.ORGANIZATION));
    }
  }, [dispatch, portfolio]);

  const handleChangeTab = (event, value) => {
    dispatch(push(ROUTES.AUTH.PORTFOLIO + TABS[value]));
    dispatch(setPage({ page: 'portfolio', tab: TABS[value], view: '' }));
  };

  return (
    <TabBar
      tabNames={['dashboard', 'analysis', 'configuration']}
      handleChangeTab={handleChangeTab}
      tabIndex={tabIndex}
      title={get(portfolio, 'name')}>
      <Dashboard />
      <Analysis />
      <Configuration />
    </TabBar>
  );
}
