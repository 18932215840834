import { createSlice } from '@reduxjs/toolkit';
import concat from 'lodash/concat';

import { buildAsyncReducers } from '../thunkTemplate';
import { alarms as initialState } from '../initialState';
import {
  getAlarms,
  getAllAlarms,
  acknowledgeAlarm,
  closeAlarm,
  deleteAlarm,
  createTriggerDevices,
  deleteTriggerDevices,
} from './_alarms';

const { reducer, actions } = createSlice({
  name: 'alarms',
  initialState,
  reducers: {
    updateTriggerDevices: (state, { payload }) => {
      let triggerDevices = concat(state.triggerDevices, payload);
      return {
        ...state,
        triggerDevices,
      };
    },
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [
      getAlarms,
      getAllAlarms,
      acknowledgeAlarm,
      closeAlarm,
      deleteAlarm,
      createTriggerDevices,
      deleteTriggerDevices,
    ]);
  },
});

const { updateTriggerDevices } = actions;

export {
  getAlarms,
  getAllAlarms,
  acknowledgeAlarm,
  closeAlarm,
  deleteAlarm,
  createTriggerDevices,
  deleteTriggerDevices,
  updateTriggerDevices,
};
export default reducer;
