import React from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

import styled from '@mui/material/styles/styled';
import useTheme from '@mui/material/styles/useTheme';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { displaykW } from '../../../../helpers/display-energy';
import useInverter from '../../../../store/hooks/useInverter';
import useMeter from '../../../../store/hooks/useMeter';
import useSite from '../../../../store/hooks/useSite';
import useDeviceAlarms from '../../../../store/hooks/useDeviceAlarms';
import InverterChart from '../../../../components/charts/dashboard/InverterChart';
import InfoGrid from '../../../../components/Grid/InfoGrid';
import AlarmsTable from '../../../../components/table/tables/AlarmsTable';

const BottomContent = styled('div')(() => ({ marginBottom: 96 }));

const BottomPanel = styled('div')(({ theme }) => ({
  height: 10,
  width: '100%',
  bottom: 0,
  backgroundColor: theme.palette.veregy_colors.green,
}));

function InverterDashboard() {
  const theme = useTheme();
  const { id } = useSelector((state) => state.pages.inverter);
  const inverter = useInverter(id);
  const meter = useMeter(inverter.meter_id);
  const site = useSite(meter.site_id);
  const alarms = useDeviceAlarms(id);

  const acSize = inverter.ac_size ? inverter.ac_size + ' kW' : '-';
  const dcSize = inverter.dc_size ? inverter.dc_size + ' kW' : '-';

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={2}
      padding={2}
      sx={{ pl: 4, pr: 3 }}>
      <InfoGrid
        title={
          <Typography align='left' variant='h5'>
            {inverter?.name}
          </Typography>
        }
        attribute1={{ label: 'State', value: get(inverter, 'state', '') }}
        attribute2={{
          label: 'Power Today',
          value: displaykW(get(inverter, 'today')),
        }}
        color={theme.palette.veregy_colors.grey}
      />
      <InfoGrid
        attribute1={{ label: 'DC Size', value: dcSize }}
        attribute2={{ label: 'AC Size', value: acSize }}
        color={theme.palette.veregy_colors.orange}
      />
      <Grid item xs={4}>
        <Card raised>
          <CardContent sx={{ p: 2, pb: '0px !important' }}>
            <TextField
              id='manufacturer'
              label='Manufacturer'
              value={get(inverter, 'manufacturer', '')}
              fullWidth
              InputProps={{
                readOnly: true,
                disableUnderline: true,
                fullWidth: true,
              }}
              variant='standard'
            />
            <BottomContent />
          </CardContent>
          <BottomPanel />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card raised>
          <CardContent sx={{ p: 1, pb: '4px !important' }}>
            <InverterChart site={site} inverter={inverter} />
          </CardContent>
        </Card>
      </Grid>
      {alarms.length > 0 ? (
        <AlarmsTable title={true} resource={inverter} alarms={alarms} />
      ) : null}
    </Grid>
  );
}

export default InverterDashboard;
