import axios from '../xhr_libs/axios';
import { processResponse } from '.';

export const getReportsAPI = async () => {
  return processResponse(await axios.get('/resource/reports'));
};

export const postReportAPI = async (body) => {
  return processResponse(await axios.post(`/resource/reports`, body));
};

export const putReportAPI = async (report) => {
  return processResponse(
    await axios.put(`/resource/reports/${report.report_id}`, report)
  );
};

export const deactivateReportAPI = async (reportId) => {
  return processResponse(await axios.delete(`/resource/reports/${reportId}`));
};
