import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import find from 'lodash/find';

const defaultState = {
  org_id: '',
  meter_id: '',
  name: '',
  parent_index: '',
  logger_id: '',
};

const useMeter = (meterId) => {
  const meters = useSelector((state) => state.meters.data);
  const [meter, setMeter] = useState({});

  useEffect(() => {
    if (meterId && meterId.startsWith('meter') && meters.length) {
      setMeter(find(meters, { meter_id: meterId }));
    } else setMeter(defaultState);
  }, [meters, meterId]);

  return meter;
};

export default useMeter;
