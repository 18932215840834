import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';

import BaseTable from '../../../components/table/tables/BaseTable';
import { MembershipRoleTypeProvider } from '../../../components/table/providers';

const UserMembershipsTable = () => {
  const { memberships: allMemberships } = useSelector((state) => state.user);
  const { data: organizations } = useSelector((state) => state.organizations);

  const [memberships, setMemberships] = useState([]);

  const columns = [
    { title: 'Organization', name: 'name', width: 0.35 },
    { title: 'Role', name: 'role', width: 0.35 },
  ];

  useEffect(() => {
    if (!isEmpty(allMemberships) && !isEmpty(organizations)) {
      setMemberships(
        map(allMemberships, (membership) => {
          const organization = find(organizations, {
            org_id: membership.org_id,
          });
          return {
            name: organization.name,
            role: membership.role,
          };
        })
      );
    }
  }, [allMemberships, organizations]);

  return (
    <Card raised>
      <CardContent sx={{ p: 1, pt: 0, pb: '4px !important' }}>
        <BaseTable rows={memberships} columns={columns}>
          <MembershipRoleTypeProvider for={['role']} />

          <FilteringState />
          <IntegratedFiltering />

          <SortingState
            defaultSorting={[{ columnName: 'name', direction: 'asc' }]}
          />
          <IntegratedSorting />

          <PagingState defaultCurrentPage={0} pageSize={10} />
          <IntegratedPaging />
        </BaseTable>
      </CardContent>
    </Card>
  );
};

export default UserMembershipsTable;
