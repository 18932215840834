import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export const types = ['site', 'logger', 'meter', 'inverter', 'sensor'];

function SelectDeviceType(props) {
  const { selectedType, setSelectedType } = props;

  const [type, setType] = useState('');

  useEffect(() => {
    setType(selectedType);
  }, [selectedType]);

  const handleSelect = (e) => {
    e.preventDefault();
    setSelectedType(e.target.value);
  };

  return (
    <Card sx={{ width: '20%', minWidth: 125 }}>
      <CardContent sx={{ p: 0, pb: '0 !important' }}>
        <FormControl variant='filled' size='small' sx={{ width: '100%' }}>
          <InputLabel id='type-label'>Device Type</InputLabel>
          <Select
            label='Device Type'
            labelId='type-label'
            id='type-select'
            disableUnderline
            value={type}
            onChange={handleSelect}
            sx={{
              backgroundColor: 'unset',
              '& .MuiSelect-select': { display: 'flex', alignItems: 'center' },
            }}
            MenuProps={{
              MenuListProps: { disablePadding: true },
            }}>
            <MenuItem id='empty-menu-item' value=''>
              none
            </MenuItem>
            {types.map((type) => {
              return (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </CardContent>
    </Card>
  );
}

SelectDeviceType.propTypes = {
  selectedType: PropTypes.string,
  setSelectedType: PropTypes.func,
};

export default SelectDeviceType;
