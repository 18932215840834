import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import get from 'lodash/get';

import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { generateRanges } from '../../../helpers/dates';

function SelectRange(props) {
  const { range, handleRangeSelect, timezone } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [ranges, setRanges] = useState([]);
  const [customRange, setCustomRange] = useState({});

  useEffect(() => {
    setShowDatePicker(false);
    const _ranges = generateRanges(true, get(timezone, 'offset', 0));
    setRanges(_ranges);
    setCustomRange(_ranges[6]);
  }, [timezone]);

  const handleClose = () => {
    setOpen(false);
  };

  const selectRange = (e) => {
    e.preventDefault();
    const newRange = find(ranges, { label: e.target.value });
    if (newRange.key === 6) {
      if (range.key === 6 && showDatePicker) setShowDatePicker(false);
      else {
        setOpen(true);
      }
    } else {
      handleRangeSelect(newRange);
      handleClose();
    }
  };

  const handleSelectDay = (day, attr) => {
    if (attr === 'start') {
      day = day?.startOf('day');
    } else {
      day = day?.endOf('day');
    }

    const _customRange = cloneDeep(customRange);
    setCustomRange({
      ..._customRange,
      [attr]: day?.valueOf(),
    });
  };

  const handleSubmit = () => {
    handleRangeSelect(customRange);
    handleClose();
  };

  let rangeLabel = get(range, 'label', '');
  if (range && range.key === 6)
    rangeLabel = `${dayjs(range.start).format('MMM D')} - ${dayjs(
      range.end
    ).format('MMM D')}`;

  return (
    <>
      <Select
        disableUnderline
        id='range-select'
        value={!ranges.length ? '' : rangeLabel}
        variant='standard'
        onChange={selectRange}
        sx={{ width: range?.key === 6 ? '7rem' : '5rem', textAlign: 'center' }}
        IconComponent={() => null}
        inputProps={{
          sx: {
            padding: '0 !important',
            color: theme.palette.primary.main,
            ...theme.typography.body2,
          },
        }}
        MenuProps={{ MenuListProps: { disablePadding: true } }}>
        {ranges.map((range) => {
          return (
            <MenuItem
              sx={{
                color: theme.palette.primary.main,
                ...theme.typography.body2,
              }}
              key={range.label}
              value={range.label}>
              {range.label}
            </MenuItem>
          );
        })}
        {range && range.key === 6 ? (
          <MenuItem
            sx={{
              color: theme.palette.primary.main,
              ...theme.typography.body2,
            }}
            key={rangeLabel}
            value={rangeLabel}>
            {rangeLabel}
          </MenuItem>
        ) : null}
      </Select>
      <Dialog open={open} onClose={handleClose} fullScreen={fullScreen}>
        <DialogTitle sx={{ p: 1 }}>Select Date Range</DialogTitle>
        <DialogContent sx={{ pl: 2, pr: 2, pb: 1 }}>
          <Stack direction='row' spacing={2}>
            <DatePicker
              disableFuture
              openTo='month'
              views={['year', 'month', 'day']}
              label='Start'
              value={dayjs(customRange.start)}
              onChange={(newValue) => {
                handleSelectDay(newValue, 'start');
              }}
              slotProps={{
                textField: {
                  variant: 'standard',
                  inputProps: {
                    readOnly: true,
                  },
                },
              }}
            />
            <DatePicker
              disableFuture
              openTo='month'
              views={['year', 'month', 'day']}
              label='End'
              value={dayjs(customRange.end)}
              minDate={dayjs(customRange.start)}
              onChange={(newValue) => {
                handleSelectDay(newValue, 'end');
              }}
              slotProps={{
                textField: {
                  variant: 'standard',
                  inputProps: {
                    readOnly: true,
                  },
                },
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: '4px' }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant='contained'
            onClick={handleSubmit}
            disabled={
              customRange.start === undefined || customRange.end === undefined
            }>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

SelectRange.propTypes = {
  range: PropTypes.object,
  setRange: PropTypes.func,
  timezone: PropTypes.object,
};

export default SelectRange;
