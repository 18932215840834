import React from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function DefaultOrganizationSelect({
  organizations,
  portfolios,
  handleChange,
  defaultOrganization,
}) {
  return (
    <ListItem sx={{ pl: 0, pr: 0, pt: 1, pb: 1 }}>
      <FormControl variant='standard' fullWidth>
        <InputLabel id='default-organization-label'>
          Default Organization
        </InputLabel>
        <Select
          labelId='default-organization-label'
          id='default_organization'
          value={
            portfolios.length || organizations.length ? defaultOrganization : ''
          }
          onChange={handleChange}
          label='Default Organization'
          sx={{
            '& .MuiSelect-select': {
              display: 'flex',
              alignItems: 'center',
            },
            '& .MuiListItemIcon-root': {
              minWidth: '35px',
            },
          }}
          MenuProps={{ MenuListProps: { disablePadding: true } }}>
          <MenuItem id={`empty-menu-item`} value={''} />
          {portfolios.length > 0 && <ListSubheader>Portfolios</ListSubheader>}
          {portfolios.map((portfolio) => (
            <MenuItem
              key={portfolio.org_id}
              id={`${portfolio.org_id}-menu-item`}
              value={portfolio.org_id}>
              <ListItemIcon>
                <FontAwesomeIcon icon={['fal', 'folder']} />
              </ListItemIcon>
              <Typography variant='inherit'>{portfolio.name}</Typography>
            </MenuItem>
          ))}
          {organizations.length > 0 && (
            <ListSubheader>Organizations</ListSubheader>
          )}
          {organizations.map((organization) => (
            <MenuItem
              key={organization.org_id}
              id={`${organization.org_id}-menu-item`}
              value={organization.org_id}>
              <ListItemIcon>
                <FontAwesomeIcon icon={['fal', 'buildings']} />
              </ListItemIcon>
              <Typography variant='inherit'>{organization.name}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ListItem>
  );
}

export default DefaultOrganizationSelect;
