import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import each from 'lodash/each';
import get from 'lodash/get';
import indexOf from 'lodash/indexOf';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { default as _range } from 'lodash/range';
import remove from 'lodash/remove';

import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';

import {
  Area,
  Bar,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import Loader from '../../../Loaders/ComponentLoader';

import ChartTooltip from './Tooltip';
import { chartColor } from '../../../../styles/muiTheme';

const compileChartData = (raw, range) => {
  let rawData = cloneDeep(raw);
  return map(
    _range(range.start / 1000, range.end / 1000 + 1, 900),
    (timestamp) => {
      let pointData = { timestamp };
      each(rawData, (deviceData) => {
        let deviceId = get(deviceData, 'device_id');

        let deviceDataPoints = remove(
          get(deviceData, 'records', []),
          (record) => {
            if (
              timestamp <= record.timestamp &&
              record.timestamp < timestamp + 900
            ) {
              return record;
            }
          }
        );

        if (!isEmpty(deviceDataPoints)) {
          if (deviceId.startsWith('sensor')) {
            each(deviceDataPoints, (dataPoint) => {
              let measureName = get(dataPoint, 'measure_name');
              let attrName = measureName.substring(
                0,
                indexOf(measureName, '_')
              );

              pointData[`${deviceId}:${attrName}`] = get(dataPoint, 'value');
            });
          } else if (deviceId.startsWith('meter')) {
            pointData[deviceId] = get(deviceDataPoints, '0.value');
          }
        }
      });
      return pointData;
    }
  );
};
function WeatherStationChart({
  rawData,
  sensors,
  meters,
  range,
  timezone,
  loading,
}) {
  const theme = useTheme();
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (!loading && !isEmpty(rawData)) {
      setChartData(compileChartData(rawData, range));
    }
    /* eslint-disable-next-line */
  }, [rawData, range, loading]);

  if (loading)
    return (
      <div
        style={{
          height: '450px',
        }}>
        <Loader height={150} width={150} />
      </div>
    );

  if (isEmpty(chartData))
    return (
      <div
        style={{
          height: '450px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Typography>Click "Generate" to fetch chart data</Typography>
      </div>
    );

  return (
    <ResponsiveContainer
      key={'analysis-weather-chart'}
      width='100%'
      height={450}
      style={{ mb: 2 }}>
      <ComposedChart
        width='100%'
        height={250}
        data={chartData}
        margin={{
          top: 5,
          right: 0,
          left: 0,
          bottom: 5,
        }}>
        <YAxis yAxisId='kw' hide domain={[0, 'auto']} allowDataOverflow />
        <YAxis
          yAxisId='temp'
          orientation='right'
          domain={[
            (dataMin) => Math.min(0, dataMin),
            (dataMax) => Math.ceil(dataMax / 10) * 10,
          ]}
          tickFormatter={(val, _axis) => {
            return numeral(val.toPrecision(4)).format('0,0.[000]') + ' °C';
          }}
        />
        <YAxis
          yAxisId='radiation'
          domain={[0, (dataMax) => Math.ceil(dataMax / 10) * 10]}
          width={100}
          tickFormatter={(val, _axis) => {
            return numeral(val.toPrecision(4)).format('0,0.[000]') + ' W/m^2';
          }}
        />
        <XAxis
          type='number'
          dataKey='timestamp'
          tickMargin={15}
          domain={[range.start / 1000, range.end / 1000]}
          tickFormatter={(unixTime) => {
            return dayjs(unixTime * 1000)
              .utcOffset(get(timezone, 'offset'))
              .format('MM/DD h:mm A');
          }}
        />
        <Tooltip
          content={
            <ChartTooltip
              timezone={timezone}
              sensors={sensors}
              meters={meters}
            />
          }
        />
        {map(meters, (meter, idx) => {
          return (
            <Bar
              yAxisId='kw'
              stackId='a'
              key={`bar-${meter.meter_id}`}
              type='monotone'
              dataKey={meter.meter_id}
              fill={chartColor(idx)}
            />
          );
        })}
        {map(sensors, (sensor) => (
          <Area
            yAxisId='radiation'
            key={`radiation-area-${sensor.sensor_id}`}
            dataKey={`${sensor.sensor_id}:Radiation`}
            stroke={theme.palette.veregy_colors.yellow}
            fill={theme.palette.veregy_colors.yellow}
          />
        ))}
        {map(sensors, (sensor) => (
          <Line
            yAxisId='temp'
            dot={false}
            key={`temp-line-${sensor.sensor_id}`}
            dataKey={`${sensor.sensor_id}:PanelTemp`}
            stroke={theme.palette.veregy_colors.purple}
            connectNulls
          />
        ))}
      </ComposedChart>
    </ResponsiveContainer>
  );
}

WeatherStationChart.propTypes = {
  chartData: PropTypes.array,
  sensors: PropTypes.array,
  meters: PropTypes.array,
  range: PropTypes.object,
  timezone: PropTypes.object,
  loading: PropTypes.bool,
};

export default WeatherStationChart;
