import React from 'react';

import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { ORG_USER_ROLES as ROLES } from '../../../constants';

export const MembershipRoleTypeProvider = (props) => {
  return (
    <DataTypeProvider
      formatterComponent={({ value }) => {
        return ROLES[value];
      }}
      {...props}
    />
  );
};
