import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';

import useTheme from '@mui/material/styles/useTheme';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export const INTERVALS = [
  { name: '15 min', minutes: 15 },
  { name: '1 h', minutes: 60 },
  { name: '4 h', minutes: 240 },
  { name: '12 h', minutes: 720 },
  { name: '24 h', minutes: 1440 },
];

function SelectInterval(props) {
  const { selectedInterval, setSelectedInterval } = props;
  const theme = useTheme();

  const [interval, setInterval] = useState(INTERVALS[0]);
  const [intervals] = useState(INTERVALS);

  useEffect(() => {
    setInterval(selectedInterval);
  }, [selectedInterval]);

  const handleSelect = (e) => {
    e.preventDefault();
    let _interval = find(intervals, { minutes: e.target.value });
    setSelectedInterval(_interval);
  };

  return (
    <Select
      disableUnderline
      id='interval-select'
      value={interval.minutes}
      variant='standard'
      onChange={handleSelect}
      sx={{ width: '3rem', textAlign: 'center' }}
      IconComponent={() => null}
      inputProps={{
        sx: {
          padding: '0 !important',
          color: theme.palette.primary.main,
          ...theme.typography.body2,
        },
      }}
      MenuProps={{ MenuListProps: { disablePadding: true } }}>
      {intervals.map((interval) => {
        return (
          <MenuItem
            key={interval.minutes}
            value={interval.minutes}
            sx={{
              color: theme.palette.primary.main,
              ...theme.typography.body2,
            }}>
            {interval.name}
          </MenuItem>
        );
      })}
    </Select>
  );
}

SelectInterval.propTypes = {
  selectedInterval: PropTypes.object,
  setSelectedInterval: PropTypes.func,
};

export default SelectInterval;
