import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';

import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { navigate } from '../../../store/pages';
import { TextFieldListItem } from '../../List/TextFieldListItem';

function Form(props) {
  const { mode, site, logger, meter, setMeter, handleClose } = props;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.item);

  const handleChange = (e) => {
    setMeter({ ...meter, [e.target.id]: e.target.value });
  };

  return (
    <List disablePadding>
      <ListItem sx={{ pl: 0, pr: 0, pt: 1, pb: 1 }}>
        <Link
          underline='hover'
          onClick={
            mode === 'edit'
              ? () => {
                  dispatch(
                    navigate({
                      page: site.type_,
                      id: site.site_id,
                    })
                  );
                  handleClose();
                }
              : null
          }>
          {get(site, 'name', '')}
        </Link>
      </ListItem>
      <TextFieldListItem
        id='logger'
        label='Logger'
        value={get(logger, 'name', '')}
        InputProps={{
          readOnly: true,
          disableUnderline: true,
        }}
      />
      <TextFieldListItem
        id='parent_index'
        label='Logger Index'
        value={get(meter, 'parent_index', '')}
        InputProps={{
          readOnly: true,
          disableUnderline: true,
        }}
      />
      {mode === 'edit' && get(user, 'super_user', false) ? (
        <TextFieldListItem
          id='meter_id'
          label='Meter ID'
          value={get(meter, 'meter_id', '')}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
          }}
        />
      ) : null}
      <TextFieldListItem
        label='Name'
        value={get(meter, 'name', '')}
        onChange={(e) =>
          handleChange({ target: { id: 'name', value: e.target.value } })
        }
      />
    </List>
  );
}

Form.propTypes = {
  site: PropTypes.object,
  logger: PropTypes.object,
  meter: PropTypes.object,
  setMeter: PropTypes.func,
  handleClose: PropTypes.func,
};

export default Form;
