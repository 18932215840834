import { createSlice } from '@reduxjs/toolkit';
import { buildAsyncReducers } from '../thunkTemplate';
import { sites as initialState } from '../initialState';

import { postSite, getSites, putSite, deleteSite } from './_sites';

const { reducer, actions } = createSlice({
  name: 'sites',
  initialState,
  reducers: {
    setSites: (state, { payload }) => ({
      ...state,
      data: payload,
    }),
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [postSite, getSites, putSite, deleteSite]);
  },
});

const { setSites } = actions;

export { postSite, getSites, putSite, deleteSite, setSites };
export default reducer;
