import axios from '../xhr_libs/axios';
import { processResponse } from '.';

export const authAPI = async () => {
  return processResponse(await axios.get(`/resource/auth`));
};

export const getUsersAPI = async () => {
  const { data } = await axios.get(`/resource/users`);
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const postUserAPI = async (body) => {
  return processResponse(await axios.post(`/resource/users`, body));
};

export const putUserAPI = async (id, body) => {
  return processResponse(await axios.put(`/resource/users/${id}`, body));
};

export const generateAPIKeyAPI = async (id) => {
  return processResponse(await axios.put(`/resource/generate_api_key/${id}`));
};

export const deleteUserAPI = async (id) => {
  return processResponse(await axios.delete(`/resource/users/${id}`));
};
