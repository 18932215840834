import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import numeral from 'numeral';
import compact from 'lodash/compact';
import find from 'lodash/find';
import map from 'lodash/map';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

function ExternalTooltip(props) {
  const { payload, sites, unit } = props;
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (!payload?.activePayload) {
      setTableData([]);
      return;
    }

    let data = map(sites, (site) => {
      let actual = find(payload?.activePayload, {
        dataKey: `${site.site_id}-actual`,
      });
      let expected = find(payload?.activePayload, {
        dataKey: `${site.site_id}-expected`,
      });

      if (!actual?.value && !expected?.value) {
        return null;
      }

      return {
        id: site.site_id,
        name: site.name,
        actual: actual.value,
        expected: expected.value,
        pi: !expected.value ? 0 : actual.value / expected.value,
      };
    });

    setTableData(compact(data));
  }, [payload?.activePayload, sites]);

  if (tableData.length === 0 || !payload) return null;

  return (
    <Grid item xs={12}>
      <Card raised>
        <CardHeader
          title={dayjs(Number(payload.activeLabel)).format('YYYY MMMM')}
          titleTypographyProps={{ align: 'left' }}
          sx={{ p: 1, pb: 0 }}
        />
        <CardContent sx={{ p: 1, pt: 0, pb: '4px !important' }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ p: 1 }}>Site</TableCell>
                  <TableCell sx={{ p: 1 }} align='right'>
                    Actual
                  </TableCell>
                  <TableCell sx={{ p: 1 }} align='right'>
                    Expected
                  </TableCell>
                  <TableCell sx={{ p: 1 }} align='right'>
                    Production Index
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((site) => {
                  return (
                    <TableRow key={site.id}>
                      <TableCell sx={{ p: 1 }} component='th' scope='row'>
                        {site.name}
                      </TableCell>
                      <TableCell sx={{ p: 1 }} align='right'>
                        {numeral(site.actual).format('0.00') + ' ' + unit}
                      </TableCell>
                      <TableCell sx={{ p: 1 }} align='right'>
                        {numeral(site.expected).format('0.00') + ' ' + unit}
                      </TableCell>
                      <TableCell sx={{ p: 1 }} align='right'>
                        {numeral(site.pi).format('0.00')}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Grid>
  );
}

ExternalTooltip.propTypes = {
  payload: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  sites: PropTypes.array,
  unit: PropTypes.string,
};

export default ExternalTooltip;
