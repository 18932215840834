import React from 'react';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';

import useSensor from '../../../../store/hooks/useSensor';
import AlarmsConfigurationTable from '../../../../components/table/tables/AlarmsConfigurationTable';

export default function Configuration() {
  const { id } = useSelector((state) => state.pages.sensor);
  const sensor = useSensor(id);

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={2}
      padding={2}
      sx={{ pl: 4, pr: 3 }}>
      <Grid item xs={12}>
        <AlarmsConfigurationTable resource={sensor} />
      </Grid>
    </Grid>
  );
}
