import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import concat from 'lodash/concat';
import find from 'lodash/find';
import findKey from 'lodash/findKey';
import get from 'lodash/get';
import lowerCase from 'lodash/lowerCase';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import size from 'lodash/size';
import startCase from 'lodash/startCase';

import { default as MuiBreadcrumbs } from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import { openDialog } from '../../../store/dialogs';
import { navigate } from '../../../store/pages';
import ROUTES from '../../../constants/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const generateHref = (resource) => {
  switch (get(resource, 'type_')) {
    case 'organization':
      if (get(resource, 'is_portfolio')) {
        return ROUTES.AUTH.PORTFOLIO;
      } else return ROUTES.AUTH.ORGANIZATION;

    case 'site':
      return ROUTES.AUTH.SITE;

    case 'logger':
      return ROUTES.AUTH.LOGGER;

    case 'meter':
      return ROUTES.AUTH.METER;

    case 'inverter':
      return ROUTES.AUTH.INVERTER;

    case 'sensor':
      return ROUTES.AUTH.SENSOR;

    default:
      return null;
  }
};

const generateLink = (resource) => {
  const type = get(resource, 'type_');
  const id = get(resource, type === 'organization' ? 'org_id' : `${type}_id`);
  return {
    name: get(resource, 'name'),
    id,
    type,
    href: generateHref(resource),
  };
};

function Breadcrumbs() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [links, setLinks] = useState([]);
  const pages = useSelector((state) => state.pages);
  const { item: user, loading: userLoading } = useSelector(
    (state) => state.user
  );
  const { data: organizations, loading: orgLoading } = useSelector(
    (state) => state.organizations
  );
  const { data: sites, loading: siteLoading } = useSelector(
    (state) => state.sites
  );
  const { data: loggers, loading: loggerLoading } = useSelector(
    (state) => state.loggers
  );
  const { data: meters, loading: meterLoading } = useSelector(
    (state) => state.meters
  );
  const { data: inverters, loading: inverterLoading } = useSelector(
    (state) => state.inverters
  );
  const { data: sensors, loading: sensorLoading } = useSelector(
    (state) => state.sensors
  );

  useEffect(() => {
    if (
      !(
        orgLoading &&
        siteLoading &&
        loggerLoading &&
        meterLoading &&
        inverterLoading &&
        sensorLoading &&
        userLoading
      )
    ) {
      const defaultOrganization = find(organizations, {
        org_id: get(user, 'default_organization'),
      });
      let portfolio = null;
      let organization = null;
      let logger = null;
      let site = null;
      let meter = null;
      let _meter = null;
      let inverter = null;
      let sensor = null;

      switch (location.pathname) {
        case ROUTES.AUTH.PORTFOLIO:
          let portfolio_id = pages.portfolio.id
            ? pages.portfolio.id
            : defaultOrganization?.is_portfolio;
          portfolio = find(organizations, { org_id: portfolio_id });
          break;

        case ROUTES.AUTH.ORGANIZATION:
          organization = find(organizations, {
            org_id: pages.organization.id,
          });
          break;

        case ROUTES.AUTH.SITE:
          site = find(sites, {
            site_id: pages.site.id,
          });
          organization = find(organizations, { org_id: get(site, 'org_id') });
          break;

        case ROUTES.AUTH.LOGGER:
          logger = find(loggers, {
            logger_id: pages.logger.id,
          });
          organization = find(organizations, {
            org_id: get(logger, 'org_id'),
          });
          site = find(sites, { site_id: get(logger, 'site_id') });
          break;

        case ROUTES.AUTH.METER:
          meter = find(meters, {
            meter_id: pages.meter.id,
          });
          organization = find(organizations, {
            org_id: get(meter, 'org_id'),
          });
          site = find(sites, { site_id: get(meter, 'site_id') });
          logger = find(loggers, { logger_id: get(meter, 'logger_id') });
          break;

        case ROUTES.AUTH.INVERTER:
          inverter = find(inverters, {
            inverter_id: pages.inverter.id,
          });
          _meter = find(meters, {
            meter_id: get(inverter, 'meter_id'),
          });
          organization = find(organizations, {
            org_id: get(inverter, 'org_id'),
          });
          logger = find(loggers, {
            logger_id: get(_meter, 'logger_id'),
          });
          site = find(sites, { site_id: get(_meter, 'site_id') });
          break;

        case ROUTES.AUTH.SENSOR:
          sensor = find(sensors, {
            sensor_id: pages.sensor.id,
          });
          _meter = find(meters, {
            meter_id: get(sensor, 'meter_id'),
          });
          organization = find(organizations, {
            org_id: get(sensor, 'org_id'),
          });
          logger = find(loggers, {
            logger_id: get(_meter, 'logger_id'),
          });
          site = find(sites, { site_id: get(_meter, 'site_id') });
          break;

        default:
          break;
      }

      let _links = reduce(
        [portfolio, organization, site, logger, meter, inverter, sensor],
        (acc, resource) => {
          if (resource) return concat(acc, generateLink(resource));
          return acc;
        },
        []
      );

      if (size(_links) === 0) {
        if (location.pathname === ROUTES.AUTH.KPIS) {
          _links = [
            {
              name: 'Key Performance Indicators',
              id: 'kpis-link-key',
            },
          ];
        } else if (location.pathname === ROUTES.AUTH.INDEX) {
          _links = [
            {
              name: '',
              id: 'app-link-key',
            },
          ];
        } else {
          const page = startCase(
            lowerCase(
              findKey(ROUTES.AUTH, (route) => {
                return route === location.pathname;
              })
            )
          );
          _links = [
            {
              name: page,
              id: `${page}-link-key`,
            },
          ];
        }
      }

      setLinks(_links);
    }

    /* eslint-disable-next-line */
  }, [
    pages,
    location.pathname,
    orgLoading,
    siteLoading,
    loggerLoading,
    meterLoading,
    inverterLoading,
    sensorLoading,
  ]);

  const handleClick = (link) => {
    dispatch(
      navigate({
        page: link.type,
        id: link.id,
      })
    );
  };

  const handleButtonClick = (link) => {
    dispatch(
      openDialog({
        type: link.type,
        id: link.id,
        mode: 'edit',
      })
    );
  };

  const renderSettingsIcon = (link) => {
    if (get(user, 'super_user', false) && 'type' in link) {
      return (
        <IconButton
          key='resource-settings-button'
          onClick={() => handleButtonClick(link)}
          sx={{ color: 'white', ml: 1 }}>
          <FontAwesomeIcon icon={['fal', 'cog']} size='sm' />
        </IconButton>
      );
    }
  };

  const renderLinks = () => {
    return map(links, (link, idx) => {
      return (
        <Stack
          key={link.id}
          direction='row'
          justifyContent='center'
          alignItems='center'>
          <Link
            underline='hover'
            color='inherit'
            onClick={() => handleClick(link)}>
            {link.name}
          </Link>
          {idx + 1 === size(links) ? renderSettingsIcon(link) : null}
        </Stack>
      );
    });
  };

  return (
    <MuiBreadcrumbs ml={2} sx={{ pt: '1px', color: 'white' }}>
      {renderLinks()}
    </MuiBreadcrumbs>
  );
}

export default Breadcrumbs;
