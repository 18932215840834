import React, { useState } from 'react';
import { useStateValidator } from 'react-use';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';

import { changePassword } from '../../../helpers/auth/cognito';
import validatePassword from '../../../xhr_libs/password-validator';
import ToastrMessages from '../../../components/ToastrMessages';

const PasswordField = (props) => {
  return (
    <ListItem>
      <TextField
        sx={{ width: '100%' }}
        required
        type='password'
        variant='standard'
        inputProps={{
          autoComplete: 'off',
        }}
        {...props}
      />
    </ListItem>
  );
};

const ChangePasswordForm = () => {
  const dispatch = useDispatch();
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordValid] = useStateValidator(password, (password) => {
    return validatePassword(password);
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (passwordValid.errors.length > 0) {
      toastr.error('Password validation', {
        component: <ToastrMessages messages={passwordValid.errors} />,
      });
    } else if (confirmPassword !== password) {
      toastr.error('Passwords do not match');
    } else {
      try {
        dispatch(showLoading());
        await changePassword(currentPassword, password);
        toastr.success('Password updated');

        setCurrentPassword('');
        setPassword('');
        setConfirmPassword('');
      } catch (error) {
        toastr.error('Error', error.message ? error.message : error);
      } finally {
        dispatch(hideLoading());
      }
    }
  };

  const handleClear = async () => {
    setCurrentPassword('');
    setPassword('');
    setConfirmPassword('');
  };

  return (
    <Card raised>
      <CardContent sx={{ p: 0, pb: '0 !important' }}>
        <form>
          <List disablePadding>
            <PasswordField
              id='current-password'
              label='Current Password'
              value={currentPassword}
              autoComplete='current-password'
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            <PasswordField
              id='new-password'
              label='New Password'
              value={password}
              autoComplete='new-password'
              onChange={(e) => setPassword(e.target.value)}
            />
            <PasswordField
              id='confirm-password'
              label='Confirm Password'
              value={confirmPassword}
              autoComplete='confirm-password'
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </List>
        </form>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button onClick={handleClear} variant='outlined'>
          Clear
        </Button>
        <Button color='primary' variant='contained' onClick={handleSubmit}>
          Submit
        </Button>
      </CardActions>
    </Card>
  );
};

export default ChangePasswordForm;
