import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';
import dayjs from 'dayjs';

import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import get from 'lodash/get';
import includes from 'lodash/includes';
import map from 'lodash/map';
import remove from 'lodash/remove';

import {
  acknowledgeAlarmAPI,
  getAlarmsAPI,
  getAlarmTriggersAPI,
  getTriggerDevicesAPI,
  closeAlarmAPI,
  deleteAlarmAPI,
  postTriggerDeviceAPI,
  deleteTriggerDeviceAPI,
} from '../../api';

const getAlarms = createAsyncThunk(
  'alarms/getAlarms',
  async (_, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().alarms;
    const { item: user } = getState().user;

    if (loading !== true || requestId !== currentRequestId || !user) {
      return;
    }

    try {
      dispatch(showLoading());
      const alarms = await getAlarmsAPI(true);
      const triggers = await getAlarmTriggersAPI();
      const triggerDevices = await getTriggerDevicesAPI(user?.user_id);

      return {
        data: alarms,
        triggers,
        triggerDevices,
        lastUpdate: dayjs(),
      };
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const getAllAlarms = createAsyncThunk(
  'alarms/getAllAlarms',
  async (_, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().alarms;
    const { item: user } = getState().user;

    if (loading !== true || requestId !== currentRequestId || !user) {
      return;
    }

    try {
      dispatch(showLoading());
      return { data: await getAlarmsAPI() };
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const acknowledgeAlarm = createAsyncThunk(
  'alarms/acknowledgeAlarm',
  async (alarm, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading, data: allAlarms } = getState().alarms;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    let alarms = cloneDeep(allAlarms);
    let alarmId = get(alarm, 'alarm_id');

    try {
      dispatch(showLoading());
      const updatedAlarm = await acknowledgeAlarmAPI(alarmId);

      remove(alarms, { alarm_id: alarmId });
      alarms = concat(alarms, updatedAlarm);

      toastr.success('Alarm Acknowledged');
      return { data: alarms };
    } catch (err) {
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

const closeAlarm = createAsyncThunk(
  'alarms/closeAlarm',
  async (alarm, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading, data: allAlarms } = getState().alarms;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    let alarms = cloneDeep(allAlarms);
    let alarmId = get(alarm, 'alarm_id');

    try {
      dispatch(showLoading());
      const updatedAlarm = await closeAlarmAPI(alarmId);

      remove(alarms, { alarm_id: alarmId });
      alarms = concat(alarms, updatedAlarm);

      toastr.success('Alarm closed');
      return { data: alarms };
    } catch (err) {
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deleteAlarm = createAsyncThunk(
  'alarms/deleteAlarm',
  async (alarm, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading, data: allAlarms } = getState().alarms;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    let alarms = cloneDeep(allAlarms);
    let alarmId = get(alarm, 'alarm_id');

    try {
      dispatch(showLoading());
      await deleteAlarmAPI(alarmId);

      toastr.success('Alarm deleted');
      remove(alarms, { alarm_id: alarmId });
      return { data: alarms };
    } catch (err) {
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

// TRIGGER DEVICE

const createTriggerDevices = createAsyncThunk(
  'alarms/createTriggerDevices',
  async (devices, { dispatch, getState, requestId }) => {
    const { loading, currentRequestId, triggerDevices } = getState().alarms;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    try {
      dispatch(showLoading());
      let _triggerDevices = cloneDeep(triggerDevices);
      let relationsAdded = await Promise.all(
        map(devices, async (device) => {
          const deviceId =
            device?.device_id || get(device, `${device.type_}_id`);
          return await postTriggerDeviceAPI({
            orgId: device.org_id,
            deviceId: deviceId,
            triggerId: device.trigger_id,
          });
        })
      );

      toastr.success(`Alarm trigger${devices.length > 1 ? 's' : ''} activated`);

      // update store
      return {
        triggerDevices: concat(_triggerDevices, relationsAdded),
      };
    } catch (err) {
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deleteTriggerDevices = createAsyncThunk(
  'alarms/deleteTriggerDevices',
  async (relations, { dispatch, getState, requestId }) => {
    const { loading, currentRequestId, triggerDevices } = getState().alarms;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }
    try {
      dispatch(showLoading());
      let _triggerDevices = cloneDeep(triggerDevices);

      let relationsRemoved = await Promise.all(
        map(
          relations,
          async (relation) =>
            await deleteTriggerDeviceAPI(relation.trigger_device_id)
        )
      );

      toastr.success(
        `Alarm trigger${relations.length > 1 ? 's' : ''} deactivated`
      );

      // update store
      remove(_triggerDevices, (relation) =>
        includes(relationsRemoved, relation.trigger_device_id)
      );

      return {
        triggerDevices: _triggerDevices,
      };
    } catch (err) {
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

export {
  getAlarms,
  getAllAlarms,
  acknowledgeAlarm,
  closeAlarm,
  deleteAlarm,
  createTriggerDevices,
  deleteTriggerDevices,
};
