export const VIEWS = {
  PRODUCTION: {
    name: 'production profile',
    description:
      '15 minute interval production data for selected meters and inverters',
  },
  PERFORMANCE: {
    name: 'performance',
    description: 'Actual vs Expected production for selected sites by month',
  },
  ESTIMATED: {
    name: 'estimated production',
    description: 'Estimated meter production for selected sites',
  },
  WEATHER: {
    name: 'weather',
    description: 'Temperature and irradiance data for the selected sensors',
  },
  METER: {
    name: 'meter',
    description: 'View trend of selected datapoints for a meter',
  },
};
