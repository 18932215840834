import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import numeral from 'numeral';
import map from 'lodash/map';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

function ExternalTooltip({ selectedPayload, timezone }) {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const data = map(selectedPayload?.activePayload, (payload) => {
      return {
        color: payload.color,
        dataKey: payload.dataKey,
        value: payload.value,
      };
    });
    setTableData(data);
  }, [selectedPayload]);

  if (tableData.length === 0) return null;
  return (
    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
      <Card raised sx={{ maxWidth: 500, width: '100%' }}>
        <CardHeader
          title={dayjs(Number(selectedPayload.activeLabel) * 1000)
            .utcOffset(timezone.offset)
            .format('MMMM Do h:mm A')}
          titleTypographyProps={{ align: 'center' }}
          sx={{ p: 1, pb: 0 }}
        />
        <CardContent
          sx={{
            p: 1,
            pt: 0,
            pb: '4px !important',
            display: 'flex',
            justifyContent: 'center',
          }}>
          <TableContainer>
            <Table>
              <TableBody>
                {tableData.map((item) => {
                  return (
                    <TableRow key={item.dataKey}>
                      <TableCell
                        sx={{ p: 1, color: item.color }}
                        component='th'
                        scope='row'>
                        {item.dataKey}
                      </TableCell>
                      <TableCell sx={{ p: 1, color: item.color }} align='right'>
                        {numeral(item.value).format('0.00')}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Grid>
  );
}

ExternalTooltip.propTypes = {
  selectedPayload: PropTypes.object,
};

export default ExternalTooltip;
